import buttonFilled from "./buttonFilled";
import buttonOutlined from "./buttonOutlined";
import buttonText from "./buttonText";

// types
import type { className, color, fullWidth, ripple, size, variant } from "../../../types/components/button";
import { propTypesColor, propTypesSize, propTypesVariant } from "../../../types/components/button";

export interface ButtonStyleTypes {
  defaultProps?: {
    variant?: variant;
    size?: size;
    color?: color;
    fullWidth?: fullWidth;
    ripple?: ripple;
    className?: className;
  };
  valid?: {
    variants?: string[];
    sizes?: string[];
    colors?: string[];
  };
  styles?: {
    base?: {
      initial?: object;
      fullWidth?: object;
    };
    sizes?: {
      sm?: object;
      md?: object;
      lg?: object;
    };
    variants?: {
      filled?: typeof buttonFilled;
      outlined?: typeof buttonOutlined;
      text?: typeof buttonText;
    };
  };
}

export const button: ButtonStyleTypes = {
  defaultProps: {
    variant: "filled",
    size: "md",
    color: "primary",
    fullWidth: false,
    ripple: false,
    className: "",
  },
  valid: {
    variants: propTypesVariant,
    sizes: propTypesSize,
    colors: propTypesColor,
  },
  styles: {
    base: {
      initial: {
        verticalAlign: "align-middle",
        userSelect: "select-none",
        fontFamily: "font-sans",
        fontWeight: "font-normal",
        textAlign: "text-center",
        textTransform: "normal-case",
        transition: "transition-all duration-200",
        disabled:
          "disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-600",
      },
      fullWidth: {
        display: "block",
        width: "w-full",
      },
    },
    sizes: {
      sm: {
        fontSize: "text-xs",
        py: "py-1.5",
        px: "px-3",
        borderRadius: "rounded-md",
      },
      md: {
        fontSize: "text-sm",
        py: "py-2",
        px: "px-4",
        borderRadius: "rounded-md",
      },
      lg: {
        fontSize: "text-md",
        py: "py-2.5",
        px: "px-6",
        borderRadius: "rounded-md",
      },
    },
    variants: {
      filled: buttonFilled,
      outlined: buttonOutlined,
      text: buttonText,
    },
  },
};

export default button;
