import type { color } from "@vip-tailwind/types/components/select";

const selectOutlinedLabelColors: Record<color, Record<string, Record<string, string>>> = {
  gray: {
    close: {
      color: "text-gray-400",
      before: "before:border-transparent",
      after: "after:border-transparent",
    },
    open: {
      color: "text-gray-900",
      before: "before:border-blue-700",
      after: "after:border-blue-700",
    },
    withValue: {
      color: "text-gray-400",
      before: "before:border-blue-200",
      after: "after:border-blue-200",
    },
  },
};

export default selectOutlinedLabelColors;
