const chipOutlined: object = {
  gray: {
    border: "border border-gray-500",
    color: "text-gray-700",
  },
  orange: {
    border: "border border-orange-500",
    color: "text-orange-700",
  },
  yellow: {
    border: "border border-yellow-500",
    color: "text-yellow-700",
  },
  green: {
    border: "border border-green-500",
    color: "text-green-700",
  },
  blue: {
    border: "border border-blue-500",
    color: "text-blue-700",
  },
  red: {
    border: "border border-red-500",
    color: "text-red-700",
  },
};

export default chipOutlined;
