import { useClosestListingTableStateValue } from "@features/listing";
import { type ThreatsListingApiResponse, ThreatsListingQueryKey } from "@features/listing/hooks/useThreatsListing";
import useQuickStart from "@features/quickStart";
import { useCreateToast } from "@hooks/useCreateToast";
import { useQueryClient } from "@tanstack/react-query";
import useFetchMutation from "@utils/useFetchMutation";

const bookmarkStatusUrlEndpoint = (threat_id: number) => `/api/v1/bookmarks/${threat_id}`;

interface MutationVariables {
  threat_id: number;
  new_status: boolean;
}

function useMutateBookmarkStatus() {
  const quickStart = useQuickStart();
  const queryClient = useQueryClient();
  const listingTableSnap = useClosestListingTableStateValue();
  const { addMessage } = useCreateToast();

  return useFetchMutation<any, any, MutationVariables, any>(
    (variables) => bookmarkStatusUrlEndpoint(variables!.threat_id),
    ({ new_status }: { new_status: boolean }) => ({
      new_status: new_status,
    }),
    {
      onMutate: async ({ threat_id, new_status }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: [ThreatsListingQueryKey, listingTableSnap.urlEndpoint] });

        // Snapshot the previous value
        const previousThreats = queryClient.getQueryData<ThreatsListingApiResponse>([
          ThreatsListingQueryKey,
          listingTableSnap.urlEndpoint,
        ]);

        // Optimistically update to the new value
        if (previousThreats) {
          queryClient.setQueryData(
            [ThreatsListingQueryKey, listingTableSnap.urlEndpoint],
            (oldData: ThreatsListingApiResponse) => {
              const updatedResults = oldData.results.map((threat) => {
                return threat.cve_threat_intel_id === threat_id ? { ...threat, favorite: new_status } : threat;
              });

              return {
                ...oldData,
                results: updatedResults,
                bookmarked_count: oldData.bookmarked_count + (new_status ? 1 : -1),
              };
            },
          );
        }

        await quickStart.markNextStepAsCompleted("add_favorite");

        return { previousThreats };
      },
      onError: (err, { threat_id, new_status }, context) => {
        // Rollback to the previous value
        if (context?.previousThreats) {
          queryClient.setQueryData([ThreatsListingQueryKey, listingTableSnap.urlEndpoint], context.previousThreats);
        }

        addMessage({
          title: "Error!",
          content: "Failed to update bookmark status. Please try again.",
          variant: "error",
        });
      },
      onSuccess: (_data, { threat_id, new_status }) => {
        if (_data.ok) {
          addMessage({
            title: "Success!",
            content: "Bookmark status was successfully updated.",
            variant: "success",
          });
        } else {
          // If the server responds with not ok, roll back
          queryClient.invalidateQueries({ queryKey: [ThreatsListingQueryKey, listingTableSnap.urlEndpoint] });
        }
      },
    },
  );
}

export default useMutateBookmarkStatus;
