// types
import type { animate, className, dismiss, offset, placement } from "../../../types/components/popover";

export interface PopoverStylesType {
  defaultProps?: {
    placement?: placement;
    offset?: offset;
    dismiss?: dismiss;
    animate?: animate;
    className?: className;
  };
  styles?: {
    base?: object;
  };
}

export const popover: PopoverStylesType = {
  defaultProps: {
    placement: "top",
    offset: 5,
    dismiss: {},
    animate: {
      unmount: {},
      mount: {},
    },
    className: "",
  },
  styles: {
    base: {
      bg: "bg-white",
      p: "p-2",
      border: "border border-gray-400",
      borderRadius: "rounded",
      boxShadow: "shadow shadow-gray-500/10",
      fontFamily: "font-sans",
      fontSize: "text-sm",
      fontWeight: "font-normal",
      color: "text-gray-500",
      outline: "focus:outline-none",
      overflowWrap: "break-words",
      whiteSpace: "whitespace-normal",
      zIndex: "z-[9999]",
    },
  },
};

export default popover;
