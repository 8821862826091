import { Popover as MTPopover, PopoverContent, PopoverHandler } from "@vip-tailwind/components/Popover";
import React from "react";

const Popover = ({
  Trigger,
  children,
  isOpen = false,
  setOpen,
  triggerProps = {},
  popoverProps = { children: null },
}: {
  Trigger: React.ForwardRefExoticComponent<
    {
      isOpen: boolean;
    } & React.RefAttributes<HTMLButtonElement> &
      Record<string, any>
  >;
  children: React.ReactNode;
  isOpen?: boolean;
  setOpen?: (isOpen: boolean) => void;
  triggerProps?: React.ComponentPropsWithoutRef<any>;
  popoverProps?: React.ComponentProps<typeof PopoverContent>;
}) => {
  // State could be handled by a parent component also
  const [_isOpen, _setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    if (isOpen !== _isOpen) {
      _setOpen(isOpen);
    }
  }, [isOpen]);

  const handleOpen = () => {
    const newStatus = !_isOpen;
    if (setOpen) {
      setOpen(newStatus);
    } else {
      _setOpen(newStatus);
    }
  };

  return (
    <MTPopover open={_isOpen} handler={handleOpen}>
      <PopoverHandler>
        <Trigger isOpen={_isOpen} {...triggerProps} />
      </PopoverHandler>
      <PopoverContent className="p-0" {...popoverProps}>
        <div className="ring-1 ring-blue-500 ring-opacity-5 overflow-visible rounded-lg shadow-lg">
          <div className="relative p-2 bg-white">{children}</div>
        </div>
      </PopoverContent>
    </MTPopover>
  );
};

export default Popover;