import fetchWithSession from "@utils/fetchWithSession";
import { HeaderMenu, type MenuTypographyItemProps } from "./HeaderMenu";

const profileMenuItems: MenuTypographyItemProps[] = [
  {
    label: "Profile",
    iconClass: "far fa-user",
    url: "/profile/",
  },
  {
    label: "My organization",
    iconClass: "far fa-building",
    url: "/organization/",
  },
  {
    label: "API",
    iconClass: "far fa-code",
    url: "/swagger/",
    external: true,
  },
  {
    label: "Logout",
    iconClass: "far fa-sign-out-alt",
    url: "/logout/",
    action: async (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopPropagation();
      await fetchWithSession("/logout/", {
        method: "GET",
        noParse: true,
      });
      window.location.href = "/login/";
      return null;
    },
  },
];

function ProfileMenu({ is_impersonated }: { is_impersonated: boolean }) {
  return <HeaderMenu menuItems={profileMenuItems} iconClass="far fa-user" placement="bottom-start" />;
}

export { ProfileMenu };
