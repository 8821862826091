import { useCurrentUserProfile } from "@queries/useCurrentUserProfile";
import { HeaderMenu, type MenuTypographyItemProps } from "./HeaderMenu";

const adminMenuItems: MenuTypographyItemProps[] = [
  {
    label: "Admin Portal",
    iconClass: "far fa-terminal",
    url: "/admin/",
    action: (e) => {
      e.preventDefault();
      window.location.href = "/admin/";
    },
  },
  {
    label: "Global Tags",
    iconClass: "far fa-tag",
    url: "/manage_global_tags/",
  },
  {
    label: "Logs",
    iconClass: "far fa-file",
    url: "/logs/",
    action: (e) => {
      e.preventDefault();
      window.location.href = "/logs/";
    },
  },
];

function AdminMenu() {
  const { data: userProfile } = useCurrentUserProfile();
  const isAdmin = userProfile?.is_staff;

  if (!isAdmin) {
    return null;
  }

  return <HeaderMenu menuItems={adminMenuItems} iconClass="far fa-lock" />;
}

export { AdminMenu };
