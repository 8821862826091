import { z } from "zod";

// Tag option for the select component
interface TagSelectOption {
  value: string;
  label: string;
  isEditable: boolean;
  isGlobal?: boolean;
}


export const SerializedBaseTagSchema = z.object({
  id: z.number(),
  value: z.string(),
  created_by: z.number(),
  created_at: z.string(),
  is_global: z.boolean().optional(),
});

// Base tag structure from the API
type SerializedBaseTag = z.infer<typeof SerializedBaseTagSchema>;

interface OrganizationTagStat {
  value: string;
  id: number;
  count: number;
}

type FrontendSerializedTag = Pick<TagWithPermissions, "value" | "isEditable" | "is_global"> & {
  id: number | undefined;
};

// Tag with user permissions
interface TagWithPermissions extends SerializedBaseTag {
  isEditable: boolean | undefined;
}

export type { TagSelectOption, SerializedBaseTag, OrganizationTagStat, FrontendSerializedTag, TagWithPermissions };
