import "vite/modulepreload-polyfill";
import "./assets/fontawesome-pro/css/fontawesome.css";
import "./assets/fontawesome-pro/css/light.css";
import "./assets/fontawesome-pro/css/solid.css";
import "./assets/fontawesome-pro/css/regular.css";

// Purpose: make valtio state type use object instead of readonly object to gradually use the state without type declaration for every snapshot
declare module "valtio" {
  function useSnapshot<T extends object>(p: T): T;
}

import "react-tooltip/dist/react-tooltip.css";

import { createRoot } from "react-dom/client";

import { browserTracingIntegration, init, replayIntegration } from "@sentry/react";

import { ErrorWithResponse } from "@utils/useFetchMutation";
import { Router } from "./Router";

const isDevelopment = import.meta.env.MODE === "development";

if (isDevelopment) {
  // @ts-ignore
  import("./styles.css");
}

if (__SENTRY_DSN_FRONTEND__ && !isDevelopment) {
  const environment = __SENTRY_ENVIRONMENT__ ?? "production";
  console.log("Sentry environment", environment);
  init({
    release: `nucleus-vip@${__APP_VERSION__}`,
    dsn: __SENTRY_DSN_FRONTEND__,
    environment: environment,
    tracesSampleRate: Number.parseFloat(__SENTRY_TRACES_SAMPLE_RATE__ ?? "0.3"),
    replaysSessionSampleRate: Number.parseFloat(__SENTRY_REPLAYS_SESSION_SAMPLE_RATE__ ?? "0.3"),
    replaysOnErrorSampleRate: Number.parseFloat(__SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE__ ?? "1.0"),
    integrations: environment !== "development" ? [browserTracingIntegration(), replayIntegration()] : undefined,
    beforeSend(event, hint) {
      // dont track some ErrorWithResponse, since they are 400 errors with validations which are handled by the frontend
      if (hint.originalException instanceof ErrorWithResponse && !hint.originalException.logInSentry) {
        return null;
      }

      return event;
    },
  });
}

console.info(`Nucleus VIP version: ${__APP_VERSION__}`);

const domContainer = document.getElementById("react-root");
if (domContainer) {
  const root = createRoot(domContainer);
  root.render(
    // TODO: enable StrictMode when we have a chance to fix NucleusQL double initialization issue
    // <StrictMode>
    (<Router />) as any,
    // </StrictMode>,
  );
}
