import { ActionButton } from "@components/ui/ActionButton";
import { capitalizeFirstLetter } from "@utils/utility";
import { Popover, PopoverContent, PopoverHandler } from "@vip-tailwind/components/Popover";
import Tooltip from "@vip-tailwind/components/Tooltip";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import Select, { type StylesConfig } from "react-select";
import { debounce } from "remeda";

const colourStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    borderColor: "#e2e8f0",
    minHeight: "40px",
  }),
  option: (styles, state) => ({
    ...styles,
    padding: "2px 6px",
    cursor: "pointer",
    color: state.isSelected ? "black" : undefined,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 6px",
  }),
  multiValue: (styles) => ({
    ...styles,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "black", // Ensures text is black in multi-value display
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ":hover": {
      color: "white",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
};

interface SelectionFilterProps {
  header?: string;
  accessorKey: string;
  options: { label: string; value: string }[];
  filteredOptions: { label: string; value: string }[];
  currentValue: string[] | string | null;
  isMulti?: boolean;
  updateFilterState: (value: string | string[] | null) => void;
  isClearable?: boolean;
  usePopover?: boolean; // New prop to control the display mode
}

function SelectionFilter({
  header,
  accessorKey,
  options,
  filteredOptions,
  currentValue,
  updateFilterState,
  isMulti = true,
  isClearable = false,
  usePopover = true, // Default to original behavior
}: SelectionFilterProps) {
  const [filterValue, setFilterValue] = useState(() => {
    if (isMulti && Array.isArray(currentValue)) {
      return currentValue.length ? currentValue.map((v) => ({ label: v, value: v })) : [];
    }
    return currentValue ? { label: currentValue, value: currentValue } : null;
  });

  const debouncedSetFilters = useMemo(
    () =>
      debounce(
        (newFilterValue) => {
          if (isMulti) {
            updateFilterState(newFilterValue.map((v: { label: string; value: string }) => v.value));
          } else {
            updateFilterState(newFilterValue ? newFilterValue.value : null);
          }
        },
        { timing: "trailing", waitMs: 800 },
      ),
    [isMulti, updateFilterState],
  );

  useEffect(() => {
    debouncedSetFilters.call(filterValue);
    return () => {
      debouncedSetFilters.cancel();
    };
  }, [filterValue, debouncedSetFilters]);

  const handleFilterChange = (selectedOptions: any) => {
    setFilterValue(selectedOptions);
  };

  const optionsToShow = currentValue?.length === 0 ? filteredOptions : options;
  const isFilterApplied = isMulti ? Array.isArray(filterValue) && filterValue.length > 0 : !!filterValue;

  const SelectComponent = (
    <Select
      placeholder="All"
      id={accessorKey}
      value={filterValue}
      onChange={handleFilterChange}
      options={optionsToShow}
      isMulti={isMulti}
      isClearable={isClearable}
      openMenuOnFocus={true}
      autoFocus={true}
      styles={colourStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#e9eef2",
        },
      })}
    />
  );

  if (!usePopover) {
    return (
      <div className="flex items-center space-x-2">
        <label htmlFor={accessorKey}>{capitalizeFirstLetter(header ?? accessorKey.split("_").join(" "), true)}</label>
        <div className="w-64">{SelectComponent}</div>
      </div>
    );
  }

  const tooltipContent = !isFilterApplied
    ? "Filter by selection"
    : `Filtered by: ${Array.isArray(filterValue) ? filterValue.map((v) => v.label).join(", ") : filterValue?.label}`;

  return (
    <div className="flex items-center space-x-2">
      <label htmlFor={accessorKey}>{capitalizeFirstLetter(header ?? accessorKey.split("_").join(" "), true)}</label>
      <Popover placement="bottom-start">
        <PopoverHandler>
          <ActionButton
            classes={classNames({
              "!bg-blue-500 hover:!bg-blue-400 hover:!text-gray-200": isFilterApplied,
              "text-gray-400 hover:text-white hover:!bg-blue-500": !isFilterApplied,
              "text-white hover:text-blue-1000": isFilterApplied,
            })}
            size="xs"
          >
            <Tooltip content={tooltipContent}>
              <i className="fas fa-filter text-sm" />
            </Tooltip>
          </ActionButton>
        </PopoverHandler>
        <PopoverContent className="p-2 w-64">{SelectComponent}</PopoverContent>
      </Popover>
    </div>
  );
}

export { SelectionFilter };
