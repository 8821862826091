import type { color } from "@vip-tailwind/types/components/select";

const selectOutlinedColors: Record<color, Record<string, Record<string, string>>> = {
  gray: {
    close: {
      borderColor: "border-gray-200",
    },
    open: {
      borderColor: "border-blue-700",
      borderTopColor: "border-t-transparent",
    },
    withValue: {
      borderColor: "border-gray-200",
      borderTopColor: "border-t-transparent",
    },
  },
};

export default selectOutlinedColors;
