//     <meta name="c-token" content="{{ csrf_token }}">
function getCsrfToken(forceFetch = false): Promise<string> {
  // First check meta tag unless forceFetch is true
  if (!forceFetch) {
    const metaToken = document.querySelector('meta[name="c-token"]')?.getAttribute("content");
    if (metaToken) return Promise.resolve(metaToken);
  }

  // Fallback to fetch if meta tag not found or forceFetch is true
  return fetch("/get-csrf-token/", {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Failed to fetch CSRF token: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (!data?.csrfToken || typeof data.csrfToken !== "string") {
        throw new Error("Invalid CSRF token response format");
      }
      return data.csrfToken;
    });
}

interface CsrfFetchOptions extends RequestInit {
  retry403?: boolean;
}

async function csrfFetch(url: string, options: CsrfFetchOptions = {}): Promise<Response> {
  const csrfToken = await getCsrfToken();

  const fetchOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      "X-CSRFToken": csrfToken,
      "X-Requested-With": "XMLHttpRequest",
    },
  };

  let response = await fetch(url, fetchOptions);

  // Optionally handle 403s with token refresh
  if (!response.ok && response.status === 403 && options.retry403) {
    const newCsrfToken = await getCsrfToken(true);
    fetchOptions.headers = {
      ...fetchOptions.headers,
      "X-CSRFToken": newCsrfToken,
    };
    response = await fetch(url, fetchOptions);
  }

  return response;
}

export { getCsrfToken, csrfFetch };