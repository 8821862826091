import classNames from "classnames";
import React from "react";

import type { Table } from "@tanstack/react-table";

export function PaginationLink({
  table,
  isPrev,
}: {
  table: Table<any>;
  isPrev: boolean;
}) {
  const handlePaginationLinkClick = (e: any) => {
    e.preventDefault();

    if (isPrev) {
      if (table.getCanPreviousPage()) {
        table.previousPage();
      }
    } else {
      if (table.getCanNextPage()) {
        table.nextPage();
      }
    }
  };

  const isDisabled = !(isPrev ? table.getCanPreviousPage() : table.getCanNextPage());
  return (
    <button
      className={classNames("block py-1.5 px-2 ml-0 leading-tight bg-white border border-gray-300", {
        "rounded-l-lg": isPrev,
        "rounded-r-lg": !isPrev,
        "cursor-not-allowed text-gray-200": isDisabled,
        "shadow-sm hover:bg-gray-100 hover:text-gray-700 text-gray-500": !isDisabled,
      })}
      onClick={handlePaginationLinkClick}
      disabled={isDisabled}
    >
      <span className="sr-only">{isPrev ? "Previous" : "Next"}</span>
      {isPrev ? (
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
}
