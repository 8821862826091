import React from "react";

import { useClosestListingTableMode, useVisibleColumns } from "@features/listing";
import { defaultColumnSizing, minColumnSizing } from "@features/listing/ListingTableState";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import useIntrospections from "@queries/useIntrospections";

function TableLoader() {
  const recordsType = useClosestListingTableMode();

  const visibleColumns = useVisibleColumns(recordsType);
  const {
    data: { fields: fieldsDefinition },
  } = useIntrospections();

  return (
    <div>
      <div className={"overflow-x-auto relative"}>
        <table className="relative w-full max-w-full text-sm text-left text-gray-600 border-collapse">
          <thead
            className="text-xs text-gray-400 uppercase"
            style={{
              width: "calc( 100% - 1em )",
            }}
          >
            <tr>
              <th
                scope="col"
                key={"favorite"}
                className={"px-2 py-1 bg-gray-200"}
                style={{
                  minWidth: defaultColumnSizing.favorite,
                }}
              >
                {" "}
              </th>
              {visibleColumns.map((column_id: ThreatKey, index) => (
                <th
                  scope="col"
                  key={`${column_id}_${index}`}
                  className={"px-2 py-1 bg-gray-200"}
                  style={{
                    minWidth: minColumnSizing[column_id],
                  }}
                >
                  {fieldsDefinition[column_id]?.constrains?.name || column_id.replace(/_/g, " ")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Loading state */}
            <tr>
              <td colSpan={visibleColumns.length + 1}>
                <div className="flex items-center justify-center h-20">
                  <div className="flex flex-col items-center space-y-2">
                    <div className="animate-pulse w-8 h-8 border-2 border-gray-300 rounded-full" />
                    <div className="animate-pulse text-gray-400">Loading...</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableLoader;
