import { isNil } from "lodash-es";

import type SerializedThreat from "@interfaces/SerializedThreat";
import useIntrospections, { type IntrospectionsResponse } from "@queries/useIntrospections";
import { isArrayLikeString, safeParseJSONtoArray } from "@utils/utility";

import DatePublished from "./DatePublished";
import LastUpdated from "./LastUpdated";
import { renderField } from "./renderField";

const BaseMetricsV4 = ({
  introspections,
  threat,
}: { introspections: IntrospectionsResponse; threat: Partial<SerializedThreat> }) => {
  const base_metrics: (keyof SerializedThreat)[] = [
    "nvd_cvssv4_attack_vector",
    "nvd_cvssv4_attack_complexity",
    "nvd_cvssv4_attack_requirements",
    "nvd_cvssv4_privileges_required",
    "nvd_cvssv4_user_interaction",
  ];

  const supplemental_metrics: (keyof SerializedThreat)[] = [
    "nvd_cvssv4_safety",
    "nvd_cvssv4_recovery",
    "nvd_cvssv4_value_density",
    "nvd_cvssv4_vulnerability_response_effort",
    "nvd_cvssv4_provider_urgency",
  ];

  const vulnerable_system_metrics: (keyof SerializedThreat)[] = [
    "nvd_cvssv4_vulnerable_system_confidentiality",
    "nvd_cvssv4_vulnerable_system_integrity",
    "nvd_cvssv4_vulnerable_system_availability",
  ];

  const subsequent_system_metrics: (keyof SerializedThreat)[] = [
    "nvd_cvssv4_subsequent_system_confidentiality",
    "nvd_cvssv4_subsequent_system_integrity",
    "nvd_cvssv4_subsequent_system_availability",
  ];

  const hasFieldsInfo = [
    ...base_metrics,
    ...supplemental_metrics,
    ...vulnerable_system_metrics,
    ...subsequent_system_metrics,
  ].some((field) => !isNil(threat[field]) && threat[field] !== "");

  if (!hasFieldsInfo) {
    return null;
  }

  const renderMetricsBlock = (
    title: string,
    fields: (keyof SerializedThreat)[],
    titleExtraClasses?: string,
    valuesExtraClasses?: string,
  ) => (
    <div className="mb-4">
      <h4 className="font-semibold mb-2 -ml-3">{title}</h4>
      {fields.map((field) => renderField(field, introspections, threat, titleExtraClasses, valuesExtraClasses))}
    </div>
  );

  return (
    <div className="lg:p-4 flex-1 px-2">
      <div className="lg:flex-row lg:gap-8 flex flex-col">
        <div className="lg:flex-1 flex flex-col md:gap-8">
          {renderMetricsBlock("CVSS v4 Base Metrics", base_metrics)}
          {renderMetricsBlock("CVSS v4 Vulnerable System Impact Metrics", vulnerable_system_metrics)}
        </div>
        <div className="lg:flex-1 flex flex-col">
          {renderMetricsBlock(
            "CVSS v4 Supplemental Metrics",
            supplemental_metrics,
            "md:!max-w-[160px]",
            "md:!justify-start md:flex-1",
          )}
          {renderMetricsBlock("CVSS v4 Subsequent System Impact Metrics", subsequent_system_metrics)}
        </div>
      </div>
    </div>
  );
};

const BaseMetricsV3 = ({
  introspections,
  threat,
}: { introspections: IntrospectionsResponse; threat: Partial<SerializedThreat> }) => {
  const first_column_fields: (keyof SerializedThreat)[] = [
    "nvd_cvssv3_attack_vector",
    "nvd_cvssv3_attack_complexity",
    "nvd_cvssv3_user_interaction",
    "nvd_cvssv3_privileges_required",
    "nvd_cvssv3_scope",
  ];

  const second_column_fields: (keyof SerializedThreat)[] = [
    "nvd_cvssv3_confidentiality_impact",
    "nvd_cvssv3_integrity_impact",
    "nvd_cvssv3_availability_impact",
  ];

  return (
    <div className="lg:px-4 flex-1 px-2 py-1">
      <h3 className="py-2 font-bold -ml-3">CVSS v3 Base Metrics</h3>
      <div className="lg:flex-row lg:gap-16 flex flex-col">
        <div className="lg:flex-1 flex flex-col">
          {first_column_fields.map((field) => renderField(field, introspections, threat))}
        </div>
        <div className="lg:flex-1 flex flex-col">
          {second_column_fields.map((field) => renderField(field, introspections, threat))}
        </div>
      </div>
    </div>
  );
};

const BaseMetricsV2 = ({
  introspections,
  threat,
}: { introspections: IntrospectionsResponse; threat: Partial<SerializedThreat> }) => {
  const first_column_fields: (keyof SerializedThreat)[] = [
    "nvd_cvssv2_access_vector",
    "nvd_cvssv2_access_complexity",
    "nvd_cvssv2_authentication",
  ];

  const second_column_fields: (keyof SerializedThreat)[] = [
    "nvd_cvssv2_confidentiality_impact",
    "nvd_cvssv2_integrity_impact",
    "nvd_cvssv2_availability_impact",
  ];

  const hasFieldsInfo =
    first_column_fields.some((field) => !isNil(threat[field]) && threat[field] !== "") ||
    second_column_fields.some((field) => !isNil(threat[field]) && threat[field] !== "");

  if (!hasFieldsInfo) {
    return null;
  }

  return (
    <div className="lg:p-4 flex-1 px-2">
      <h3 className="py-2 font-bold -ml-3">CVSS v2 Base Metrics</h3>
      <div className="lg:flex-row lg:gap-16 flex flex-col">
        <div className="lg:flex-1 flex flex-col">
          {first_column_fields.map((field) => renderField(field, introspections, threat))}
        </div>
        <div className="lg:flex-1 flex flex-col">
          {second_column_fields.map((field) => renderField(field, introspections, threat))}
        </div>
      </div>
    </div>
  );
};

function NVD({ threat }: { threat: Partial<SerializedThreat> }) {
  const { data: introspections } = useIntrospections();

  const hasNVDFieldsInfo: boolean = [
    "nvd_cvssv3_attack_vector",
    "nvd_cvssv3_attack_complexity",
    "nvd_cvssv3_user_interaction",
    "nvd_cvssv3_privileges_required",
    "nvd_cvssv3_scope",
    "nvd_cvssv3_confidentiality_impact",
    "nvd_cvssv3_integrity_impact",
    "nvd_cvssv3_availability_impact",
    "nvd_cvssv2_confidentiality_impact",
    "nvd_cvssv2_integrity_impact",
    "nvd_cvssv2_availability_impact",
    "nvd_cvssv2_access_vector",
    "nvd_cvssv2_access_complexity",
    "nvd_cvssv2_authentication",
    "nvd_cvssv4_attack_vector",
    "nvd_cvssv4_attack_complexity",
    "nvd_cvssv4_attack_requirements",
    "nvd_cvssv4_privileges_required",
    "nvd_cvssv4_user_interaction",
    "nvd_cvssv4_vulnerable_system_confidentiality",
    "nvd_cvssv4_vulnerable_system_integrity",
    "nvd_cvssv4_vulnerable_system_availability",
    "nvd_cvssv4_subsequent_system_confidentiality",
    "nvd_cvssv4_subsequent_system_integrity",
    "nvd_cvssv4_subsequent_system_availability",
  ].some((field: keyof SerializedThreat) => {
    return !isNil(threat[field]) && threat[field] !== "";
  });

  const vendors = isArrayLikeString(threat.nvd_vendor) ? safeParseJSONtoArray(threat.nvd_vendor) : [];

  return (
    <>
      <div className="flex-auto">
        <LastUpdated value={threat.nvd_last_updated_by_nucleus} />
        <DatePublished value={threat.nvd_published_date} />
        <div className="mt-2">
          <div className="lg:px-4 flex-1 px-2">
            <div className="lg:flex-1 flex flex-row">
              <div className="font-semibold">NVD Vendor(s) affected:</div>
              <div className="flex-1 px-2">{vendors?.length ? vendors.join(", ") : "N/A"}</div>
            </div>
          </div>

          <div className="lg:px-4 flex-1 px-2">
            <div className="lg:flex-1 flex flex-col">{renderField("nvd_status", introspections, threat)}</div>
          </div>
          {hasNVDFieldsInfo ? (
            <>
              <div className="md:flex-row flex flex-col w-full mt-6">
                <BaseMetricsV4 introspections={introspections} threat={threat} />
              </div>
              <div className="md:flex-row flex flex-col w-full mt-6">
                <BaseMetricsV3 introspections={introspections} threat={threat} />
              </div>
              <div className="md:flex-row flex flex-col w-full mt-6">
                <BaseMetricsV2 introspections={introspections} threat={threat} />
              </div>
            </>
          ) : null}
          <div className="px-10">
            <div className="w-full py-2 font-semibold">CPEs</div>
            <div className="flex flex-col justify-center gap-1 px-3 py-2">
              {threat.nvd_cpes?.length ? (
                threat.nvd_cpes?.map((cpe) => <div key={cpe}>{cpe}</div>)
              ) : (
                <div className="text-gray-500 text-sm">No data</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NVD;
