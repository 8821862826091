import { DateTime } from "luxon";
import React from "react";

function DatePublished({ value }: { value?: string }) {
  const lastUpdated = value ? DateTime.fromISO(value) : null;
  // Let’s use the actual date and time though, instead of the “x time ago”
  // format: April 5, 2023 at 12:41 PM UTC
  const lastUpdatedStr =
    value && DateTime.isDateTime(lastUpdated) ? lastUpdated.toFormat("MMMM d, yyyy 'at' hh:mm a 'UTC'") : "Never";
  return <h5 className="px-3 pt-0 pb-1 text-xs">Date published to feed: {lastUpdatedStr}</h5>;
}

export default DatePublished;
