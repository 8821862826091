import classNames from "classnames";
import type React from "react";
import { type MouseEventHandler, useCallback } from "react";
import Select, {
  components,
  type ValueContainerProps,
  type MultiValueProps,
  type MultiValueRemoveProps,
  type OnChangeValue,
} from "react-select";

import { DndContext, type DragEndEvent, closestCorners } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DEFAULT_MALWARE_COLUMNS, RecordsType, useDefaultColumns } from "@features/listing";
import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";
import type { ThreatModelConfiguration } from "@queries/useIntrospections";
import { capitalizeFirstLetter } from "@utils/utility";

import { Icon } from "@components/Icon";
import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";
import Button from "@vip-tailwind/components/Button";
import useAvailableColumns from "./ColumnsToggle/useAvailableColumns";

const DragIcon = ({
  onMouseDown,
}: {
  onMouseDown: MouseEventHandler<HTMLDivElement>;
}) => (
  <i className="fas fa-bars hover:text-gray-600 pr-1 text-sm text-gray-400 cursor-move" onMouseDown={onMouseDown} />
);

const MultiValue = (props: MultiValueProps<Option>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.data.value,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100%",
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <div className="flex items-center gap-0">
        <DragIcon onMouseDown={onMouseDown} />
        {/* @ts-ignore-next-line We're failing to provide a required index prop to SortableElement */}
        <components.MultiValue {...props} innerProps={innerProps} />
      </div>
    </div>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<Option>) => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        onPointerDown: (e) => e.stopPropagation(),
        ...props.innerProps,
      }}
    />
  );
};

export interface Option {
  value: keyof ThreatModelConfiguration | SerializedMandiantMalwareKey;
  label: string;
  isDisabled?: boolean;
}

const ResetColumnsButton = ({
  isNotDefaultSetup,
  setSelectedOptions,
  recordsType,
}: {
  isNotDefaultSetup: boolean;
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  recordsType: RecordsType;
}) => {
  const defaultColumns = useDefaultColumns(recordsType);

  const handleResetColumns = async () => {
    setSelectedOptions(
      defaultColumns.map((column_id) => ({
        label: transformLabel(column_id, false),
        value: column_id,
      })),
    );
  };

  return (
    <div className="flex flex-row justify-center px-2 mt-2">
      <Button
        size="sm"
        variant="text"
        onClick={handleResetColumns}
        className={classNames({
          "opacity-50 cursor-not-allowed": !isNotDefaultSetup,
        })}
      >
        Reset to defaults
      </Button>
    </div>
  );
};

function transformLabel(column_id: string, includeID = true) {
  return `${capitalizeFirstLetter(column_id.replace(/_/g, " "))}${includeID ? ` (${column_id})` : ""}`;
}

function useIsDefaultSetup(recordsType: RecordsType, selectedOptions: Option[]) {
  const { data } = useOrganizationConfiguration();

  const defaultSetup =
    recordsType === RecordsType.CVEs
      ? data?.default_columns?.map((column_id) => ({
          label: transformLabel(column_id),
          value: column_id,
        }))
      : DEFAULT_MALWARE_COLUMNS.map((column_id) => ({
          label: transformLabel(column_id),
          value: column_id,
        }));

  /**
   * selectedOptions.some(
      ({ value }) => !siteSettings.default_columns.includes(value)
    ) || selectedOptions.length !== siteSettings.default_columns.length; // not perfect cuz may be abused to have false positive, but most cases will be covered
   */

  return (
    !defaultSetup ||
    !selectedOptions ||
    selectedOptions.length !== defaultSetup.length ||
    selectedOptions.some(
      ({
        value,
      }: {
        value: keyof ThreatModelConfiguration | SerializedMandiantMalwareKey;
      }) => !defaultSetup.map((opt) => opt.value).includes(value as any),
    )
  );
}

const CustomValueContainer = (props: ValueContainerProps<any, true>) => {
  const { children, selectProps } = props;

  return (
    // Render the default ValueContainer
    <components.ValueContainer {...props}>
      {children}
      {/* Render our extra placeholder only if there's no active search input */}
      {!selectProps.inputValue && (
        <div className="flex items-center ml-1 text-gray-500 pointer-events-none absolute bottom-1">
          <Icon icon="search" size="sm" className="mr-1" />
          <span>{selectProps.placeholder}</span>
        </div>
      )}
    </components.ValueContainer>
  );
};

function ColumnsToggle({
  selectedOptions,
  setSelectedOptions,
  recordsType,
}: {
  selectedOptions: Option[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  recordsType: RecordsType;
}) {
  const availableColumns = useAvailableColumns(recordsType);

  const options = availableColumns.map((column_id) => ({
    label: transformLabel(column_id),
    value: column_id as keyof ThreatModelConfiguration,
  }));

  const isNotDefaultSetup = useIsDefaultSetup(recordsType, selectedOptions);

  const onChange = (selectedOptions: OnChangeValue<Option, true>) => {
    // Looks like not needed transformation here, but its needed in order to keep selection options labels to be different from options values
    return setSelectedOptions([
      ...selectedOptions.map(({ value, label }) => ({
        label: transformLabel(value, false),
        value,
      })),
    ]);
  };

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active || !over) {
        return;
      }

      setSelectedOptions((items) => {
        const oldIndex = items.findIndex((item) => item.value === active.id);
        const newIndex = items.findIndex((item) => item.value === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    },
    [setSelectedOptions],
  );

  return (
    <div className="w-36 md:w-96 flex flex-col">
      <DndContext modifiers={[restrictToParentElement]} onDragEnd={onDragEnd} collisionDetection={closestCorners}>
        <SortableContext items={selectedOptions.map((o) => o.value)} strategy={verticalListSortingStrategy}>
          <Select
            autoFocus
            isMulti
            options={options}
            value={selectedOptions}
            onChange={onChange}
            components={{
              // @ts-ignore [we cannot pass index to SortableElement from sortable lib]
              MultiValue: MultiValue,
              MultiValueRemove: MultiValueRemove,
              ValueContainer: CustomValueContainer,
            }}
            closeMenuOnSelect={false}
            isClearable={false}
            isSearchable={true}
            defaultMenuIsOpen={true}
            placeholder="Search columns..."
            styles={{
              multiValue: (baseStyles, { isDisabled }) => ({
                ...baseStyles,
                cursor: isDisabled ? "not-allowed" : "default",
                backgroundColor: "#EFF8FF", // Tailwind blue-100
                flex: 1,
                justifyContent: "space-between",
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                flexDirection: "column",
                alignItems: "flex-start",
              }),
              multiValueLabel: (baseStyles, { isDisabled }) => ({
                ...baseStyles,
                color: isDisabled ? "#98A2B3" : "black", // Tailwind gray-500
              }),
              option: (baseStyles, { isDisabled }) => ({
                ...baseStyles,
                fontSize: "12px",
                color: isDisabled ? "#98A2B3" : "black",
                ":active": {
                  backgroundColor: "#84CAFF", // Tailwind blue-400
                },
              }),
              noOptionsMessage: (baseStyles) => ({
                ...baseStyles,
                fontSize: "12px",
              }),
            }}
          />
        </SortableContext>
      </DndContext>
      <ResetColumnsButton
        isNotDefaultSetup={isNotDefaultSetup}
        setSelectedOptions={setSelectedOptions}
        recordsType={recordsType}
      />
    </div>
  );
}

export { ColumnsToggle };
