// types
import type { animate, dismiss, lockScroll, offset, placement } from "../../../types/components/menu";
export interface MenuStylesType {
  defaultProps: {
    placement: placement;
    offset: offset;
    dismiss: dismiss;
    animate: animate;
    lockScroll: lockScroll;
  };
  styles: {
    base: {
      menu: object;
      item: {
        initial: object;
        disabled: object;
      };
    };
  };
}

export const menu: MenuStylesType = {
  defaultProps: {
    placement: "bottom",
    offset: 5,
    dismiss: {
      itemPress: true,
    },
    animate: {
      unmount: {},
      mount: {},
    },
    lockScroll: false,
  },
  styles: {
    base: {
      menu: {
        bg: "bg-white",
        minWidth: "min-w-[180px]",
        p: "p-0",
        border: "border border-gray-200",
        borderRadius: "rounded",
        boxShadow: "shadow-lg shadow-gray-800/10",
        fontFamily: "font-sans",
        fontSize: "text-sm",
        fontWeight: "font-normal",
        color: "text-gray-800",
        overflow: "overflow-auto",
        outline: "focus:outline-none",
        zIndex: "z-[9997]",
      },
      item: {
        initial: {
          display: "block",
          width: "w-full",
          pt: "pt-3",
          pb: "pb-3",
          px: "px-4",
          borderRadius: "rounded-none",
          textAlign: "text-start",
          lightHeight: "leading-tight",
          cursor: "cursor-pointer",
          userSelect: "select-none",
          transition: "transition-all",
          bg: "hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-200",
          color: "hover:text-gray-900 focus:text-gray-900 active:text-gray-900",
          outline: "outline-none",
        },
        disabled: {
          opacity: "opacity-50",
          cursor: "cursor-not-allowed",
          pointerEvents: "pointer-events-none",
          userSelect: "select-none",
          bg: "hover:bg-transparent focus:bg-transparent active:bg-transparent",
          color: "hover:text-gray-500 focus:text-gray-500 active:text-gray-500",
        },
      },
    },
  },
};

export default menu;
