import PropTypes from "prop-types";
import React from "react";

// @floating-ui
import {
  FloatingNode,
  FloatingTree,
  autoUpdate, // Import autoUpdate
  flip,
  offset as fuiOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";

// utils
import merge from "deepmerge";

// context
import { useTheme } from "../../context/useTheme";
import { PopoverContextProvider, usePopover } from "./PopoverContext";

// types
import type { animate, children, dismiss, handler, offset, open, placement } from "../../types/components/popover";
import {
  propTypesAnimate,
  propTypesChildren,
  propTypesDismiss,
  propTypesHandler,
  propTypesOffset,
  propTypesOpen,
  propTypesPlacement,
} from "../../types/components/popover";

import { PopoverContent, type PopoverContentProps } from "./PopoverContent";
// popover components
import { PopoverHandler, type PopoverHandlerProps } from "./PopoverHandler";

export interface PopoverProps {
  open?: open;
  handler?: handler;
  placement?: placement;
  offset?: offset;
  dismiss?: dismiss;
  animate?: animate;
  children: children;
  ref?: React.Ref<HTMLButtonElement>;
}

const PopoverCore = ({ open, handler, placement, offset, dismiss, animate, children, ref }: PopoverProps) => {
  const { popover } = useTheme();
  const { defaultProps } = popover;
  const [internalOpen, setInternalOpen] = React.useState(false);

  open = open ?? internalOpen;
  handler = handler ?? setInternalOpen;
  placement = placement ?? defaultProps?.placement;
  offset = offset ?? defaultProps?.offset;
  dismiss = dismiss ?? defaultProps?.dismiss;
  animate = animate ?? defaultProps?.animate;

  const animation = {
    unmount: {
      opacity: 0,
    },
    mount: {
      opacity: 1,
    },
  };
  const appliedAnimation = merge(animation, animate ?? {});

  const parentId = useFloatingParentNodeId();
  const nodeId = useFloatingNodeId(parentId ?? undefined);
  const { x, y, strategy, refs, context } = useFloating({
    open,
    nodeId,
    onOpenChange: handler,
    middleware: [fuiOffset(offset), flip(), shift()],
    placement,
    strategy: "absolute",
    whileElementsMounted: autoUpdate,
  });

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, dismiss),
  ]);

  const referenceRef = useMergeRefs([refs.setReference, ref]);
  const contextValue = React.useMemo(
    () => ({
      open,
      strategy,
      x,
      y,
      context,
      reference: referenceRef,
      floating: refs.setFloating,
      getReferenceProps,
      getFloatingProps,
      appliedAnimation,
      labelId,
      descriptionId,
    }),
    [
      open,
      strategy,
      x,
      y,
      context,
      refs,
      referenceRef,
      getFloatingProps,
      getReferenceProps,
      appliedAnimation,
      labelId,
      descriptionId,
    ],
  );

  return (
    // @ts-ignore
    <PopoverContextProvider value={contextValue}>
      <FloatingTree>
        <FloatingNode id={nodeId}>{children}</FloatingNode>
      </FloatingTree>
    </PopoverContextProvider>
  );
};

const Popover = React.forwardRef<HTMLButtonElement, PopoverProps>(
  ({ open, handler, placement, offset, dismiss, animate, children }, ref) => {
    const props = {
      open,
      handler,
      placement,
      offset,
      dismiss,
      animate,
    };

    return (
      <PopoverCore {...props} ref={ref}>
        {children}
      </PopoverCore>
    );
  },
);

Popover.propTypes = {
  open: propTypesOpen,
  handler: propTypesHandler,
  placement: PropTypes.oneOf(propTypesPlacement),
  offset: propTypesOffset,
  dismiss: propTypesDismiss,
  animate: propTypesAnimate,
  children: propTypesChildren,
};

Popover.displayName = "VIP.Popover";

export type { PopoverHandlerProps, PopoverContentProps };
export { Popover, PopoverHandler, PopoverContent, usePopover };
export default Object.assign(Popover, { Handler: PopoverHandler, Content: PopoverContent });