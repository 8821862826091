import { useSnapshot } from "valtio";
import { proxy, snapshot } from "valtio/vanilla";

interface GlobalAppState {
  version: string;
  isVersionMismatch: boolean;
}

const globalAppState = proxy<GlobalAppState>({
  version: "",
  isVersionMismatch: false,
});

function updateGlobalAppState(newState: Partial<GlobalAppState>) {
  Object.assign(globalAppState, newState);
}

function getGlobalAppStateSnapshot() {
  return snapshot(globalAppState);
}

function useGlobalAppState() {
  return useSnapshot(globalAppState);
}

export { updateGlobalAppState, getGlobalAppStateSnapshot, useGlobalAppState };
