import type { color } from "@vip-tailwind/types/components/button";

const buttonFilled: Record<color, Record<string, string>> = {
  // Primary & Secondary
  primary: {
    backgroud: "bg-blue-600", // Primary/state-primary-enabled
    color: "text-white",
    hover: "hover:bg-blue-700",
    focus: "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1",
    active: "active:bg-blue-800 active:opacity-[0.85]",
  },
  secondary: {
    backgroud: "bg-white",
    color: "text-gray-800",
    border: "border border-gray-200",
    hover: "hover:bg-gray-200",
    focus: "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1",
    active: "active:bg-gray-300 active:opacity-[0.85]",
  },

  // Semantic colors
  success: {
    backgroud: "bg-green-700",
    color: "text-white",
    hover: "hover:bg-green-800",
    focus: "focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-1",
    active: "active:bg-green-900 active:opacity-[0.85]",
  },
  danger: {
    backgroud: "bg-red-700",
    color: "text-white",
    hover: "hover:bg-red-800",
    focus: "focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-1",
    active: "active:bg-red-900 active:opacity-[0.85]",
  },
  warning: {
    backgroud: "bg-red-200",
    color: "text-red-700",
    hover: "hover:bg-red-300",
    focus: "focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-1",
    active: "active:bg-red-300 active:opacity-[0.85]",
  },

  // Secondary variants
  "secondary-success": {
    backgroud: "bg-white",
    color: "text-green-700",
    border: "border border-green-700",
    hover: "hover:bg-green-100",
    focus: "focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-1",
    active: "active:bg-green-200 active:opacity-[0.85]",
  },
  "secondary-danger": {
    backgroud: "bg-white",
    color: "text-red-700",
    border: "border border-red-700",
    hover: "hover:bg-red-100",
    focus: "focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-1",
    active: "active:bg-red-200 active:opacity-[0.85]",
  },
};

export default buttonFilled;
