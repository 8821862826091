import classNames from "classnames";
import React, { useMemo } from "react";

import { FeedIcon } from "@components/FeedIcon";
import { FEEDS_LABELS_MAPPING } from "@configs/feeds";
import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";
import type SerializedMandiantMalware from "@interfaces/SerializedMandiantMalware";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { Row, Table } from "@tanstack/react-table";
import { capitalizeFirstLetter } from "@utils/utility";

import TableHeader from "./TableLayout/TableHeader";

function useAvailableFeeds(): string[] {
  const { data } = useOrganizationConfiguration();

  return useMemo(
    () => (data?.feeds?.length ? data.feeds.filter((feed) => feed.is_enabled).map((feed) => feed.db_prefix) : []),
    [data],
  );
}

function ExtraTableRow({
  row,
}: {
  row: Row<SerializedMandiantMalware | FrontendSerializedThreat>;
}) {
  const availableFeedsForCurrentUser = useAvailableFeeds();

  if (!(row.original as any)?.feeds?.length) {
    return null;
  }

  const feeds = (row as Row<FrontendSerializedThreat>).original.feeds ?? [];

  return (
    <>
      {/* every record contains own tr with feeds only as a column */}
      <tr className="h-2.5 border-b border-gray-300">
        <td colSpan={100}>
          <div className="relative">
            <div className="flex absolute -top-5 flex-wrap">
              <div className="flex flex-row gap-x-0.5 px-8">
                {availableFeedsForCurrentUser.map((feed) => {
                  const label = feed as keyof typeof FEEDS_LABELS_MAPPING;

                  return (
                    <div
                      className="flex justify-center items-center"
                      title={FEEDS_LABELS_MAPPING[label] || capitalizeFirstLetter(label)}
                      key={feed}
                    >
                      <FeedIcon fieldPrefix={feed} isActive={feeds.includes(feed)} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

const TableLayout = ({
  table,
  isFetching,
  showExtraRow,
  tableMaxHeight,
}: {
  table: Table<FrontendSerializedThreat | SerializedMandiantMalware>;
  isFetching: boolean;
  showExtraRow?: boolean;
  tableMaxHeight?: number;
}) => {
  const { getRowModel } = table;

  const ref = React.useRef(null);

  const rowModel = getRowModel();
  const rows = rowModel.rows;
  const isTableEmpty = !rows || rows.length === 0;

  return (
    <div
      className={classNames(
        "overflow-x-auto relative flex-1 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-rounded-full",
        {
          "opacity-50": isFetching,
          [`overflow-y-auto max-h-[${tableMaxHeight}px]`]: !!tableMaxHeight,
        },
      )}
      ref={ref}
    >
      <table
        className="relative w-full max-w-full text-sm text-left text-gray-600 border-collapse"
        style={{
          width: `${
            table.getTotalSize() + (table.getAllLeafColumns().length + 1) * 3 // I got better result when I added this which is related with the tr css borders !
          }px`,
        }}
      >
        <TableHeader table={table} />

        <tbody>
          {isTableEmpty ? (
            <>
              <tr>
                <td colSpan={100} className="px-6 py-3 text-center">
                  No threats found
                </td>
              </tr>
            </>
          ) : (
            rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr className="bg-white hover:bg-gray-50">
                    {row.getVisibleCells().map((cell: any, index) => (
                      <td
                        key={`${cell.id}_${index}`}
                        className={classNames("px-2 pt-1", {
                          "pb-4": showExtraRow,
                        })}
                      >
                        <cell.column.columnDef.cell {...cell.getContext()} />
                      </td>
                    ))}
                  </tr>
                  {showExtraRow && <ExtraTableRow row={row} />}
                </React.Fragment>
              );
            })
          )}
        </tbody>
        {/* <tfoot>
          {getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
      </table>
    </div>
  );
};

export default TableLayout;
