import PropTypes from "prop-types";
import React from "react";

// @floating-ui
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset as fuiOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";

// framer-motion
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";

// utils
import classnames from "classnames";
import merge from "deepmerge";
import { twMerge } from "tailwind-merge";
import objectsToString from "../../utils/objectsToString";

// context
import { useTheme } from "../../context/useTheme";

import type {
  animate,
  children,
  className,
  content,
  dismiss,
  handler,
  interactive,
  offset,
  open,
  placement,
} from "../../types/components/popover";
import {
  propTypesAnimate,
  propTypesChildren,
  propTypesClassName,
  propTypesContent,
  propTypesDismiss,
  propTypesHandler,
  propTypesInteractive,
  propTypesOffset,
  propTypesOpen,
  propTypesPlacement,
} from "../../types/components/popover";
// types
import type { NewAnimatePresenceProps } from "../../types/generic";

export interface TooltipProps extends React.ComponentProps<any> {
  open?: open;
  handler?: handler;
  content?: content;
  interactive?: interactive;
  placement?: placement;
  offset?: offset;
  dismiss?: dismiss;
  animate?: animate;
  className?: className;
  children: children | React.ComponentProps<any>;
}

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ open, handler, content, interactive, placement, offset, dismiss, animate, className, children, ...rest }, ref) => {
    // 1. init
    const { tooltip } = useTheme();
    const {
      defaultProps,
      styles: { base },
    } = tooltip;
    const [internalOpen, setInternalOpen] = React.useState(false);

    // 2. set default props
    open = open ?? internalOpen;
    handler = handler ?? setInternalOpen;
    interactive = interactive ?? defaultProps.interactive;
    placement = placement ?? defaultProps.placement;
    offset = offset ?? defaultProps.offset;
    dismiss = dismiss ?? defaultProps.dismiss;
    animate = animate ?? defaultProps.animate;
    className = twMerge(defaultProps.className || "", className);

    // 3. set styles
    const tooltipClasses = twMerge(classnames(objectsToString(base)), className);

    // 4. set animation
    const animation = {
      unmount: {
        opacity: 0,
        transition: { duration: 0.2, ease: "easeOut" },
      },
      mount: {
        opacity: 1,
        transition: { duration: 0.2, ease: "easeOut" },
      },
    };
    const appliedAnimation = merge(animation, animate ?? {});

    // 5. Update @floating-ui implementation
    const { refs, floatingStyles, context } = useFloating({
      open,
      onOpenChange: handler,
      middleware: [fuiOffset(offset), flip(), shift({ padding: 5 })],
      placement,
      whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useClick(context, {
        enabled: interactive,
      }),
      useFocus(context),
      useHover(context),
      useRole(context, { role: "tooltip" }),
      useDismiss(context, dismiss),
    ]);

    const mergedRef = useMergeRefs([ref, refs.setFloating]);
    const childMergedRef = useMergeRefs([ref, refs.setReference]); 

    // 6. Create an instance of AnimatePresence because of the types issue with the children
    const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;

    // 7. return
    return (
      <>
        {typeof children === "string" ? (
          <span
            {...getReferenceProps({
              ref: childMergedRef,
            })}
          >
            {children}
          </span>
        ) : (
          React.cloneElement(children, {
            ...getReferenceProps({
              ...children?.props,
              ref: childMergedRef,
            }),
          })
        )}
        <LazyMotion features={domAnimation}>
          {open && (
            <FloatingPortal>
              <NewAnimatePresence>
                <m.div
                  {...getFloatingProps({
                    ...rest,
                    ref: mergedRef,
                    className: tooltipClasses,
                    style: floatingStyles,
                  })}
                  initial="unmount"
                  exit="unmount"
                  animate={open ? "mount" : "unmount"}
                  variants={appliedAnimation}
                >
                  {content}
                </m.div>
              </NewAnimatePresence>
            </FloatingPortal>
          )}
        </LazyMotion>
      </>
    );
  },
);

Tooltip.propTypes = {
  open: propTypesOpen,
  handler: propTypesHandler,
  content: propTypesContent,
  interactive: propTypesInteractive,
  placement: PropTypes.oneOf(propTypesPlacement),
  offset: propTypesOffset,
  dismiss: propTypesDismiss,
  animate: propTypesAnimate,
  className: propTypesClassName,
  children: propTypesChildren,
};

Tooltip.displayName = "VIP.Tooltip";

export default Tooltip;
