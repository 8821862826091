import type { ThreatKey } from "@interfaces/SerializedThreat";
import {
  type IntrospectionsResponse,
  type ModelPropertyConfiguration,
  getIntrospections,
} from "@queries/useIntrospections";
import {
  CVE_ID_LIKE_REGEX,
  FULL_CVE_ID_REGEX,
  LEXER_TOKENS,
  type LexerToken,
  TOKEN_TYPE,
  getLexer,
} from "@utils/Lexer";
import { getFieldConfig } from "@utils/getFieldConfig";

import { FIELD_TYPE } from "./utils";

export const stringToCVEsLikeList = (searchQuery: string) => {
  // Handle comma(or space)-separated list of CVEs
  const strParts = searchQuery
    .split(/[ ,"']/)
    .map((cve) => cve.trim())
    .filter((cve) => cve.length);

  const cveList = strParts.filter((cve: string) => CVE_ID_LIKE_REGEX.test(cve));

  // we consider correct transformation only if all the strings in the list are valid CVE IDs
  if (cveList.length && cveList.length === strParts.length) {
    return cveList;
  }

  return [];
};

export function autofixCveId(searchQuery: string) {
  // Special case with shortcut search by id
  if (searchQuery.length && CVE_ID_LIKE_REGEX.exec(searchQuery?.trim())) {
    searchQuery = `vip_id ${FULL_CVE_ID_REGEX.test(searchQuery) ? "=" : "~"} "${searchQuery.trim()}"`;
  }

  const cveList = stringToCVEsLikeList(searchQuery);
  if (cveList.length > 1) {
    // if cve if full(FULL_CVE_ID_REGEX), then we lookup via full match "="
    searchQuery = cveList.map((cve) => `vip_id ${FULL_CVE_ID_REGEX.test(cve) ? "=" : "~"} "${cve}"`).join(" or ");
  } else if (cveList.length === 1) {
    searchQuery = `vip_id ${FULL_CVE_ID_REGEX.test(cveList[0]?.toString() ?? "") ? "=" : "~"} "${cveList[0]}"`;
  }

  return searchQuery;
}

/**
 * Generates an updated search query by fixing missing quotes around string values in the search query based on provided introspections.
 *
 * @param {string} searchQuery - The original search query that needs to be updated
 * @param {IntrospectionsResponse} introspections - The introspections containing information about the fields and their types
 * @return {string} The updated search query with missing quotes added around string values
 */
function autoFixSearchQuery(searchQuery = "", introspections: IntrospectionsResponse = getIntrospections()) {
  searchQuery = autofixCveId(searchQuery);

  // The character U+2018 "‘" could be confused with the ASCII character U+0060 "`", which is more common in source code. This is why we need to replace them with ASCII character U+0027 "'"
  // Same with U+2019 "’" and U+0027 "'"
  searchQuery = searchQuery.replace(/‘/g, '"').replace(/’/g, '"');

  const tokens: LexerToken[] = getLexer().setInput(searchQuery).lexAll();

  // when we have missed quotes arount some string value, f.e. `mandiant_last_updated_by_nucleus within "7 days" and mandiant_risk_rating = Critical` should figure out that Critical is a string value and add quotes around it
  // tokens always must be in this order: TOKEN_TYPE.FIELD, TOKEN_TYPE.OPERATOR, TOKEN_TYPE.VALUE. TOKEN_TYPE.MISC can be anywhere and ignored, TOKEN_TYPE.LOGIC can be anywhere between correct combinations
  // For above problem, order would be ... TOKEN_TYPE.FIELD, TOKEN_TYPE.OPERATOR, TOKEN_TYPE.FIELD ..., so we can assume that if field(first one) is expected to have a string value, it was supposed to be a string instead of field
  // We can check if field is expecting a string value by checking introspections.fields[fieldName].type === "string"

  // Iterate through tokens to fix missing quotes
  let updatedSearchQuery = "";
  let lastFieldName: ThreatKey | undefined = undefined;
  tokens.forEach((token, index) => {
    let token_value = token.value;

    if (token.type === TOKEN_TYPE.FIELD) {
      lastFieldName = token.value as ThreatKey;
    } else if (token.type === TOKEN_TYPE.FUNCTION) {
      lastFieldName = token.name.toLowerCase() as ThreatKey; // this is hacky, works for only our existing function field for now, but rest functional fields has to follow same naming convention
    }

    const previousToken = tokens[index - 1];
    const previousFieldToken = tokens[index - 2];
    if (
      // current token is a field
      token.type === TOKEN_TYPE.FIELD &&
      previousFieldToken &&
      // previous token is an operator
      previousToken?.type === TOKEN_TYPE.OPERATOR &&
      // previous field token is a string type
      (introspections.fields as any)[previousFieldToken.value]?.type === FIELD_TYPE.STRING
    ) {
      // Check if the previous token is an operator. We wont have a field after an operator
      if (previousToken?.type === TOKEN_TYPE.OPERATOR) {
        token_value = `"${token_value}"`;
      }
    }

    const fieldConfig = getFieldConfig(introspections, lastFieldName);

    // Rebuild the search query, adding spaces when needed
    updatedSearchQuery += tokenToSearchQuery(tokens[index - 1]!, fieldConfig, { ...token, value: token_value }, index);
  });

  return updatedSearchQuery;
}

// todo: this function semi-follows transformTokens from frontends/utils/searchQueryToUrl/transformTokens.ts and should be refactored to share some code
function tokenToSearchQuery(
  lastToken: LexerToken | null,
  fieldConfig: ModelPropertyConfiguration | null,
  token: LexerToken,
  index: number,
) {
  let tranformedValue = token.value;
  let prefix = index > 0 ? " " : "";
  let suffix = "";

  switch (token.name) {
    case LEXER_TOKENS.PAREN_L: {
      prefix = " ";
      break;
    }

    case LEXER_TOKENS.PAREN_R: {
      prefix = "";
      break;
    }

    case LEXER_TOKENS.STRING_VALUE: {
      prefix = ' "';
      suffix = '"';

      // could be that this token may need to be transformed into int or float
      if (!fieldConfig) {
        break;
      }

      // auto-fix case-insensitive option matching
      if (fieldConfig.type === FIELD_TYPE.STRING && fieldConfig.constrains?.options?.length) {
        const matchingOption = fieldConfig.constrains.options.find(
          (option) => option.toLowerCase() === token.value.toLowerCase(),
        );

        if (matchingOption) {
          tranformedValue = matchingOption;
        }
      }

      if (fieldConfig.type === FIELD_TYPE.INTEGER) {
        tranformedValue = Number.parseInt(token.value, 10).toString();
        prefix = index > 0 ? " " : "";
        suffix = "";
      } else if (fieldConfig.type === FIELD_TYPE.FLOAT) {
        // transform to float with 1 decimal place
        const floatValue = Number.parseFloat(token.value);
        if (Number.isNaN(floatValue)) {
          // value is wrong, raise an error handler
        } else {
          tranformedValue = floatValue.toFixed(2);
          prefix = index > 0 ? " " : "";
          suffix = "";

          // Could be that this value is a percentage, so we need to convert it to float
          if (fieldConfig.constrains?.display_type === "percents" && floatValue > 1) {
            // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
            tranformedValue = (floatValue / 100).toFixed(2);
          }
        }
      }

      break;
    }

    case LEXER_TOKENS.FLOAT_VALUE: {
      if (!fieldConfig) {
        break;
      }

      // Float may be represented with '.X' instead of '0.X', so we need to add '0' in front of it
      if (token.value.startsWith(".")) {
        tranformedValue = `0${token.value}`;
      }

      const floatValue = Number.parseFloat(tranformedValue);
      // Could be that this value is a percentage, so we need to convert it to float
      if (fieldConfig.constrains?.display_type === "percents" && floatValue > 1) {
        // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
        tranformedValue = (floatValue / 100).toFixed(2);
      }

      break;
    }

    case LEXER_TOKENS.INT_VALUE: {
      if (!fieldConfig) {
        break;
      }

      const intValue = Number.parseInt(token.value, 10);

      // Could be that this value is a percentage, so we need to convert it to float
      if (fieldConfig.constrains?.display_type === "percents" && intValue > 1) {
        // database values for percents are floats between 0 and 1 and frontend values are integers between 0 and 100, we need to convert it
        tranformedValue = (intValue / 100).toFixed(2);
      }

      break;
    }

    case LEXER_TOKENS.HISTORY: {
      tranformedValue = `history(${token.value})`;
      break;
    }
  }

  // no need spaces after parentheses left bracket
  if (lastToken && ["PAREN_L"].includes(lastToken.name)) {
    prefix = prefix.trim();
  }

  // Modify token in-place in the outputTokens array
  return `${prefix}${tranformedValue}${suffix}`;
}

export default autoFixSearchQuery;
