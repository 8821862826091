import { Tooltip } from "@vip-tailwind/components/Tooltip";
import classNames from "classnames";
import { forwardRef } from "react";
import type { MouseEventHandler, ReactNode } from "react";

interface ActionButtonProps {
  children: ReactNode;
  tooltip?: string;
  classes?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
}
// @deprecated use IconButton component instead
const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      tooltip,
      children,
      classes = "bg-green-500 hover:bg-green-600 text-white",
      disabled = false,
      onClick,
      size = "md",
      ...props
    },
    ref,
  ) => {
    return (
      <button
        className={classNames(
          `flex items-center justify-center border border-gray-300 rounded transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed text-base hover:border-gray-500 ${classes}`,
          {
            "w-6 h-6": size === "xs",
            "w-8 h-8": size === "sm",
            "w-10 h-10": size === "md",
            "w-12 h-12": size === "lg",
          },
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {tooltip ? (
          <Tooltip content={tooltip}>
            <div className="w-full h-full flex items-center justify-center">{children}</div>
          </Tooltip>
        ) : (
          children
        )}
      </button>
    );
  },
);
ActionButton.displayName = "ActionButton";

export { ActionButton };