import React from "react";

// utils
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import objectsToString from "../../utils/objectsToString";

// context
import { useTheme } from "../../context/useTheme";

// types
import type { children, className, disabled } from "../../types/components/menu";
import { propTypesChildren, propTypesClassName, propTypesDisabled } from "../../types/components/menu";

export interface MenuItemProps extends React.ComponentProps<"li"> {
  className?: className;
  disabled?: disabled;
  children: children;
}

export const MenuItem = React.forwardRef<
  HTMLButtonElement,
  MenuItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className = "", disabled = false, children, ...rest }, ref) => {
  // 1. init
  const { menu } = useTheme();
  const base = menu?.styles?.base ?? {};

  // 2. set styles
  const menuItemClasses = twMerge(
    classnames(objectsToString(base.item?.initial), {
      [objectsToString(base.item?.disabled)]: disabled,
    }),
    className,
  );

  // 4. return
  return (
    <button {...rest} ref={ref} role="menuitem" className={menuItemClasses}>
      {children}
    </button>
  );
});

MenuItem.propTypes = {
  className: propTypesClassName,
  disabled: propTypesDisabled,
  children: propTypesChildren,
};

MenuItem.displayName = "VIP.MenuItem";

export default MenuItem;
