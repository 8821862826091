import type { color } from "@vip-tailwind/types/components/input";

const textareaOutlinedLabelColors: Record<color, object> = {
  primary: {
    color: "text-gray-400 peer-focus:text-blue-500",
    before: "before:border-gray-200 peer-focus:before:!border-blue-500",
    after: "after:border-gray-200 peer-focus:after:!border-blue-500",
  },
  warning: {
    color: "text-gray-400 peer-focus:text-orange-500",
    before: "before:border-gray-200 peer-focus:before:!border-orange-500",
    after: "after:border-gray-200 peer-focus:after:!border-orange-500",
  },
  success: {
    color: "text-gray-400 peer-focus:text-green-500",
    before: "before:border-gray-200 peer-focus:before:!border-green-500",
    after: "after:border-gray-200 peer-focus:after:!border-green-500",
  },
  danger: {
    color: "text-gray-400 peer-focus:text-red-500",
    before: "before:border-gray-200 peer-focus:before:!border-red-500",
    after: "after:border-gray-200 peer-focus:after:!border-red-500",
  },
};

export default textareaOutlinedLabelColors;
