import PropTypes from "prop-types";
import type { ReactNode } from "react";

// generic types
import type { semantic_colors as generic_semantic_colors } from "../generic";

/**
 * This file contains the types and prop-types for Button and IconButton component.
 */

export type semantic_colors =
  | "primary"
  | "secondary"
  | "secondary-success"
  | "secondary-danger"
  | generic_semantic_colors;
export type variant = "filled" | "outlined" | "text";
export type size = "sm" | "md" | "lg";
export type color = semantic_colors;
export type fullWidth = boolean;
export type ripple = boolean;
export type className = string;
export type children = ReactNode;
export type loading = boolean;

export const propTypesVariant: variant[] = ["filled", "outlined", "text"];
export const propTypesSize: size[] = ["sm", "md", "lg"];
export const propTypesColor: color[] = [
  "primary",
  "secondary",
  "secondary-success",
  "secondary-danger",
  "success",
  "danger",
  "warning",
];
export const propTypesFullWidth = PropTypes.bool;
export const propTypesRipple = PropTypes.bool;
export const propTypesClassName = PropTypes.string;
export const propTypesChildren: any = PropTypes.node.isRequired;
export const propTypesLoading = PropTypes.bool;
