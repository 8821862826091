import { autofixCveId } from "@components/DjangoQL/autoFixSearchQuery";
import { FULL_CVE_ID_REGEX } from "@utils/Lexer";
import { CVE_FORMATS } from "@utils/Lexer";
import { useCallback, useState } from "react";
import { CVEDialog, type CVEParseResult } from "./CVEDialog";

interface UseCVEPasteParams {
  onValueChange: (value: string, cursorPos: number) => void;
}

const analyzePastedCVEs = (text: string): CVEParseResult => {
  const originalText = text.trim();
  const cves = extractCVEsFromText(text); // You'll need to implement this based on your existing logic

  const normalizedOriginal = originalText
    .replace(/[\n\r,\s]+/g, " ")
    .trim()
    .toUpperCase();

  const normalizedCVEs = cves.join(" ");
  const pureMatch = normalizedOriginal === normalizedCVEs;

  return {
    cves,
    pureMatch,
    originalText,
  };
};

function extractCVEsFromText(text: string): string[] {
  const normalizedText = text.toUpperCase();
  const cves = new Set<string>();

  // Extract CVEs using different patterns
  for (const pattern of Object.values(CVE_FORMATS)) {
    const matches = normalizedText.match(pattern) || [];
    for (const match of matches) {
      // Clean up and normalize CVE IDs
      const cleaned = match.replace(/[,\s]+/g, " ").trim();
      for (const cve of cleaned.split(/\s+/)) {
        if (FULL_CVE_ID_REGEX.test(cve)) {
          cves.add(cve);
        }
      }
    }
  }

  return Array.from(cves);
}

export function useCVEPaste({ onValueChange }: UseCVEPasteParams) {
  const [showCVEDialog, setShowCVEDialog] = useState(false);
  const [currentParseResult, setCurrentParseResult] = useState<CVEParseResult | null>(null);
  const [pastePosition, setPastePosition] = useState<number>(0);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const text = e.clipboardData.getData("text");
    const parseResult = analyzePastedCVEs(text);

    // only consider multiple CVEs pasted
    if (parseResult.cves.length > 1) {
      e.preventDefault();
      const cursorPos = e.currentTarget.selectionStart;
      setPastePosition(cursorPos);

      if (parseResult.pureMatch) {
        // Direct paste for pure CVE lists
        const newValue = autofixCveId(parseResult.cves.join(" "));
        onValueChange(newValue, cursorPos);
        return;
      }

      // Show dialog only when we're potentially discarding extra content
      setCurrentParseResult(parseResult);
      setShowCVEDialog(true);
    }
  }, []);

  const handleCVEDialogClose = useCallback(
    (useCVEs: boolean) => {
      setShowCVEDialog(false);

      if (currentParseResult) {
        const newValue = useCVEs ? autofixCveId(currentParseResult.cves.join(" ")) : currentParseResult.originalText;

        onValueChange(newValue, pastePosition);
      }

      setCurrentParseResult(null);
    },
    [currentParseResult, onValueChange, pastePosition],
  );

  const DialogComponent = useCallback(() => {
    if (!currentParseResult) {
      return null;
    }

    return <CVEDialog parseResult={currentParseResult} onClose={handleCVEDialogClose} open={showCVEDialog} />;
  }, [currentParseResult, showCVEDialog, handleCVEDialogClose]);

  return {
    handlePaste,
    DialogComponent,
  };
}
