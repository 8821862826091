// Own wrapper around useMutation to make it easier to use fetch with react-query
import { type UseMutationOptions, useMutation } from "@tanstack/react-query";

import { captureException } from "@sentry/react";
import { getCsrfToken } from "./getCsrfToken";

interface BasicJSONResponse {
  ok: boolean;
  message?: string;
}

class ErrorWithResponse extends Error {
  responseJSON: Record<string, any>;
  logInSentry = true;

  constructor(message: string, responseJSON: Record<string, any>) {
    super(message);
    this.responseJSON = responseJSON;
  }
}

function useFetchMutation<ResponseData, TError, TVariables = undefined, TContext = undefined>(
  endpoint: string | ((variables?: TVariables) => string),
  body: object | ((variables?: TVariables) => object),
  mutationOptions: Omit<
    UseMutationOptions<ResponseData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  > = {},
  method = "POST",
) {
  return useMutation<ResponseData, TError, TVariables, TContext>({
    mutationFn: async (variables?: TVariables) => {
      const endpointUrl = endpoint instanceof Function ? endpoint(variables) : endpoint;
      const bodyBody = JSON.stringify(body instanceof Function ? body(variables) : body);
      // todo: ideally we should use fetchWithSession here, but its having duplicated functionality which makes it hard to replace as it is
      // Get CSRF token for mutating requests
      const csrfToken = await getCsrfToken();
      return (
        fetch(endpointUrl, {
          method: method,
          body: bodyBody,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
            "X-Requested-With": "XMLHttpRequest",
          },
        }) as unknown as Promise<Response>
      ).then((response) => {
        if (method === "DELETE" && response.status === 204) {
          return response as ResponseData; // delete response could be 204 and empty
        }

        if (response.status >= 400) {
          // There could be error message in response
          const defaultErrorMessage = "Something went wrong. Please try again.";
          if (response.status === 400) {
            return response.json().then((responseJSON) => {
              if (responseJSON.message) {
                const error = new ErrorWithResponse(responseJSON.message, responseJSON);
                error.logInSentry = false;
                throw error;
              }

              if (responseJSON?.value?.[0]) {
                const error = new ErrorWithResponse(responseJSON.value[0], responseJSON);
                error.logInSentry = false;
                throw error;
              }

              const arrayResponse = Array.isArray(responseJSON) && responseJSON[0] ? responseJSON[0] : responseJSON;
              const error = new ErrorWithResponse(arrayResponse ? arrayResponse : defaultErrorMessage, responseJSON);
              error.logInSentry = typeof arrayResponse === "string";
              throw error;
            });
          }

          if (response.status === 500) {
            // lets stringify the response body
            response.text().then((responseBody) => {
              captureException(new Error(`Server error: ${responseBody}`), {
                level: "error",
                extra: {
                  endpointUrl,
                  method,
                  body: bodyBody,
                },
              });
            });
          }

          throw new Error(defaultErrorMessage);
        }

        return response.json() as Promise<ResponseData>;
      });
    },
    ...mutationOptions,
  });
}

// biome-ignore lint/style/noDefaultExport: <ok for hook>
export default useFetchMutation;
export type { BasicJSONResponse };
export { ErrorWithResponse };
