import classNames from "classnames";
import type React from "react";

import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import useMutateBookmarkStatus from "@queries/useBookmarkStatus";
import type { CellContext } from "@tanstack/react-table";

const QuickBookmarkStatusRenderer: (
  context: CellContext<FrontendSerializedThreat, FrontendSerializedThreat>,
) => string | JSX.Element = (context) => {
  const { mutateAsync } = useMutateBookmarkStatus();

  const handleQuickBookmark = async (
    event: React.MouseEvent<HTMLElement>,
    cve_threat_intel_id: number,
    isBookmarked: boolean,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    await mutateAsync({
      threat_id: cve_threat_intel_id,
      new_status: isBookmarked,
    });
  };

  const threat = context.getValue();
  const isBookmarked = threat.favorite;

  // SVG bookmark icon, should be a dark outline but not filled in (i.e. not bookmarked) and a filled in blue icon (i.e. bookmarked) when isBookmarked
  return (
    <i
      className={classNames("hover:text-blue-700 cursor-pointer", {
        "far fa-star": !isBookmarked,
        "fas fa-star text-blue-500": isBookmarked,
      })}
      onClick={(event) => handleQuickBookmark(event, threat.cve_threat_intel_id, !isBookmarked)}
    />
  );
};

export default QuickBookmarkStatusRenderer;
