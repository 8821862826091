import type { color } from "@vip-tailwind/types/components/input";

const inputStandardLabelColors: Record<color, object> = {
  primary: {
    color: "!text-gray-600 peer-focus:!text-blue-700",
    borderColor: "border-black",
    borderColorFocused: "focus:border-blue-700",
  },
  danger: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-orange-500",
  },
  success: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-green-500",
  },
  warning: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-yellow-500",
  },
};

export default inputStandardLabelColors;
