import { useCreateToast } from "@hooks/useCreateToast";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { type UseSuspenseQueryOptions, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation, { type ErrorWithResponse } from "@utils/useFetchMutation";
import type { CombinedFieldReference } from "./fieldReferences.interfaces";
import { setErrorData } from "./sourcesFieldsReference.state";

const fetchFieldReferences = async (): Promise<FieldReferenceApiResponse[]> => {
  return fetchWithSession(fieldReferencesUrl);
};

const useFieldReferences = (options: Partial<UseSuspenseQueryOptions<FieldReferenceApiResponse[]>> = {}) => {
  return useSuspenseQuery<FieldReferenceApiResponse[]>({
    queryKey: ["fieldReferences"],
    queryFn: fetchFieldReferences,
    ...options,
  });
};

const fieldReferencesUrl = "/api/v1/field_references/";

interface FieldReferenceApiResponse {
  id: number;
  field: ThreatKey;
  description: string;
  possible_values: string[];
  created_by: string;
  created_at: string;
  updated_at: string;
  is_from_json: boolean;
  is_from_introspection: boolean;
}

const useBulkUpsertFieldReferences = () => {
  const queryClient = useQueryClient();
  const { addMessage } = useCreateToast();

  return useFetchMutation<FieldReferenceApiResponse[], ErrorWithResponse, CombinedFieldReference[], unknown>(
    () => fieldReferencesUrl,
    (data) =>
      data?.map((item) => ({
        field: item.field,
        description: item.description,
        possible_values: item.possible_values,
      })),
    {
      throwOnError: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["fieldReferences"] });
        setErrorData({}, false);
        addMessage({
          title: "Success!",
          content: "Field references updated successfully",
          variant: "success",
          duration: 2000,
        });
      },
      onError: (error: ErrorWithResponse) => {
        if (
          error.responseJSON &&
          typeof error.responseJSON === "object" &&
          Object.keys(error.responseJSON).length > 0
        ) {
          setErrorData(error.responseJSON);
        }

        addMessage({
          title: "Error!",
          content: `Could not update field references: ${error.message}`,
          variant: "error",
          duration: 3000,
        });
      },
    },
    "POST",
  );
};

export { useFieldReferences, useBulkUpsertFieldReferences };
