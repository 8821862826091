import { isNil } from "lodash-es";

import type { BadgeProps } from "@components/Badge";
import type { ThreatKey } from "@interfaces/SerializedThreat";

// Mapping various values to color classes
export const COLORS_CONFIGURATION: Partial<
  Record<
    ThreatKey,
    {
      [key: string]: BadgeProps["color"];
    }
  >
> = {
  nvd_cvssv3_attack_vector: {
    NETWORK: "error",
    ADJACENT: "warning",
    LOCAL: "notice",
    PHYSICAL: "success",
    NO_DATA: "default",
    ADJACENT_NETWORK: "notice",
  },
  nvd_cvssv3_attack_complexity: {
    HIGH: "success",
    LOW: "error",
  },
  nvd_cvssv3_user_interaction: {
    NONE: "error",
    REQUIRED: "success",
  },
  nvd_cvssv3_privileges_required: {
    NONE: "error",
    LOW: "warning",
    HIGH: "notice",
  },
  nvd_cvssv3_scope: {
    UNCHANGED: "error",
    CHANGED: "success",
  },
  nvd_cvssv3_confidentiality_impact: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv3_integrity_impact: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv3_availability_impact: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv2_access_vector: {
    NETWORK: "error",
    ADJACENT: "warning",
    ADJACENT_NETWORK: "warning",
    LOCAL: "notice",
  },
  nvd_cvssv2_access_complexity: {
    LOW: "error",
    MEDIUM: "warning",
    HIGH: "notice",
  },
  nvd_cvssv2_authentication: {
    NONE: "error",
    SINGLE: "warning",
    MULTIPLE: "notice",
  },
  nvd_cvssv2_confidentiality_impact: {
    COMPLETE: "error",
    PARTIAL: "notice",
    NONE: "success",
  },
  nvd_cvssv2_integrity_impact: {
    COMPLETE: "error",
    PARTIAL: "notice",
    NONE: "success",
  },
  nvd_cvssv2_availability_impact: {
    COMPLETE: "error",
    PARTIAL: "notice",
    NONE: "success",
  },
  mandiant_risk_rating: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
    UNRATED: "default",
  },
  nvd_cvssv3_base_severity: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
    NONE: "primary",
  },
  nvd_cvssv4_base_severity: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
    NONE: "primary",
  },
  nvd_cvssv2_severity: {
    HIGH: "error",
    MEDIUM: "notice",
    LOW: "success",
  },
  mandiant_exploit_rating: {
    WIDE: "error",
    AVAILABLE: "warning",
    CONFIRMED: "notice",
    "NO KNOWN": "primary",
  },
  mandiant_exploit_in_the_wild: {
    TRUE: "error",
    FALSE: "success",
  },
  mandiant_affects_ot: {
    TRUE: "error",
    FALSE: "success",
  },
  mandiant_exploited_by_malware: {
    TRUE: "error",
    FALSE: "success",
  },
  mandiant_zero_day: {
    TRUE: "error",
    FALSE: "success",
  },
  intel471_risk_level: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
  },
  intel471_underground_activity_observed: {
    TRUE: "error",
    FALSE: "success",
  },
  intel471_poc_observed: {
    TRUE: "error",
    FALSE: "success",
  },
  intel471_exploit_status_available: {
    TRUE: "error",
    FALSE: "success",
  },
  intel471_exploit_status_weaponized: {
    TRUE: "error",
    FALSE: "success",
  },
  intel471_exploit_status_productized: {
    TRUE: "error",
    FALSE: "success",
  },
  defender_exploit_verified: {
    TRUE: "error",
    FALSE: "success",
  },
  defender_public_exploit: {
    TRUE: "error",
    FALSE: "success",
  },
  defender_exploit_in_kit: {
    TRUE: "error",
    FALSE: "success",
  },
  rf_criticality: {
    "VERY CRITICAL": "error",
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
  },
  nvd_status: {
    MODIFIED: "info",
    ANALYZED: "info",
    DEFERRED: "info",
    "UNDERGOING ANALYSIS": "info",
    "AWAITING ANALYSIS": "info",
    RECEIVED: "info",
  },
  nuclei_severity: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
  },
  shadowserver_severity: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
    NONE: "success",
  },
  nuclei_template_exists: {
    TRUE: "error",
    FALSE: "success",
  },
  metasploit_module_exists: {
    TRUE: "error",
    FALSE: "success",
  },
  exploitdb_exists: {
    TRUE: "error",
    FALSE: "success",
  },
  shadowserver_affects_iot: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_definitive_severity: {
    CRITICAL: "error",
    HIGH: "warning",
    MEDIUM: "notice",
    LOW: "success",
  },
  securin_exploited_in_wild: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_is_trending: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_is_discussed: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_has_poc: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_has_code_snippet: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_has_attack_signature: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_has_pentest_framework: {
    TRUE: "error",
    FALSE: "success",
  },
  securin_is_prioritized: {
    TRUE: "error",
    FALSE: "success",
  },
  cisa_kev_exists: {
    TRUE: "error",
    FALSE: "success",
  },
  vulncheck_kev_exists: {
    TRUE: "error",
    FALSE: "success",
  },
  vulncheck_kev_ransomware_campaigns: {
    KNOWN: "error",
    UNKNOWN: "success",
  },
  vulncheck_nvd_status: {
    REJECTED: "error",
    "AWAITING ANALYSIS": "info",
    MODIFIED: "warning",
    RECEIVED: "info",
    ANALYZED: "success",
  },
  nvd_cvssv4_attack_vector: {
    NETWORK: "error",
    ADJACENT: "warning",
    LOCAL: "notice",
    PHYSICAL: "success",
  },
  nvd_cvssv4_attack_complexity: {
    HIGH: "success",
    LOW: "error",
  },
  nvd_cvssv4_attack_requirements: {
    HIGH: "error",
    LOW: "success",
    NONE: "primary",
    PRESENT: "notice",
  },
  nvd_cvssv4_privileges_required: {
    NONE: "error",
    LOW: "warning",
    HIGH: "notice",
  },
  nvd_cvssv4_user_interaction: {
    NONE: "error",
    PASSIVE: "warning",
    ACTIVE: "success",
  },
  nvd_cvssv4_vulnerable_system_confidentiality: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_vulnerable_system_integrity: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_vulnerable_system_availability: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_subsequent_system_confidentiality: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_subsequent_system_integrity: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_subsequent_system_availability: {
    HIGH: "error",
    LOW: "notice",
    NONE: "success",
  },
  nvd_cvssv4_safety: {
    NOT_DEFINED: "success",
    NEGLIGIBLE: "error",
    HIGH: "warning",
    PRESENT: "notice",
  },
  nvd_cvssv4_recovery: {
    NOT_DEFINED: "success",
    AUTOMATABLE: "error",
    USER: "warning",
    IRRECOVERABLE: "notice",
  },
  nvd_cvssv4_value_density: {
    NOT_DEFINED: "success",
    DIFFUSE: "error",
    CONCENTRATED: "warning",
  },
  nvd_cvssv4_vulnerability_response_effort: {
    NOT_DEFINED: "success",
    LOW: "error",
    MODERATE: "warning",
    HIGH: "notice",
  },
  nvd_cvssv4_provider_urgency: {
    NOT_DEFINED: "success",
    CLEAR: "success",
    GREEN: "success",
    AMBER: "warning",
    RED: "error",
  },

};

export const COLORS_CONFIGURATION_CLASSES: Partial<
  Record<
    ThreatKey,
    {
      [key: string]: string;
    }
  >
> = {
  nucleus_risk_rating: {
    DEFAULT: "bg-gray-200 text-gray-800",
    LOW: "bg-green-200 text-green-800",
    MEDIUM: "bg-blue-200 text-blue-800",
    HIGH: "bg-yellow-200 text-yellow-800",
    CRITICAL: "bg-orange-200 text-orange-800",
    EXISTENTIAL: "bg-red-200 text-red-800",
  },
}

export const COLORS_NUMERIC_CONFIGURATION: Partial<
  Record<
    ThreatKey,
    {
      [key: number]: BadgeProps["color"];
    }
  >
> = {
  nvd_cvssv2_base_score: {
    9.49: "error",
    8.79: "warning",
    7.49: "notice",
    0: "success",
  },
  epss_score: {
    0.249: "error",
    0.099: "warning",
    0.049: "notice",
    0: "success",
  },
  epss_percentile: {
    0.949: "error",
    0.879: "warning",
    0.749: "notice",
    0: "success",
  },
  nvd_cvssv3_base_score: {
    9: "error",
    7: "warning",
    5: "notice",
    0: "success",
  },
  greynoise_malicious_count: {
    0: "error", // VIP-229 Color code malicious traffic bubble at the top to always be red
  },
  greynoise_benign_count: {
    0: "success", // VIP-146 Color code benign traffic bubble at the top to always be green
  },
  greynoise_unknown_count: {
    0: "warning", // VIP-179 making it to be always orange to 'reflect' color in graph
  },
  zdi_cvss: {
    9: "error",
    7: "warning",
    5: "notice",
    0: "success",
  },
  // 0-100
  rf_risk_score: {
    90: "error",
    70: "warning",
    50: "notice",
    0: "success",
  },
  rf_num_risk_rules_triggered: {
    10: "error",
    5: "warning",
    1: "notice",
    0: "success",
  },
  shadowserver_vuln_score: {
    90: "error",
    70: "warning",
    50: "notice",
    0: "success",
  },
  securin_definitive_vrs: {
    90: "error",
    70: "warning",
    50: "notice",
    0: "success",
  },
  shadowserver_connections: {
    0: "success",
    1: "error",
  },
  nvd_cvssv4_base_score: {
    9.49: "error",
    8.79: "warning",
    7.49: "notice",
    0: "success",
  },
};

export function numberValueToColor(field_name: ThreatKey, value?: number) {
  if (!value && value !== 0) {
    return "default";
  }

  const mappingObj = COLORS_NUMERIC_CONFIGURATION[field_name];
  if (!mappingObj) {
    return "default";
  }

  let biggestAcceptedKey = 0;
  for (const key of Object.keys(mappingObj)) {
    if (value >= +key && +key > biggestAcceptedKey) {
      biggestAcceptedKey = +key;
    }
  }

  return mappingObj[biggestAcceptedKey];
}

export function stringValueToColor(field_name: ThreatKey, value?: any) {
  if (isNil(value) || !field_name) {
    return "default";
  }

  const mappingObj = COLORS_CONFIGURATION[field_name];
  if (!mappingObj) {
    return "default";
  }

  return mappingObj[value.toString().toUpperCase()] || "default";
}

export function mappedValueToColor(
  value: string,
  mappingObj: {
    [key: string]: BadgeProps["color"];
  },
): BadgeProps["color"] {
  if (!value || !mappingObj) {
    return "default";
  }

  return mappingObj[value] || "default";
}

export function anyValueToColor(field_name: ThreatKey, value?: any) {
  if (typeof value === "number") {
    return numberValueToColor(field_name, value);
  }
  return stringValueToColor(field_name, value);
}

export function hasColorConfiguration(field_name: ThreatKey) {
  return !!COLORS_CONFIGURATION[field_name] || !!COLORS_NUMERIC_CONFIGURATION[field_name];
}
