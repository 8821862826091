// This file is used to store all the test ids used in the project
// used in html as a data-testid
const TEST_IDS = {
  layout: {
    sidebar_menu_button: "sidebar_menu_button", // used in html as a data-testid
    sidebar_menu: "sidebar_menu", // used in html as a data-testid
  },
  login: {
    username_field: "username_field", // used in python as a string
    password_field: "password_field", // used in python as a string
  },
  listing: {
    search_input: "search_input",
    search_input_error: "search_input_error",
    pinned_searches: "pinned_searches",
  },
  organization: {
    delete_user_button: "delete_user_button",
    disable_user_button: "disable_user_button",
    invite_user_button: "invite_user_button",
  },
};

export default TEST_IDS;
