const chipFilled: object = {
  gray: {
    background: "bg-gray-500",
    color: "text-white",
  },
  orange: {
    background: "bg-orange-500",
    color: "text-white",
  },
  yellow: {
    background: "bg-yellow-500",
    color: "text-black",
  },
  green: {
    background: "bg-green-500",
    color: "text-white",
  },
  blue: {
    background: "bg-blue-500",
    color: "text-white",
  },
  red: {
    background: "bg-red-500",
    color: "text-white",
  },
};

export default chipFilled;
