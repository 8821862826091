import type { color } from "@vip-tailwind/types/components/select";

const selectStandardLabelColors: Record<color, Record<string, Record<string, string>>> = {
  gray: {
    close: {
      color: "text-gray-500",
      after: "after:border-blue-500",
    },
    open: {
      color: "text-gray-900",
      after: "after:border-blue-700",
    },
    withValue: {
      color: "text-gray-500",
      after: "after:border-blue-500",
    },
  },
};

export default selectStandardLabelColors;
