import React from "react";

// @floating-ui
import { FloatingFocusManager, FloatingPortal, useMergeRefs } from "@floating-ui/react";

// framer-motion
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";

// utils
import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import objectsToString from "../../utils/objectsToString";

// context
import { useTheme } from "../../context/useTheme";
import { usePopover } from "./PopoverContext";

import type { children, className } from "../../types/components/popover";
import { propTypesChildren, propTypesClassName } from "../../types/components/popover";
// types
import type { NewAnimatePresenceProps } from "../../types/generic";

export interface PopoverContentProps extends React.ComponentProps<"div"> {
  className?: className;
  children: children;
}

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, className, ...rest }, ref) => {
    // 1. init
    const { popover } = useTheme();
    const {
      defaultProps,
      styles: { base },
    } = popover;
    const { open, strategy, x, y, context, floating, getFloatingProps, appliedAnimation, labelId, descriptionId } =
      usePopover();

    // 2. set default props
    className = twMerge(defaultProps.className || "", className);

    // 3. set styles
    const popoverClasses = twMerge(classnames(objectsToString(base)), className);

    // 4. set refs
    const mergedRef = useMergeRefs([ref, floating]);

    // 5. Create an instance of AnimatePresence because of the types issue with the children
    const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;

    // 6. return
    return (
      <LazyMotion features={domAnimation}>
        {open && (
          <FloatingPortal>
            <NewAnimatePresence>
              <FloatingFocusManager context={context}>
                <m.div
                  {...getFloatingProps({
                    ...rest,
                    ref: mergedRef,
                    className: popoverClasses,
                    style: {
                      position: strategy,
                      top: y ?? 0,
                      left: x ?? 0,
                    },
                    "aria-labelledby": labelId,
                    "aria-describedby": descriptionId,
                  })}
                  initial="unmount"
                  exit="unmount"
                  animate={open ? "mount" : "unmount"}
                  variants={appliedAnimation}
                >
                  {children}
                </m.div>
              </FloatingFocusManager>
            </NewAnimatePresence>
          </FloatingPortal>
        )}
      </LazyMotion>
    );
  },
);

PopoverContent.propTypes = {
  className: propTypesClassName,
  children: propTypesChildren,
};

PopoverContent.displayName = "VIP.PopoverContent";

export default PopoverContent;
