import type { color } from "@vip-tailwind/types/components/input";

const textareaOutlinedColors: Record<color, object> = {
  primary: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-blue-500",
  },
  warning: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-orange-500",
  },
  success: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-green-500",
  },
  danger: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-red-500",
  },
};

export default textareaOutlinedColors;
