import { filter } from "lodash-es";
import React, { useCallback } from "react";

import { RecordsType } from "@features/listing/enums";
import useClientSettings, { useMutateClientSettings } from "@hooks/useClientSettings";
import { useOrganizationConfiguration } from "@hooks/useOrganizationsConfiguration";
import type { SerializedMandiantMalwareKey } from "@interfaces/SerializedMandiantMalware";
import { type ThreatKey, isValidThreatKey } from "@interfaces/SerializedThreat";
import { useCurrentUserProfile } from "@queries/useCurrentUserProfile";

import { DEFAULT_MALWARE_COLUMNS } from "../configs/malware_columns";

function useDefaultColumns(recordsType: RecordsType) {
  const { data } = useOrganizationConfiguration();

  const defaultSetup = recordsType === RecordsType.CVEs ? data?.default_columns || [] : DEFAULT_MALWARE_COLUMNS;

  return defaultSetup;
}

/**
 * Generates the list of visible columns based on the records type, user's profile, and organization configuration.
 *
 * @param {RecordsType} recordsType - The type of records for which visible columns need to be generated.
 * @return {React.ReactNode[]} The list of visible columns that are available based on user's profile and organization configuration.
 * @need_suspence
 */
function useVisibleColumns(recordsType: RecordsType) {
  const defaultColumns = useDefaultColumns(recordsType);
  const { data: clientSettingsData } = useClientSettings(); // those which are selected in the Columns menu
  const { visible_columns } = (clientSettingsData || {
    visible_columns: {},
  }) as {
    visible_columns: {
      [RecordsType.CVEs]: ThreatKey[];
      [RecordsType.Malware]: SerializedMandiantMalwareKey[];
    };
  };

  const visibleColumns = visible_columns[recordsType] || defaultColumns;

  // we need to filter out columns which are not available for our organization
  const { data } = useCurrentUserProfile<{ available_fields: string[]; isAdmin: boolean }>({
    select: (profile) => {
      const organization_configuration = profile?.organization_configuration || {
        available_fields: [],
      };

      return {
        available_fields: Array.isArray(organization_configuration.available_fields)
          ? organization_configuration.available_fields
          : [],
        isAdmin: !!profile?.is_staff,
      };
    },
  });

  const { available_fields = [], isAdmin } = data || {};

  // it could be that not all visible previous columns are available via organization configuration, we need to filter them out
  return React.useMemo(() => {
    return filterVisibleColumns(visibleColumns, available_fields, isAdmin, recordsType);
  }, [visibleColumns, available_fields, isAdmin, recordsType]);
}

/**
 * Filters the visible columns based on user permissions and available fields.
 *
 * @param visibleColumns - Array of column IDs that are currently visible.
 * @param availableFields - Array of field IDs that are available for the current organization.
 * @param isAdmin - Boolean indicating if the current user is an admin.
 * @param recordsType - The type of records being displayed (e.g., CVEs or Malware).
 * @returns An array of filtered column IDs that should be visible to the user.
 */
function filterVisibleColumns(
  visibleColumns: (ThreatKey | SerializedMandiantMalwareKey)[],
  availableFields: string[],
  isAdmin: boolean,
  recordsType: RecordsType,
) {
  if (recordsType === RecordsType.Malware) {
    return visibleColumns;
  }

  const wildcardColumns = filter(availableFields, (col) => {
    return col.endsWith("*");
  });

  const filtered = filter(visibleColumns, (column_id) => {
    if (!isValidThreatKey(column_id)) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (!availableFields?.length) {
      return false;
    }

    return (
      availableFields.includes(column_id) ||
      wildcardColumns.some((wildcard) => column_id.startsWith(wildcard.replace("*", "")))
    );
  });

  return filtered;
}

function useChangeVisibleColumns(recordsType: RecordsType) {
  const { mutateAsync } = useMutateClientSettings();
  const { data: clientSettingsData } = useClientSettings(); // those which are selected in the Columns menu
  const visible_columns = clientSettingsData?.visible_columns || {};

  return useCallback(
    async (visibleColumns: (ThreatKey | SerializedMandiantMalwareKey)[]) => {
      await mutateAsync({
        visible_columns: {
          ...visible_columns,
          [recordsType]: visibleColumns,
        },
      });
    },
    [mutateAsync, recordsType, visible_columns],
  );
}

export { useVisibleColumns, useChangeVisibleColumns, useDefaultColumns };
