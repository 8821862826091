import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@vip-tailwind/components/Dialog";
import { Typography } from "@vip-tailwind/components/Typography";
import React, { useState } from "react";

interface AlertDialogProps {
  Trigger?: React.ElementType;
  title?: string;
  description?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  triggerProps?: React.ComponentProps<React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>>;
  customBody?: React.ReactNode;
  icon?: React.ReactNode;
}

const AlertDialog = ({
  Trigger,
  title,
  description,
  isOpen: controlledOpen,
  onOpenChange,
  children,
  triggerProps,
  customBody,
  icon,
}: AlertDialogProps) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const isControlled = controlledOpen !== undefined;
  const isOpen = isControlled ? controlledOpen : internalOpen;

  const handleOpen = React.useCallback(
    (open: boolean) => {
      if (isControlled && onOpenChange) {
        onOpenChange(open);
      } else {
        setInternalOpen(open);
      }
    },
    [isControlled, onOpenChange],
  );

  return (
    <>
      {Trigger && <Trigger {...triggerProps} onClick={() => handleOpen(true)} />}

      <Dialog open={isOpen} handler={() => handleOpen(false)} size="xs">
        <DialogHeader>
          <div className="flex items-center gap-1">
            {icon && <div className="flex items-center">{icon}</div>}
            {title && (
              <Typography variant="h5" color="gray">
                {title}
              </Typography>
            )}
          </div>
        </DialogHeader>

        <DialogBody divider className="space-y-2">
          {description && <Typography className="text-gray-700 dark:text-gray-400">{description}</Typography>}
          {customBody}
        </DialogBody>

        <DialogFooter>{children}</DialogFooter>
      </Dialog>
    </>
  );
};

export { AlertDialog };
