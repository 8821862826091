import type { FieldReferenceState } from "@features/field_references/fieldReferences.state";
import AsyncMenuSuggestion from "../AsyncMenuSuggestion";
import type QLContext from "../QLContext";
import type { Suggestion } from "../Suggestion";
import FieldModel from "./FieldModel";

class RiskLevelFieldModel extends FieldModel {
  constructor(fieldReference: FieldReferenceState, textarea: HTMLTextAreaElement) {
    super("custom_risk_rating", fieldReference, textarea);
  }

  getFieldOptions(context: QLContext): Suggestion[] {
    const value = context?.currentFullToken?.value;
    return [
      new AsyncMenuSuggestion({
        context,
        url: `/api/v1/autocomplete/risk_level${value ? `/${value}/` : "/"}`,
        textarea: this.textarea,
      }),
    ];
  }

  hasOptions() {
    return true;
  }
}

export default RiskLevelFieldModel;
