import buttonFilled from "../button/buttonFilled";
import buttonOutlined from "../button/buttonOutlined";
import buttonText from "../button/buttonText";

// types
import type { className, color, fullWidth, ripple, size, variant } from "../../../types/components/button";
import { propTypesColor, propTypesSize, propTypesVariant } from "../../../types/components/button";

export interface IconButtonStyleTypes {
  defaultProps?: {
    variant?: variant;
    size?: size;
    color?: color;
    fullWidth?: fullWidth;
    ripple?: ripple;
    className?: className;
  };
  valid?: {
    variants?: string[];
    sizes?: string[];
    colors?: string[];
  };
  styles?: {
    base?: object;
    sizes?: {
      sm?: object;
      md?: object;
      lg?: object;
    };
    variants?: {
      filled?: typeof buttonFilled;
      outlined?: typeof buttonOutlined;
      text?: typeof buttonText;
    };
  };
}

export const iconButton: IconButtonStyleTypes = {
  defaultProps: {
    variant: "filled",
    size: "md",
    color: "primary",
    fullWidth: false,
    ripple: true,
    className: "",
  },
  valid: {
    variants: propTypesVariant,
    sizes: propTypesSize,
    colors: propTypesColor,
  },
  styles: {
    base: {
      position: "relative",
      verticalAlign: "align-middle",
      userSelect: "select-none",
      fontFamily: "font-sans",
      fontWeight: "font-medium",
      textAlign: "text-center",
      textTransform: "uppercase",
      transition: "transition-all",
      disabled: "disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none",
    },
    sizes: {
      sm: {
        width: "w-7",
        maxWidth: "max-w-[28px]",
        height: "h-7",
        maxHeight: "max-h-[28px]",
        borderRadius: "rounded",
        fontSize: "text-xs",
      },
      md: {
        width: "w-9",
        maxWidth: "max-w-[36px]",
        height: "h-9",
        maxHeight: "max-h-[36px]",
        borderRadius: "rounded",
        fontSize: "text-md",
      },
      lg: {
        width: "w-11",
        maxWidth: "max-w-[44px]",
        height: "h-11",
        maxHeight: "max-h-[44px]",
        borderRadius: "rounded",
        fontSize: "text-lg",
      },
    },
    variants: {
      filled: buttonFilled,
      outlined: buttonOutlined,
      text: buttonText,
    },
  },
};

export default iconButton;
