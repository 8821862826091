import type { color } from "@vip-tailwind/types/components/input";

const inputOutlinedLabelColors: Record<color, object> = {
  primary: {
    color: "!text-gray-600 peer-focus:!text-blue-700",
    before: "before:border-gray-200 peer-focus:before:!border-blue-700",
    after: "after:border-gray-200 peer-focus:after:!border-blue-700",
  },
  success: {
    color: "text-gray-400 peer-focus:text-green-500",
    before: "before:border-gray-200 peer-focus:before:!border-green-500",
    after: "after:border-gray-200 peer-focus:after:!border-green-500",
  },
  warning: {
    color: "text-gray-400 peer-focus:text-yellow-500",
    before: "before:border-gray-200 peer-focus:before:!border-yellow-500",
    after: "after:border-gray-200 peer-focus:after:!border-yellow-500",
  },
  danger: {
    color: "text-gray-400 peer-focus:text-red-500",
    before: "before:border-gray-200 peer-focus:before:!border-red-500",
    after: "after:border-gray-200 peer-focus:after:!border-red-500",
  },
};

export default inputOutlinedLabelColors;
