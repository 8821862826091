export default function objectsToArray(object: Record<string, any>): string[] {
  const result: string[] = [];

  for (const value of Object.values(object)) {
    if (typeof value === "string") {
      result.push(value);
    } else if (value && typeof value === "object" && !Array.isArray(value)) {
      result.push(...objectsToArray(value));
    }
  }

  return result;
}