import { useFieldReferencesSnapshot } from "@features/field_references/fieldReferences.state";
import { useIsAdmin } from "@hooks/useUserData";
import React, { useCallback, useEffect } from "react";
import { uniqueID } from "./Chrono/utils";
import { DjangoQL } from "./DjangoQL";
import type { NucleusQLState } from "./NucleusQL/NucleusQLState";
import { useClosestNucleusQLState } from "./NucleusQL/useClosestNucleusQLState";
import { SearchQueryTextArea, type SearchQueryTextAreaCustomClassNames } from "./SearchQueryTextArea";

export function NucleusQL({
  onChangeCallback,
  inputName,
  placeholder,
  customClassNames,
  excludedFields,
}: {
  onChangeCallback?: (value?: Partial<NucleusQLState>, isValidate?: boolean, isSubmit?: boolean) => void;
  inputName?: string;
  placeholder?: string;
  customClassNames?: SearchQueryTextAreaCustomClassNames;
  excludedFields?: string[];
}) {
  const isAdmin = useIsAdmin();

  const [completionOpen, setCompletionOpen] = React.useState(false);

  const [state, snap] = useClosestNucleusQLState();
  const fieldReferences = useFieldReferencesSnapshot();
  const inputRef = React.createRef<HTMLTextAreaElement>();
  // Hold reference to djangoql instance to be able to call methods on it, as well as to be able to unsubscribe from valtio state
  const [djangoQLInstance, setDjangoQLInstance] = React.useState<DjangoQL | undefined>();
  const [isInitialized, setIsInitialized] = React.useState(false);

  const stateUpdater = useCallback(
    (newState: Partial<NucleusQLState>, isValidate = true, isSubmit = false) => {
      state.updateState(newState, isValidate);

      // simply informative callback which may be having some side effects affecting other components
      onChangeCallback?.(newState, isValidate, isSubmit);
    },
    [state, onChangeCallback],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <we dont want to reinitialize djangoql if the state changes>
  useEffect(() => {
    function initializeDjangoQL() {
      if (djangoQLInstance) {
        return;
      }

      const uid = uniqueID();

      const _djangoQLInstance = new DjangoQL({
        uid,
        inputRef: inputRef.current!,
        isAdmin: isAdmin,
        stateUpdater,
        currentValue: snap.value,
        cursorPos: snap.cursorPosition,
        excludedFields,
        onCompletionOpen: setCompletionOpen,
      });

      setDjangoQLInstance(_djangoQLInstance);
    }

    if (fieldReferences.isReady && inputRef?.current && !djangoQLInstance && !isInitialized) {
      initializeDjangoQL();
      setIsInitialized(true);
    }

    return () => {
      djangoQLInstance?.destroy();
    };
  }, [fieldReferences.isReady, setCompletionOpen]);

  useEffect(() => {
    if (djangoQLInstance) {
      djangoQLInstance.updateCurrentValues(snap.value, snap.cursorPosition);
    }
  }, [snap.value, snap.cursorPosition, djangoQLInstance]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <afraid to touch this>
  const handleOnChange = useCallback((value: string, cursosPos: number) => {
    state.updateState({ value, cursorPosition: cursosPos, queryParsingError: null }, false);
    onChangeCallback?.({ value, cursorPosition: cursosPos }, false, false);
  }, []);

  return (
    <SearchQueryTextArea
      isValidParsing={snap.isValidParsing}
      value={snap.value}
      cursorPosition={snap.cursorPosition}
      inputRef={inputRef}
      onChange={handleOnChange}
      name={inputName}
      placeholder={placeholder}
      customClassNames={customClassNames}
      completionUid={djangoQLInstance?.uid}
      isCompletionOpen={completionOpen}
    />
  );
}
