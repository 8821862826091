const colors = {
  white: "#ffffff",
  black: "#101828", // dashboard design, headers which are almost black
  gray: {
    1000: "#1D2939", // dashboard design, almost black texts(search query in alerts f.e.)
    900: "#283343", // Midpoint between 1000 and original 800
    800: "#394458", // Slightly lighter than original while maintaining ton
    700: "#667085", // dashboard design, text/base/secondary
    600: "#8A8F98", // dashboard design, Text/color-text-secondary, matches color-system-gray-6
    500: "#98A2B3", // color-system-gray-5
    400: "#c7c7cc", // color-system-gray-4 Text/color-text-tertiary
    300: "#d1d1d6", // color-system-gray-3
    200: "#e9eef2", // color-system-gray-2
    100: "#f8f9fb", // color-system-gray-1 Global/state-global-disabled
    50: "#F9FAFB",
  },
  blue: {
    1000: "#194185",
    900: "#1849A9", // dashboard design, new alerts badge text
    800: "#0059CC", // Primary/state-primary-active
    700: "#0066EB", // Primary/state-primary-hover
    600: "#0f70ff", // system-primary-blue
    500: "#095ABA",
    400: "#75b3fd",
    300: "#CEE5FF", // dashboard design, active menu item background
    200: "#e0eeff", // system-secondary-blue
    100: "#DCECFF",
    50: "#EFF8FF", // dashboard design, new alerts badge background
  },
  red: {
    1000: "#7A271A",
    900: "#802020",
    800: "#994040",
    700: "#B32318", // buttons danger bg
    600: "#CC7A7A",
    500: "#E69A9A",
    400: "#FDA19B",
    300: "#FECDC9",
    200: "#FFE5E9", // system-secondary-red
    100: "#FFEEEE",
    50: "#FFFBFA",
  },
  yellow: {
    1000: "#792E0D",
    900: "#93370D",
    800: "#B54708",
    700: "#DC6803",
    600: "#F79009",
    500: "#FDB022",
    400: "#FEC84B",
    300: "#FEDF89",
    200: "#FEEFC6",
    100: "#FFFAEB",
    50: "#FFFCF5",
  },
  green: {
    1000: "#054F31",
    900: "#05603A",
    800: "#03673d",
    700: "#027A48", // buttons success bg
    600: "#12B76A",
    500: "#32D583",
    400: "#6CE9A6",
    300: "#A6F4C5",
    200: "#d3f8dd", // system-secondary-green
    100: "#e6f7ed",
    50: "#F6FEF9",
  },
  purple: {
    1000: "#3E1C96",
    900: "#4A1FB8",
    800: "#5925DC",
    700: "#6938EF",
    600: "#7A5AF8",
    500: "#9B8AFB",
    400: "#BDB4FE",
    300: "#D9D6FE",
    200: "#EBE9FE",
    100: "#F4F3FF",
    50: "#FAFAFF",
  },
  orange: {
    1000: "#7E2410",
    900: "#c0361a", // system-primary-orange
    800: "#C4320A",
    700: "#EC4A0A",
    600: "#FB6514",
    500: "#d65600", // system-primary-orange-alt
    400: "#CB6F10",
    300: "#e6ac6c",
    200: "#ffe2cc", // system-secondary-orange
    100: "#f7e0c5",
    50: "#FFF5F0",
  },
};

export { colors };