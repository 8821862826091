import type { color } from "@vip-tailwind/types/components/button";

// buttonText.ts
const buttonText: Record<color, Record<string, string>> = {
  primary: {
    color: "text-black",
    hover: "hover:bg-gray-300",
    focus: "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1",
    active: "active:bg-gray-300 active:opacity-[0.85]",
  },
  secondary: {
    color: "text-gray-600",
    hover: "hover:bg-gray-600/10",
    active: "active:bg-gray-600/30",
  },
  success: {
    color: "text-green-700",
    hover: "hover:bg-green-700/10",
    active: "active:bg-green-700/30",
  },
  danger: {
    color: "text-red-700",
    hover: "hover:bg-red-700/10",
    active: "active:bg-red-700/30",
  },
  warning: {
    color: "text-orange-500",
    hover: "hover:bg-orange-500/10",
    active: "active:bg-orange-500/30",
  },
  "secondary-success": {
    color: "text-green-700",
    hover: "hover:bg-green-700/10",
    active: "active:bg-green-700/30",
  },
  "secondary-danger": {
    color: "text-red-700",
    hover: "hover:bg-red-700/10",
    active: "active:bg-red-700/30",
  },
};

export default buttonText;
