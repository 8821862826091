import { capitalizeFirstLetter } from "@utils/utility";
import { Button } from "@vip-tailwind/components/Button";
import { Card } from "@vip-tailwind/components/Card";
import { Chip } from "@vip-tailwind/components/Chip";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@vip-tailwind/components/Dialog";
import { Input } from "@vip-tailwind/components/Input";
import { Textarea } from "@vip-tailwind/components/Textarea";
import { Tooltip } from "@vip-tailwind/components/Tooltip";

import Badge from "@components/Badge";
import { Icon } from "@components/Icon";
import withConfirmation from "@components/withConfirmation";
import Alert from "@vip-tailwind/components/Alert";
import Breadcrumbs from "@vip-tailwind/components/Breadcrumbs";
import { Checkbox } from "@vip-tailwind/components/Checkbox";
import { IconButton } from "@vip-tailwind/components/IconButton";
import { List, ListItem } from "@vip-tailwind/components/List";
import Menu, { MenuHandler, MenuItem, MenuList } from "@vip-tailwind/components/Menu";
import Popover, { PopoverContent, PopoverHandler } from "@vip-tailwind/components/Popover";
import { Radio } from "@vip-tailwind/components/Radio";
import Select from "@vip-tailwind/components/Select";
import Switch from "@vip-tailwind/components/Switch";
import Tabs from "@vip-tailwind/components/Tabs";
import classNames from "classnames";
import React, { useMemo } from "react";
import { colors } from "../../colors";

interface ColorPaletteProps {
  colors:
    | string
    | {
        1000: string;
        900: string;
        800: string;
        700: string;
        600: string;
        500: string;
        400: string;
        300: string;
        200: string;
        100: string;
        50: string;
      };
  title: string;
}

const ColorPalette = ({ colors, title }: ColorPaletteProps) => {
  if (typeof colors === "string") {
    return null;
  }

  return (
    <div className="border border-gray-200 rounded-lg p-1 flex flex-col gap-2 relative">
      <h2 className="text-sm font-medium mb-2 absolute -top-3 left-2">{capitalizeFirstLetter(title)}</h2>
      <div className="flex flex-row flex-wrap gap-2 max-w-96 justify-center">
        {Object.entries(colors).map(([name, value]) => (
          <div key={name} className="flex flex-col items-center">
            <div
              className="w-8 h-8 rounded mb-1 flex items-center justify-center border border-gray-300"
              style={{ backgroundColor: value }}
            >
              <span className="text-xs truncate w-full text-center text-white">{name}</span>
            </div>
            <span
              className={classNames("text-xs truncate w-full text-center", {
                [`text-${title}-${name}`]: name,
              })}
            >
              {value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const PlaygroundPage = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("option1");
  const [activeTab, setActiveTab] = React.useState("html");

  const handleOpenDialog = () => setOpenDialog(!openDialog);

  const _colors = useMemo(() => {
    return Object.entries(colors).map(([name, value]) => ({
      name,
      value,
    }));
  }, []);

  const handleDelete = () => {
    console.log("Note deleted");
  };

  const DeleteButtonWithConfirmation = withConfirmation(
    Button,
    {
      title: "Delete Note",
      description: "Are you sure you want to delete this note?",
      confirmText: "Delete",
      confirmColor: "danger",
    },
    handleDelete,
  );

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-3xl font-bold mb-8">Nucleus Theme Playground</h1>

      <section className="flex flex-row flex-wrap gap-4">
        {_colors.map((color) => (
          <ColorPalette key={color.name} title={color.name} colors={color.value} />
        ))}
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Components</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Card className="p-4 flex flex-col gap-2">
            <h3 className="text-lg font-semibold mb-2">Button</h3>
            <div className="flex flex-wrap gap-2">
              <Tooltip content="Primary: This is the main Button type, used for the primary action of the product.">
                <Button color="primary">Primary</Button>
              </Tooltip>
              <Button color="primary" disabled>
                Disabled
              </Button>
              <Tooltip content="Secondary: This is a secondary Button type, used for actions that are less important than the primary action or are used when more than one action is present.">
                <Button color="secondary">Secondary</Button>
              </Tooltip>
              <Tooltip content="Text: This is a Button type that has no background or border, used for actions that are less important than the primary or secondary action or used when more than one action is present.">
                <Button variant="text">Text</Button>
              </Tooltip>
              <Tooltip content="Success: This is a Button type used for actions that indicate a successful operation.">
                <Button color="success">Success</Button>
              </Tooltip>
              <Tooltip content="Secondary Success: This is a secondary Button type used for actions that indicate a successful operation but don’t require primary emphasis.">
                <Button color="secondary-success">Secondary Success</Button>
              </Tooltip>
              <Tooltip content="Danger: This is a Button type used for actions that indicate a risky or dangerous operation.">
                <Button color="danger">Danger</Button>
              </Tooltip>
              <Tooltip content="Secondary Danger: This is a secondary Button type used for actions that indicate a risky or dangerous operation but don’t require primary emphasis.">
                <Button color="secondary-danger">Secondary Danger</Button>
              </Tooltip>
              <Button color="warning">Warning</Button>
            </div>
            <h4 className="text-md font-semibold mt-4 mb-2">Button Sizes Preview</h4>
            <div className="flex flex-wrap gap-2 justify-around items-center">
              <Button size="sm" color="primary">
                Small
              </Button>
              <Button size="md" color="primary">
                Medium
              </Button>
              <Button size="lg" color="primary">
                Large
              </Button>
            </div>
            <h4 className="text-md font-semibold mt-4 mb-2">Icon Button</h4>
            <div className="flex flex-wrap gap-2">
              <IconButton color="primary" size="lg">
                <Icon icon="edit" />
              </IconButton>
              <IconButton color="danger">
                <Icon icon="remove" />
              </IconButton>
              <IconButton color="secondary">
                <Icon icon="edit" />
              </IconButton>
              <IconButton color="secondary-success" size="sm">
                <Icon icon="checkCircle" />
              </IconButton>
            </div>
          </Card>

          <Card className="p-4 flex flex-col">
            <h3 className="text-lg font-semibold mb-2">Select</h3>
            <Select label="Outlined Select" variant="outlined">
              <Select.Option value="1">Option 1</Select.Option>
              <Select.Option value="2">Option 2</Select.Option>
              <Select.Option value="3">Option 3</Select.Option>
            </Select>
            <div className="mt-6">
              <Select label="Standard Select" variant="standard">
                <Select.Option value="1">Option 1</Select.Option>
                <Select.Option value="2">Option 2</Select.Option>
                <Select.Option value="3">Option 3</Select.Option>
              </Select>
            </div>
            <div className="mt-6">
              <Select label="Static Select" variant="static">
                <Select.Option value="1">Option 1</Select.Option>
                <Select.Option value="2">Option 2</Select.Option>
                <Select.Option value="3">Option 3</Select.Option>
              </Select>
            </div>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Menu</h3>
            <Menu>
              <MenuHandler>
                <Button>Open Menu</Button>
              </MenuHandler>
              <MenuList>
                <MenuItem>Item 1</MenuItem>
                <MenuItem>Item 2</MenuItem>
                <MenuItem>Item 3</MenuItem>
              </MenuList>
            </Menu>
          </Card>

          <Card className="p-4 flex flex-col gap-2">
            <h3 className="text-lg font-semibold mb-2">Input</h3>
            <Input label="Outlined Input" variant="outlined" />
            <Input label="Disabled Input" disabled />
            <Input label="Error Input" error />
            <Input label="Success Input" success />
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Textarea</h3>
            <Textarea label="Enter your message" />
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Checkbox</h3>
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} label="Check me" />
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Radio</h3>
            <div className="flex flex-col gap-2">
              <Radio
                name="radio-group"
                value="option1"
                checked={selectedValue === "option1"}
                onChange={(e) => setSelectedValue(e.target.value)}
                label="Option 1"
              />
              <Radio
                name="radio-group"
                value="option2"
                checked={selectedValue === "option2"}
                onChange={(e) => setSelectedValue(e.target.value)}
                label="Option 2"
              />
            </div>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Tooltip</h3>
            <Tooltip content="This is a tooltip">
              <Button>Hover me</Button>
            </Tooltip>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Dialog</h3>
            <Button onClick={handleOpenDialog}>Open Dialog</Button>
            <Dialog open={openDialog} handler={handleOpenDialog}>
              <DialogHeader>Dialog Title</DialogHeader>
              <DialogBody>This is a dialog. You can add any content here.</DialogBody>
              <DialogFooter>
                <Button variant="text" onClick={handleOpenDialog} className="mr-2">
                  Cancel
                </Button>
                <Button color="primary" onClick={handleOpenDialog}>
                  Confirm
                </Button>
              </DialogFooter>
            </Dialog>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Confirmation Dialog</h3>
            <DeleteButtonWithConfirmation color="warning">Delete Note</DeleteButtonWithConfirmation>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Tags</h3>
            <div className="flex flex-wrap gap-2">
              <Chip color="blue" value="Blue Chip" onClose={() => {}} />
              <Chip color="red" value="Red Chip" onClose={() => {}} />
              <Chip color="green" value="Green Chip" onClose={() => {}} />
              <Chip color="yellow" value="Yellow Chip" className="text-black" onClose={() => {}} />
            </div>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Badge</h3>
            <div className="flex flex-wrap gap-2">
              <Badge color="error">Error</Badge>
              <Badge color="warning">Warning</Badge>
              <Badge color="notice">Notice</Badge>
              <Badge color="info">Info</Badge>
              <Badge color="success">Success</Badge>
              <Badge color="primary">Primary</Badge>
              <Badge>Default</Badge>
            </div>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Tabs</h3>
            <Tabs value={activeTab} onChange={(value: string) => setActiveTab(value)}>
              <Tabs.Header>
                <Tabs.Tab value="html">HTML</Tabs.Tab>
                <Tabs.Tab value="react">React</Tabs.Tab>
                <Tabs.Tab value="vue">Vue</Tabs.Tab>
              </Tabs.Header>
              <Tabs.Body>
                <Tabs.Panel value="html">HTML Content</Tabs.Panel>
                <Tabs.Panel value="react">React Content</Tabs.Panel>
                <Tabs.Panel value="vue">Vue Content</Tabs.Panel>
              </Tabs.Body>
            </Tabs>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Alert</h3>
            <Alert color="blue">This is an alert message.</Alert>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Breadcrumbs</h3>
            <Breadcrumbs>
              <a href="/">Home</a>
              <a href="/">Components</a>
              <a href="/">Breadcrumbs</a>
            </Breadcrumbs>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">List</h3>
            <List>
              <ListItem>Item 1</ListItem>
              <ListItem>Item 2</ListItem>
              <ListItem>Item 3</ListItem>
            </List>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Popover</h3>
            <Popover>
              <PopoverHandler>
                <Button>Open Popover</Button>
              </PopoverHandler>
              <PopoverContent>This is a popover content.</PopoverContent>
            </Popover>
          </Card>

          <Card className="p-4">
            <h3 className="text-lg font-semibold mb-2">Switch</h3>
            <Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} label="Toggle me" />
          </Card>
        </div>
      </section>
    </div>
  );
};

export { PlaygroundPage };