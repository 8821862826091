import React from "react";

import { useVipRiskColor } from "@features/riskLevels";
import type { FrontendSerializedThreat } from "@interfaces/SerializedThreat";
import type { CellContext } from "@tanstack/react-table";
import { Tooltip } from "@vip-tailwind/components/Tooltip";

const Renderer = ({ value }: { value: string }) => {
  const {
    isLoading,
    isError,
    colorClass: [bgColor, textColor],
    matchingRiskLevel,
  } = useVipRiskColor(value);

  if (isLoading) {
    return <span className="animate-pulse">...</span>;
  }

  if (isError) {
    return <span className="font-bold text-red-600">-</span>;
  }

  const { description: riskLevelDescription } = matchingRiskLevel ?? {};
  // fallback to value should stay, since it might be old risk level which is not in the list anymore and we dont know the priority
  const riskLevelValue = matchingRiskLevel
    ? `P${matchingRiskLevel.priority} - ${matchingRiskLevel.risk_level}`
    : value ?? "Unassigned";

  return (
    <span
      className={`inline-flex items-center justify-center rounded-full font-semibold min-w-[80px] text-xs px-3 py-1 ${textColor}${!value ? " !text-gray-500 !justify-start" : ""}`}
      style={{
        backgroundColor: bgColor ?? "transparent",
      }}
    >
      {riskLevelValue}
      {riskLevelDescription ? (
        <Tooltip content={<div className="w-80">{riskLevelDescription}</div>}>
          <i className="ml-1 text-xs cursor-pointer fas fa-info-circle" />
        </Tooltip>
      ) : null}
    </span>
  );
};

const MemoizedRenderer = React.memo(Renderer);

const VipRiskLevelRenderer: React.FunctionComponent<CellContext<FrontendSerializedThreat, string>> = (context) => {
  const value = context.getValue();

  return <MemoizedRenderer value={value} />;
};

export default VipRiskLevelRenderer;
