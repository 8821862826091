const typographyColors: object = {
  inherit: {
    color: "text-inherit",
  },
  current: {
    color: "text-current",
  },
  black: {
    color: "text-black",
  },
  white: {
    color: "text-white",
  },
  gray: {
    color: "text-gray-700",
  },
  orange: {
    color: "text-orange-500",
  },
  yellow: {
    color: "text-yellow-500",
  },
  green: {
    color: "text-green-500",
  },
  blue: {
    color: "text-blue-500",
  },
  purple: {
    color: "text-purple-500",
  },
  red: {
    color: "text-red-500",
  },
};

export default typographyColors;
