import type { Button } from "@vip-tailwind/components/Button";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@vip-tailwind/components/Dialog";
import type React from "react";

type TriggerProps = React.ComponentPropsWithoutRef<typeof Button>;

interface CustomDialogProps {
  Trigger: React.ElementType;
  triggerProps?: TriggerProps;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const MaterialDialog: React.FC<CustomDialogProps> = ({
  Trigger,
  triggerProps = {},
  title,
  description,
  isOpen,
  onOpenChange,
  children,
  footer,
}) => {
  const handleOpen = () => onOpenChange(!isOpen);

  return (
    <>
      <Trigger {...triggerProps} onClick={handleOpen} />
      <Dialog open={isOpen} handler={handleOpen}>
        {title && <DialogHeader>{title}</DialogHeader>}
        <DialogBody>
          {description && <p>{description}</p>}
          {children}
        </DialogBody>
        {footer && <DialogFooter>{footer}</DialogFooter>}
      </Dialog>
    </>
  );
};

export { MaterialDialog };
