import type React from "react";
import { useCallback, useState } from "react";
import { type DefaultValues, type FieldValues, type UseFormReturn, useForm } from "react-hook-form";

import Button from "@vip-tailwind/components/Button";
import type { color } from "@vip-tailwind/types/components/button";
import { AlertDialog } from "./AlertDialog";

type FormConfig<T extends FieldValues> = {
  defaultValues: DefaultValues<T>;
  render: (form: UseFormReturn<T>) => React.ReactNode;
};

export type WithConfirmationCallbackProps<F extends object = Record<string, any>> = {
  formData?: F;
} & Record<string, any>;

type WithConfirmationProps<T extends object, F extends object = Record<string, any>> = React.ComponentProps<
  React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>
> &
  T & { callbackProps?: WithConfirmationCallbackProps<F> };

type DialogConfig = {
  title: string;
  description: string;
  confirmText?: string;
  confirmColor?: color;
};

type TriggerComponentProps<T, E extends HTMLElement = HTMLElement> = T & {
  onClick?: (e: React.MouseEvent<E>) => void;
};

function withConfirmation<
  T extends object,
  E extends HTMLElement = HTMLElement,
  F extends object = Record<string, any>,
>(
  TriggerComponent: React.ForwardRefExoticComponent<TriggerComponentProps<T, E> & React.RefAttributes<E>>,
  dialogConfig: DialogConfig,
  callback: (callbackProps?: WithConfirmationCallbackProps<F>) => any,
  formConfig?: FormConfig<F>,
) {
  return (props: WithConfirmationProps<T, F>) => {
    const [isOpen, setIsOpen] = useState(false);
    const form = useForm<F>(formConfig ? { defaultValues: formConfig.defaultValues } : undefined);

    const { callbackProps, ...rest } = props;

    const handleConfirm = useCallback(
      (formData?: F) => {
        callback({ ...callbackProps, formData });
        setIsOpen(false);
      },
      [callback, callbackProps],
    );

    const handleClose = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsOpen(false);
    }, []);

    const handleConfirmClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        form.handleSubmit(handleConfirm)();
      },
      [form, handleConfirm],
    );

    return (
      <AlertDialog
        Trigger={TriggerComponent}
        triggerProps={rest}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        title={dialogConfig.title}
        description={dialogConfig.description}
        customBody={formConfig && <form onSubmit={form.handleSubmit(handleConfirm)}>{formConfig.render(form)}</form>}
      >
        <div className="flex justify-end gap-4 mt-2">
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button color={dialogConfig.confirmColor || "danger"} variant="filled" onClick={handleConfirmClick}>
            {dialogConfig.confirmText || "Confirm"}
          </Button>
        </div>
      </AlertDialog>
    );
  };
}

export default withConfirmation;
