import type { color } from "@vip-tailwind/types/components/button";

const buttonOutlined: Record<color, Record<string, string>> = {
  primary: {
    border: "border border-blue-600",
    color: "text-blue-600",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-blue-600/50",
    active: "active:opacity-[0.85]",
  },
  secondary: {
    border: "border border-gray-600",
    color: "text-gray-600",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-gray-600/50",
    active: "active:opacity-[0.85]",
  },
  success: {
    border: "border border-green-700",
    color: "text-green-700",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-green-700/50",
    active: "active:opacity-[0.85]",
  },
  danger: {
    border: "border border-red-700",
    color: "text-red-700",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-red-700/50",
    active: "active:opacity-[0.85]",
  },
  warning: {
    border: "border border-orange-500",
    color: "text-orange-500",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-orange-500/50",
    active: "active:opacity-[0.85]",
  },
  "secondary-success": {
    border: "border border-green-700",
    color: "text-green-700",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-green-700/50",
    active: "active:opacity-[0.85]",
  },
  "secondary-danger": {
    border: "border border-red-700",
    color: "text-red-700",
    hover: "hover:opacity-75",
    focus: "focus:ring focus:ring-red-700/50",
    active: "active:opacity-[0.85]",
  },
};

export default buttonOutlined;