import { useMutation, useQueryClient } from "@tanstack/react-query";

import fetchWithSession from "@utils/fetchWithSession";
import type { SerializedRiskLevel, SerializedRiskLevelPost } from "../interfaces";
import { organizationRiskLevelsUrlEndpoint } from "./useRiskLevelsQuery";

export function useMutateRiskLevels() {
  const queryClient = useQueryClient();
  return useMutation<
    SerializedRiskLevel[],
    Error,
    { riskLevels: SerializedRiskLevelPost[]; keep_manual_risk_levels: boolean }
  >({
    mutationFn: async ({ riskLevels, keep_manual_risk_levels }) => {
      return fetchWithSession(organizationRiskLevelsUrlEndpoint, {
        method: "POST",
        body: JSON.stringify({
          risk_levels: riskLevels,
          keep_manual_risk_levels,
        }),
      }).then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }

        const responseRiskLevels = response as SerializedRiskLevel[];
        if (!Array.isArray(responseRiskLevels)) {
          throw new Error("No risk ratings returned from server");
        }

        queryClient.setQueryData(["riskLevels"], responseRiskLevels);
        return response;
      });
    },
  });
}
