import ErrorUnauthorized from "@errors/ErrorUnauthorized";
import { updateGlobalAppState } from "@features/global";
import { getCsrfToken } from "./getCsrfToken";

interface FetchWithSessionOptions extends RequestInit {
  allowErrorResponse?: boolean; // allow getting error response data
  noParse?: boolean; // don't parse response as JSON
  retry403?: boolean; // retry 403 errors with a new CSRF token
}

const frontendVersion = __APP_VERSION__;

async function fetchWithSession<T = any>(input: RequestInfo, init?: FetchWithSessionOptions) {
  const options = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    } as Record<string, string>,
  };

  if (init?.method && ["PUT", "PATCH", "POST", "DELETE"].includes(init.method)) {
    // Get CSRF token for mutating requests
    options.headers["X-CSRFToken"] = await getCsrfToken();
  }

  let response = await fetch(input, { ...init, ...options });

  if (response.status === 403 && init?.retry403) {
    // Handle CSRF token refresh if needed
    const newCsrfToken = await getCsrfToken(true);
    options.headers["X-CSRFToken"] = newCsrfToken;
    response = await fetch(input, { ...init, ...options });
  }

  if (response.status === 403) {
    // Handle 403 errors
    const data = await response.json().catch(() => ({}));
    if (data?.detail) {
      throw new ErrorUnauthorized(data.detail);
    }
    throw new ErrorUnauthorized("Access Forbidden");
  }

  // no parse is default option for DELETE requests if not explicitly set
  const noParse = init?.noParse ?? init?.method === "DELETE";
  if (noParse) {
    return response as unknown as T;
  }

  // Version check for GET requests
  const backendVersion = response.headers.get("X-App-Version");
  if (backendVersion && frontendVersion && backendVersion !== frontendVersion) {
    console.warn(`Version mismatch: Frontend (${frontendVersion}) != Backend (${backendVersion})`);
    updateGlobalAppState({ isVersionMismatch: true });
  }

  // Allow getting error response data if option is set
  if (!response.ok && init?.allowErrorResponse) {
    return response.json() as Promise<T>;
  }

  if (!response.ok) {
    const data = await response.json().catch((e) => {
      return {};
    });
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${
        data?.message || data?.detail || data?.error || response.statusText
      }`,
    );
  }

  return response.json() as Promise<T>;
}

export default fetchWithSession;
