import Dialog from "@vip-tailwind/components/Dialog";

export interface CVEParseResult {
  cves: string[];
  pureMatch: boolean;
  originalText: string;
}

interface CVEDialogProps {
  parseResult: CVEParseResult;
  onClose: (useCVEs: boolean) => void;
  open: boolean;
}

export const CVEDialog: React.FC<CVEDialogProps> = ({ parseResult, onClose, open }) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} handler={() => onClose(false)}>
      <div className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-2xl w-full shadow-xl">
          <div className="p-6">
            <h3 className="text-lg font-bold mb-2 text-gray-900">CVE IDs Found in Pasted Content</h3>

            <p className="text-sm text-gray-600 mb-4">
              The pasted content contains CVE IDs mixed with other text. You can choose to extract just the CVE IDs or
              use the original text.
            </p>

            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-sm text-gray-700 mb-1">
                  Extracted CVE IDs ({parseResult.cves.length})
                </h4>
                <div className="bg-blue-50 p-3 rounded border border-blue-100 text-sm font-mono">
                  {parseResult.cves.length > 10
                    ? `${parseResult.cves.slice(0, 10).join(", ")} and ${parseResult.cves.length - 10} more`
                    : parseResult.cves.join(", ")}
                </div>
              </div>

              <div>
                <h4 className="font-medium text-sm text-gray-700 mb-1">Original Text</h4>
                <div className="bg-gray-50 p-3 rounded border border-gray-200 text-sm font-mono whitespace-pre-wrap max-h-40 overflow-y-auto">
                  {parseResult.originalText}
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                onClick={() => onClose(false)}
              >
                Use Pasted Content
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                onClick={() => onClose(true)}
              >
                Use CVE IDs Only
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};