import type React from "react";

import ColoredNumericLabel from "@components/ListingTable/TableInstance/fieldRenderers/ColoredNumericLabel";
import JSONCellRenderer from "@components/ListingTable/TableInstance/fieldRenderers/JSONCellRenderer";
import LongTextBox from "@components/ListingTable/TableInstance/fieldRenderers/LongTextBox";
import MandiantBooleanRenderer from "@components/ListingTable/TableInstance/fieldRenderers/MandiantBooleanRenderer";
import SingleUrlRenderer from "@components/ListingTable/TableInstance/fieldRenderers/SingleUrlRenderer";
import ExploitdbExploits from "@components/ListingTable/TableInstance/fieldRenderers/exploitdb_exploits";
import GreynoiseTags from "@components/ListingTable/TableInstance/fieldRenderers/greynoise_tags";
import Intel471ChatterTrend from "@components/ListingTable/TableInstance/fieldRenderers/intel471_chatter_trend";
import Intel471Girs from "@components/ListingTable/TableInstance/fieldRenderers/intel471_girs";
import IntelLinks from "@components/ListingTable/TableInstance/fieldRenderers/intel_links";
import MalwareVipTags from "@components/ListingTable/TableInstance/fieldRenderers/malware_vip_tags";
import MandiantActors from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_actors";
import MandiantAliases from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_aliases";
import MandiantCpeRanges from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_cpe_ranges";
import MandiantExploits from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_exploits";
// Import all the renderers directly
import MandiantMalware from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_malware";
import MandiantSources from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_sources";
import MandiantTechnologies from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_technologies";
import MandiantVendorFixUrls from "@components/ListingTable/TableInstance/fieldRenderers/mandiant_vendor_fix_urls";
import NvdCwes from "@components/ListingTable/TableInstance/fieldRenderers/nvd_cwes";
import NvdReferences from "@components/ListingTable/TableInstance/fieldRenderers/nvd_references";
import NvdVendor from "@components/ListingTable/TableInstance/fieldRenderers/nvd_vendor";
import RfRiskRulesTriggered from "@components/ListingTable/TableInstance/fieldRenderers/rf_risk_rules_triggered";
import SecurinAffectedIndustries from "@components/ListingTable/TableInstance/fieldRenderers/securin_affected_industries";
import SecurinCpes from "@components/ListingTable/TableInstance/fieldRenderers/securin_cpes";
import SecurinFixes from "@components/ListingTable/TableInstance/fieldRenderers/securin_fixes";
import SecurinPlugins from "@components/ListingTable/TableInstance/fieldRenderers/securin_plugins";
import SecurinPosts from "@components/ListingTable/TableInstance/fieldRenderers/securin_posts";
import SecurinReferences from "@components/ListingTable/TableInstance/fieldRenderers/securin_references";
import SecurinSources from "@components/ListingTable/TableInstance/fieldRenderers/securin_sources";
import SecurinVendorComments from "@components/ListingTable/TableInstance/fieldRenderers/securin_vendor_comments";
import SecurinWeaknesses from "@components/ListingTable/TableInstance/fieldRenderers/securin_weaknesses";
import ShadowserverGeo from "@components/ListingTable/TableInstance/fieldRenderers/shadowserver_geo";
import VipIntels from "@components/ListingTable/TableInstance/fieldRenderers/vip_intels";
import VipMonitored from "@components/ListingTable/TableInstance/fieldRenderers/vip_monitored";
import VipRiskLevelRenderer from "@components/ListingTable/TableInstance/fieldRenderers/vip_risk_level";
import VipStatusRenderer from "@components/ListingTable/TableInstance/fieldRenderers/vip_status";
import { vulncheck_kev_reported_exploitation } from "@components/ListingTable/TableInstance/fieldRenderers/vulncheck_kev_reported_exploitation";
import { vulncheck_kev_xdb_entries } from "@components/ListingTable/TableInstance/fieldRenderers/vulncheck_kev_xdb_entries";
import {
  vulncheck_nvd_cvssv2,
  vulncheck_nvd_cvssv3,
  vulncheck_nvd_cvssv4,
} from "@components/ListingTable/TableInstance/fieldRenderers/vulncheck_nvd_cvss";
import { vulncheck_nvd_references } from "@components/ListingTable/TableInstance/fieldRenderers/vulncheck_nvd_references";
import ZdiCan from "@components/ListingTable/TableInstance/fieldRenderers/zdi_can";
import ZdiCans from "@components/ListingTable/TableInstance/fieldRenderers/zdi_cans";
import ZdiId from "@components/ListingTable/TableInstance/fieldRenderers/zdi_id";
import type { ThreatKey } from "@interfaces/SerializedThreat";

const cvesRenderersConfig: Partial<Record<ThreatKey, React.ComponentType<any>>> = {
  mandiant_malware: MandiantMalware,
  mandiant_exploits: MandiantExploits,
  mandiant_actors: MandiantActors,
  mandiant_sources: MandiantSources,
  greynoise_tags: GreynoiseTags,
  zdi_can: ZdiCan,
  zdi_id: ZdiId,
  mandiant_mitigations: JSONCellRenderer,
  mandiant_exploitation_vectors: JSONCellRenderer,
  mandiant_vendor_fix_urls: MandiantVendorFixUrls,
  cisa_action: LongTextBox,
  cisa_short_description: LongTextBox,
  mandiant_analysis: LongTextBox,
  mandiant_mitigation_details: LongTextBox,
  mandiant_summary: LongTextBox,
  mandiant_vulnerable_products: LongTextBox,
  defender_description: LongTextBox,
  nvd_description: LongTextBox,
  mandiant_exec_summary: LongTextBox,
  intel471_summary: LongTextBox,
  epss_score: ColoredNumericLabel,
  nvd_cvssv3_base_score: ColoredNumericLabel,
  epss_percentile: ColoredNumericLabel,
  nvd_cvssv2_base_score: ColoredNumericLabel,
  mandiant_exploited_by_malware: MandiantBooleanRenderer,
  mandiant_zero_day: MandiantBooleanRenderer,
  mandiant_exploit_in_the_wild: MandiantBooleanRenderer,
  mandiant_affects_ot: MandiantBooleanRenderer,
  intel471_poc_links: IntelLinks,
  intel471_counter_measure_links: IntelLinks,
  intel471_titan_links: IntelLinks,
  intel471_patch_links: IntelLinks,
  mandiant_technologies: MandiantTechnologies,
  intel471_girs: Intel471Girs,
  rf_risk_rules_triggered: RfRiskRulesTriggered,
  mandiant_cpe_ranges: MandiantCpeRanges,
  feeds: VipIntels,
  mandiant_aliases: MandiantAliases,
  nvd_references: NvdReferences,
  nvd_cwes: NvdCwes,
  intel471_chatter_trend: Intel471ChatterTrend,
  project_zero_analysis_url: SingleUrlRenderer,
  project_zero_advisory: SingleUrlRenderer,
  exploitdb_exploits: ExploitdbExploits,
  shadowserver_geo: ShadowserverGeo,
  zdi_cans: ZdiCans,
  nvd_vendor: NvdVendor,
  tags: MalwareVipTags,
  monitored: VipMonitored,

  // securin fields
  securin_cpes: SecurinCpes,
  securin_fixes: SecurinFixes,
  securin_affected_industries: SecurinAffectedIndustries,
  securin_plugins: SecurinPlugins,
  securin_description: LongTextBox,
  securin_posts: SecurinPosts,
  securin_references: SecurinReferences,
  securin_sources: SecurinSources,
  securin_weaknesses: SecurinWeaknesses,
  securin_prioritized_by: JSONCellRenderer,
  securin_threat_actors: JSONCellRenderer,
  securin_threats: JSONCellRenderer,
  securin_vendor_comments: SecurinVendorComments,
  custom_risk_rating: VipRiskLevelRenderer,

  // vulncheck fields
  vulncheck_kev_xdb_entries: vulncheck_kev_xdb_entries,
  vulncheck_nvd_cvssv2: vulncheck_nvd_cvssv2,
  vulncheck_nvd_cvssv3: vulncheck_nvd_cvssv3,
  vulncheck_nvd_cvssv4: vulncheck_nvd_cvssv4,
  vulncheck_kev_reported_exploitation,
  vulncheck_nvd_references,

  status: VipStatusRenderer,
};

export { cvesRenderersConfig };
