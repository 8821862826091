import PropTypes from "prop-types";
import type { ReactNode } from "react";

// generic types
import type { animation, colors } from "../generic";
import { propTypesAnimation, propTypesColors } from "../generic";

/**
 * This file contains the types and prop-types for Chip component.
 */

// typescript types
export type variant = "filled" | "outlined" | "ghost";
export type size = "sm" | "md" | "lg";
export type color = colors;
export type icon = ReactNode;
export type open = boolean;
export type onClose = () => void;
export type action = ReactNode;
export type dismissible = {
  action?: ReactNode;
  onClose: () => void;
};
export type animate = animation;
export type className = string;
export type value = ReactNode;

// javascript prop-types
export const propTypesVariant: variant[] = ["filled", "outlined", "ghost"];
export const propTypesSize: size[] = ["sm", "md", "lg"];
export const propTypesColor: color[] = propTypesColors;
export const propTypesIcon = PropTypes.node;
export const propTypesOpen = PropTypes.bool;
export const propTypesOnClose = PropTypes.func;
export const propTypesAction = PropTypes.node;
export const propTypesAnimate = propTypesAnimation;
export const propTypesClassName = PropTypes.string;
export const propTypesValue = PropTypes.node.isRequired;
