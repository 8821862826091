import PropTypes from "prop-types";
import React from "react";

import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import findMatch from "../../utils/findMatch";
import objectsToString from "../../utils/objectsToString";

// context
import { useTheme } from "../../context/useTheme";

// types
import type { children, className, color, ripple, size, variant } from "../../types/components/button";
import {
  propTypesChildren,
  propTypesClassName,
  propTypesColor,
  propTypesRipple,
  propTypesSize,
  propTypesVariant,
} from "../../types/components/button";

// Import Tooltip component
import { Tooltip } from "@vip-tailwind/components/Tooltip";

export interface IconButtonProps extends React.ComponentProps<"button"> {
  variant?: variant;
  size?: size;
  color?: color;
  className?: className;
  children: children;
  fullWidth?: boolean;
  tooltip?: string;
}

export const IconButton = React.forwardRef<HTMLElement, IconButtonProps>(
  ({ variant, size, color, className, children, fullWidth, tooltip, ...rest }, ref) => {
    // 1. init
    const { iconButton } = useTheme();
    const { valid, defaultProps, styles } = iconButton;
    const base = styles?.base;
    const variants = styles?.variants;
    const sizes = styles?.sizes;

    // 2. set default props
    variant = variant ?? defaultProps?.variant;
    size = size ?? defaultProps?.size;
    color = color ?? defaultProps?.color;
    className = twMerge(defaultProps?.className || "", className);

    // 3. set styles
    const buttonBase = objectsToString(base);
    const buttonVariant = objectsToString(
      variants[findMatch(valid.variants, variant, "filled")][findMatch(valid.colors, color, "secondary")],
    );
    const buttonSize = objectsToString(sizes[findMatch(valid.sizes, size, "md")]);
    const classes = twMerge(classnames(buttonBase, buttonSize, buttonVariant), className);

    // 6. return with or without Tooltip
    return tooltip ? (
      <Tooltip content={tooltip} ref={ref as React.Ref<HTMLDivElement>}>
        <button {...rest} className={classes} type={rest.type || "button"}>
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">{children}</span>
        </button>
      </Tooltip>
    ) : (
      <button {...rest} ref={ref as React.Ref<HTMLButtonElement>} className={classes} type={rest.type || "button"}>
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">{children}</span>
      </button>
    );
  },
);

IconButton.propTypes = {
  variant: PropTypes.oneOf(propTypesVariant),
  size: PropTypes.oneOf(propTypesSize),
  color: PropTypes.oneOf(propTypesColor),
  ripple: propTypesRipple,
  className: propTypesClassName,
  children: propTypesChildren,
  tooltip: PropTypes.string,
};

IconButton.displayName = "VIP.IconButton";

export default IconButton;
