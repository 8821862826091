import { FloatingPortal, autoUpdate, offset, useFloating } from "@floating-ui/react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import type React from "react";
import { useEffect } from "react";

type DjangoQLCompletionProps = {
  isOpen: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  completionUid: string;
  children?: React.ReactNode; // Add children prop for content
};

const completionAnimation = {
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.2, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0,
    y: -10,
    scale: 0.95,
    transition: { duration: 0.1, ease: "easeInOut" },
  },
};

export function DjangoQLCompletion({ isOpen, textareaRef, completionUid, children }: DjangoQLCompletionProps) {
  const { refs, floatingStyles, update } = useFloating({
    elements: {
      reference: textareaRef.current,
    },
    placement: "bottom-start",
    middleware: [offset(0)],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (isOpen) {
      update();
    }
  }, [isOpen, update]);

  return (
    <FloatingPortal id={`nucleusql-completion-${completionUid}`}>
      <div
        ref={refs.setFloating}
        className="nucleusql-completion"
        style={{
          ...floatingStyles,
          zIndex: 9999,
        }}
      >
        <LazyMotion features={domAnimation}>
          <m.div
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            variants={completionAnimation}
            aria-hidden={!isOpen}
            id={`nucleusql-completion-content-${completionUid}`}
          >
            {children}
          </m.div>
        </LazyMotion>
      </div>
    </FloatingPortal>
  );
}
