const chipGhost: object = {
  gray: {
    background: "bg-gray-500/20",
    color: "text-gray-900",
  },
  orange: {
    background: "bg-orange-500/20",
    color: "text-orange-900",
  },
  yellow: {
    background: "bg-yellow-500/20",
    color: "text-yellow-900",
  },
  green: {
    background: "bg-green-500/20",
    color: "text-green-900",
  },
  blue: {
    background: "bg-blue-500/20",
    color: "text-gray-700",
  },
  red: {
    background: "bg-red-500/20",
    color: "text-red-900",
  },
};

export default chipGhost;
