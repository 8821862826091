import PropTypes from "prop-types";
import type React from "react";
import { createContext } from "react";
import theme from "../theme/index";

const VipTailwindTheme = createContext(theme);

VipTailwindTheme.displayName = "VipTailwindThemeProvider";

function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <VipTailwindTheme.Provider value={theme}>{children}</VipTailwindTheme.Provider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { VipTailwindTheme, ThemeProvider };
