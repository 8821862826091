import { LEXER_TOKENS } from "@utils/Lexer";
import { MenuSuggestion } from "./MenuSuggestion";
import type QLContext from "./QLContext";
import { Suggestion } from "./Suggestion";

const createMenuSuggestionWithCategories = (
  allFieldsKeys: string[],
  context: QLContext,
  textarea: HTMLTextAreaElement,
) => {
  // --- Step 1: Pre-filter and Prepare Fields ---
  // Exclude internal fields that should not be suggested (vip_id will be added later)
  const suggestibleFields = allFieldsKeys.filter(
    (field) => !["cve_threat_intel_id", "vip_id", "cve_id"].includes(field),
  );

  // --- Step 2: Group Fields by Prefix ---
  // Create a map to group fields by their prefix.  The 'vip' and 'nucleus'
  // prefixes are treated as equivalent and grouped under 'vip'
  const fieldsByPrefix = new Map<string, string[]>();
  for (const field of suggestibleFields) {
    const [prefix] = field.split("_"); // Extract the first part as the prefix.
    const effectivePrefix = prefix === "nucleus" || prefix === "vip" ? "vip" : prefix;

    if (effectivePrefix) {
      if (!fieldsByPrefix.has(effectivePrefix)) {
        fieldsByPrefix.set(effectivePrefix, []);
      }
      fieldsByPrefix.get(effectivePrefix)!.push(field);
    }
  }

  // --- Step 3: Categorize Fields (VIP vs. Others) ---
  const categorizedFields = new Map<string, string[]>();
  let vipFields: string[] = [];

  // Iterate through the grouped fields and separate them into:
  // - 'vipFields':  Fields with the "vip" prefix, and fields that have a unique prefix.
  // - 'categorizedFields': Fields with prefixes that appear multiple times.
  for (const [prefix, fields] of fieldsByPrefix) {
    if (prefix === "vip") {
      vipFields.push(...fields); // Add all "vip" prefixed fields.
    } else if (prefix === "shodan") {
      // --- Special Handling for Shodan ---
      categorizedFields.set("Shodan", fields); // Force "Shodan" category
    } else if (fields.length === 1) {
      vipFields.push(...fields); // Single-field groups are also treated as "vip".
    } else {
      categorizedFields.set(prefix, fields); // Multiple fields: create a category.
    }
  }

  // --- Step 4: Handle ORDER_BY Context ---
  // If the last token is ORDER_BY, we don't suggest custom (vip) fields
  if (context.lastToken?.name === LEXER_TOKENS.ORDER_BY) {
    vipFields = [];
  }

  // --- Step 5: Build Suggestion Objects ---
  const dropdownMenuChildren: any[] = [];

  // Always include "vip_id" as a separate suggestion if it exists
  if (allFieldsKeys.includes("vip_id")) {
    dropdownMenuChildren.push(
      new Suggestion({
        text: "vip_id",
        snippetAfter: " ", // Add a space after the suggestion
        textarea: textarea,
      }),
    );
  }

  // Create a "vip" category if there are any vip fields
  if (vipFields.length > 0) {
    dropdownMenuChildren.push(
      new MenuSuggestion({
        context: context,
        text: "vip", // Category label
        textarea: textarea,
        children: vipFields.map(
          (field) =>
            new Suggestion({
              text: field,
              snippetAfter: field === "history" ? "" : " ", // No space after "history"
              textarea: textarea,
            }),
        ),
      }),
    );
  }

  // Create suggestions for each categorized field group
  categorizedFields.forEach((fields, category) => {
    dropdownMenuChildren.push(
      new MenuSuggestion({
        context: context,
        text: category, // Category label
        textarea: textarea,
        children: fields.map(
          (field) =>
            new Suggestion({
              text: field,
              snippetAfter: field === "history" ? "" : " ", // No space after "history"
              textarea: textarea,
            }),
        ),
      }),
    );
  });

  // --- Step 6: Create and Return the Root Menu Suggestion ---

  // Create the final menu suggestion containing all categories.
  const dropdownMenuSuggestion = new MenuSuggestion({
    children: dropdownMenuChildren,
    type: "menu",
    context: context,
    textarea: textarea,
  });

  return dropdownMenuSuggestion;
};

export default createMenuSuggestionWithCategories;
