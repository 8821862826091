import { z } from "zod";

import { SerializedBaseTagSchema } from "@features/tags/tags.interfaces";
import { SerializedProfileSchema } from "./SerializedProfile";

// ----------------------
// Greynoise Schemas
// ----------------------
export const GreynoiseTagSchema = z.object({
  description: z.string(),
  references: z.array(z.string()),
  related_cves: z.array(z.string()),
  tag: z.string(),
  url: z.string(),
});

export const GreynoiseTrendRecordSchema = z.object({
  total: z.number(),
  malicious: z.number(),
  unknown: z.number(),
  benign: z.number(),
});


// ----------------------
// Intel471 Chatter Schemas
// ----------------------
export const Intel471ChatterTrendRecordSchema = z.object({
  chatter: z.number().optional(),
  instant_messages: z.number().optional(),
  posts: z.number().optional(),
  private_messages: z.number().optional(),
});
export const Intel471ChatterTrendSchema = z.record(z.string(), Intel471ChatterTrendRecordSchema);

// ----------------------
// Threat Link
// ----------------------
export const ThreatLinkSchema = z.object({
  title: z.string(),
  url: z.string(),
});

// ----------------------
// Mandiant Schemas
// ----------------------
export const MandiantActorSchema = z.object({
  actor_id: z.string(),
  actor_name: z.string(),
  attribution_scope: z.string(),
  cve_id: z.string(),
  mandiant_cve_to_actor_id: z.number(),
});

export const MandiantExploitSchema = z.object({
  description: z.string(),
  exploitUrl: z.string().optional(),
  fileSize: z.string().optional(),
  md5: z.string(),
  name: z.string(),
  releaseDate: z.string().optional(),
  reliability: z.string().optional(),
  exploit_url: z.string().optional(),
  file_size: z.number().optional(),
  grade: z.string().optional(),
  hashes: z.record(z.string(), z.string()).optional(),
  index: z.any().optional(),
  release_date: z.string().optional(),
  replication_urls: z.array(z.any()).optional(),
});

export const MandiantMalwareSchema = z.object({
  cve_id: z.string(),
  malware_id: z.string(),
  malware_name: z.string(),
  mandiant_cve_malware_id: z.number(),
});

export const MandiantSourceSchema = z.object({
  date: z.string().optional(),
  description: z.string().optional(),
  title: z.string().optional(),
  urls: z
    .object({
      url: z.array(z.string()),
    })
    .optional(),
  is_vendor_fix: z.boolean().optional(),
  source_description: z.string().optional(),
  source_name: z.string().optional(),
  unique_id: z.string().optional(),
  url: z.string().optional(),
});

export const MandiantTechnologySchema = z.object({
  cpe: z.string(),
  cpeTitle: z.string().optional(),
  cpe_title: z.string().optional(),
  technologyName: z.string().optional(),
  technology_name: z.string().optional(),
  vendor: z.string().optional(),
  vendor_name: z.string().optional(),
});

export const MandiantVendorFixUrlSchema = z.object({
  name: z.string(),
  url: z.string(),
});

export const MandiantCpeRangeSchema = z.object({
  end_cpe: z
    .object({
      product: z.string(),
      uri: z.string(),
      vendor: z.string(),
      version: z.string(),
    })
    .nullable(),
  end_rel: z.string().nullable(),
  start_cpe: z.object({
    product: z.string(),
    uri: z.string(),
    vendor: z.string(),
    version: z.string(),
  }),
  start_rel: z.string().nullable(),
});

// ----------------------
// Evidence & Risk Schemas
// ----------------------
export const EvidenceDetailSchema = z.object({
  Criticality: z.number(),
  CriticalityLabel: z.string(),
  EvidenceString: z.string(),
  MitigationString: z.string(),
  Name: z.string(),
  Rule: z.string(),
  RuleCategory: z.string(),
  SightingsCount: z.number(),
  Sources: z.array(z.string()),
  SourcesCount: z.number(),
  Timestamp: z.string(),
});

export const RiskScoreTrendRecordSchema = z.object({
  risk_score: z.number(),
});
export const RiskScoreTrendSchema = z.record(z.string(), RiskScoreTrendRecordSchema);

// ----------------------
// Securin Schemas
// ----------------------
export const SecurinSourceSchema = z.object({
  name: z.string(),
  url: z.string().optional(),
});

export const SecurinVendorCommentSchema = z.object({
  commentary: z.string(),
  issuedDate: z.string(),
  vendor: z.string(),
});

// ----------------------
// Vulncheck Schemas
// ----------------------
export const VulncheckNvcReferenceSchema = z.object({
  source: z.string(),
  url: z.string(),
  tags: z.array(z.string()),
});

export const VulncheckKevReportExploitationSchema = z.object({
  date_added: z.string(),
  url: z.string(),
});

export const VulncheckKevCVSSv2Schema = z.object({
  accessComplexity: z.string(),
  accessVector: z.string(),
  authentication: z.string(),
  availabilityImpact: z.string(),
  baseScore: z.number(),
  confidentialityImpact: z.string(),
  integrityImpact: z.string(),
  vectorString: z.string(),
  version: z.string(),
});

export const VulncheckKevCVSSv3Schema = z.object({
  attackComplexity: z.string(),
  attackVector: z.string(),
  availabilityImpact: z.string(),
  baseScore: z.number(),
  baseSeverity: z.string(),
  confidentialityImpact: z.string(),
  integrityImpact: z.string(),
  privilegesRequired: z.string(),
  scope: z.string(),
  userInteraction: z.string(),
  vectorString: z.string(),
  version: z.string(),
});

export const VulncheckKevCVSSv4Schema = VulncheckKevCVSSv3Schema.extend({});

export const VulncheckKevXDBEntrySchema = z.object({
  clone_ssh_url: z.string(),
  date_added: z.string(),
  exploit_type: z.string(),
  xdb_id: z.string(),
  xdb_url: z.string(),
});

// ----------------------
// Main Threat Schema
// ----------------------
export const threatSchema = z.object({
  cisa_action: z.string(),
  cisa_date_added_to_catalog: z.string(),
  cisa_due_date: z.string(),
  cisa_last_updated_by_nucleus: z.string(),
  cisa_notes: z.string(),
  cisa_product: z.string(),
  cisa_short_description: z.string(),
  cisa_vendor: z.string(),
  cisa_vulnerability_name: z.string(),
  cve_id: z.string(),
  vip_id: z.string(),
  cve_threat_intel_id: z.number(),
  defender_cvss3: z.number(),
  defender_description: z.string(),
  defender_exploit_in_kit: z.boolean(),
  defender_exploit_types: z.string(),
  defender_exploit_uris: z.string(),
  defender_exploit_verified: z.boolean(),
  defender_last_sync: z.string(),
  defender_public_exploit: z.boolean(),
  defender_published_on: z.string(),
  defender_updated_on: z.string(),
  epss_last_updated_by_nucleus: z.string(),
  epss_percentile: z.number(),
  epss_score: z.number(),

  // New fields after 2023-01-25
  greynoise_benign_count: z.number(),
  greynoise_last_updated_by_nucleus: z.string(),
  greynoise_malicious_count: z.number(),
  greynoise_tags: z.array(GreynoiseTagSchema),
  greynoise_total_count: z.number(),
  greynoise_trend: z.record(z.string(), GreynoiseTrendRecordSchema).optional(),
  greynoise_unknown_count: z.number(),

  // New fields after 2023-05-04
  greynoise_max_count: z.number(),
  greynoise_query: z.string(),

  intel471_activity_location_opensource: z.boolean(),
  intel471_activity_location_private: z.boolean(),
  intel471_activity_location_underground: z.boolean(),
  intel471_chatter_trend: z.array(Intel471ChatterTrendSchema).optional(),
  intel471_chatter: z.number(),
  intel471_counter_measure_links: z.array(ThreatLinkSchema),
  intel471_counter_measures: z.string(),
  intel471_exploit_status_available: z.boolean(),
  intel471_exploit_status_productized: z.boolean(),
  intel471_exploit_status_weaponized: z.boolean(),
  intel471_girs: z.record(z.string(), z.number()),
  intel471_im_count: z.number(),
  intel471_interest_level_disclosed_publicly: z.boolean(),
  intel471_interest_level_exploit_sought: z.boolean(),
  intel471_interest_level_researched_publicly: z.boolean(),
  intel471_last_updated_by_nucleus: z.string(),
  intel471_patch_links: z.array(ThreatLinkSchema),
  intel471_pm_count: z.number(),
  intel471_poc_links: z.array(ThreatLinkSchema),
  intel471_poc_observed: z.boolean(),
  intel471_post_count: z.number(),
  intel471_risk_level: z.string(),
  intel471_summary: z.string(),
  intel471_titan_links: z.array(ThreatLinkSchema),
  intel471_uid: z.string(),
  intel471_underground_activity_observed: z.boolean(),
  intel471_underground_activity_summary: z.string(),

  mandiant_actors: z.array(MandiantActorSchema),
  mandiant_analysis: z.string(),
  mandiant_attacking_ease: z.string(),
  mandiant_data_available: z.number(),
  mandiant_exec_summary: z.string(),
  mandiant_exploit_in_the_wild: z.boolean(),
  mandiant_exploit_rating: z.string(),
  mandiant_exploitation_consequence: z.string(),
  mandiant_exploitation_vectors: z.string(),
  mandiant_exploited_by_malware: z.boolean(),
  mandiant_exploits: z.array(MandiantExploitSchema),
  mandiant_last_updated_by_nucleus: z.string(),
  mandiant_malware: z.array(MandiantMalwareSchema),
  mandiant_mitigation_details: z.string(),
  mandiant_mitigations: z.string(),
  mandiant_publish_date: z.string(),
  mandiant_report_id: z.string(),
  mandiant_risk_rating: z.string(),
  mandiant_sources: z.array(MandiantSourceSchema),
  mandiant_summary: z.string(),
  mandiant_technologies: z.array(MandiantTechnologySchema),
  mandiant_title: z.string(),
  mandiant_vendor_fix_urls: z.array(MandiantVendorFixUrlSchema),
  mandiant_vulnerable_products: z.string(),
  mandiant_zero_day: z.boolean(),
  mandiant_status: z.number().optional(),

  mandiant_cpe_ranges: z.array(MandiantCpeRangeSchema),
  mandiant_days_to_patch: z.number(),
  mandiant_aliases: z.string(),
  mandiant_mve_id: z.string(),
  mandiant_affects_ot: z.boolean(),

  nvd_assigner: z.string(),
  nvd_cvssv2_ac_insuf_info: z.boolean(),
  nvd_cvssv2_access_complexity: z.string(),
  nvd_cvssv2_access_vector: z.string(),
  nvd_cvssv2_authentication: z.string(),
  nvd_cvssv2_availability_impact: z.string(),
  nvd_cvssv2_base_score: z.number(),
  nvd_cvssv2_base_severity: z.enum(["Unrated", "Unassigned", "Low", "Medium", "High", "Critical"]),
  nvd_cvssv2_confidentiality_impact: z.string(),
  nvd_cvssv2_exploitability_score: z.number(),
  nvd_cvssv2_impact_score: z.number(),
  nvd_cvssv2_integrity_impact: z.string(),
  nvd_cvssv2_obtain_all_privilege: z.boolean(),
  nvd_cvssv2_obtain_other_privilege: z.boolean(),
  nvd_cvssv2_obtain_user_privilege: z.boolean(),
  nvd_cvssv2_severity: z.enum(["HIGH", "MEDIUM", "LOW"]),
  nvd_cvssv2_user_interaction_required: z.boolean(),
  nvd_cvssv2_vector_string: z.string(),
  nvd_cvssv2_version: z.string(),
  nvd_cvssv3_attack_complexity: z.string(),
  nvd_cvssv3_attack_vector: z.string(),
  nvd_cvssv3_availability_impact: z.string(),
  nvd_cvssv3_base_score: z.number(),
  nvd_cvssv3_base_severity: z.enum(["CRITICAL", "HIGH", "MEDIUM", "LOW", "NONE"]),
  nvd_cvssv3_confidentiality_impact: z.string(),
  nvd_cvssv3_exploitability_score: z.number(),
  nvd_cvssv3_impact_score: z.number(),
  nvd_cvssv3_integrity_impact: z.string(),
  nvd_cvssv3_privileges_required: z.string(),
  nvd_cvssv3_scope: z.string(),
  nvd_cvssv3_user_interaction: z.string(),
  nvd_cvssv3_vector_string: z.string(),
  nvd_cvssv3_version: z.string(),
  nvd_cwes: z.string(),
  nvd_description: z.string(),
  nvd_impact: z.string(),
  nvd_last_modified_date: z.string(),
  nvd_last_updated_by_nucleus: z.string(),
  nvd_published_date: z.string(),
  nvd_references: z.string(),
  nvd_status: z.enum(["Modified", "Analyzed", "Deferred", "Undergoing Analysis", "Awaiting Analysis", "Received"]),

  // New fields after 2023-01-25
  rf_criticality: z.enum(["Critical", "High", "Low", "Medium", "Very Critical"]),
  rf_id: z.any(),
  rf_last_updated_by_nucleus: z.string(),
  rf_num_risk_rules_triggered: z.number(),
  rf_risk_rules_triggered: z.array(EvidenceDetailSchema),
  rf_risk_score: z.number(),
  rf_risk_score_trend: RiskScoreTrendSchema.optional(),

  zdi_affected_vendor: z.any().optional(),
  zdi_can: z.any().optional(),
  zdi_cvss: z.any().optional(),
  zdi_deadline: z.any().optional(),
  zdi_discovered: z.any().optional(),
  zdi_id: z.any().optional(),
  zdi_last_updated_by_nucleus: z.any().optional(),
  zdi_name: z.any().optional(),
  zdi_published: z.any().optional(),
  zdi_reported: z.any().optional(),
  zdi_status: z.any().optional(),
  zdi_updated: z.any().optional(),

  feeds: z.array(z.string()),
  tags: z.array(SerializedBaseTagSchema),
  monitored: z.boolean(),
  favorite: z.boolean(),
  notes: z.string(),
  custom_risk_rating: z.string(),
  vip_risk_level_changes: z.array(
    z.object({
      changed_at: z.string(),
      changed_by: SerializedProfileSchema,
      color: z.string().optional(),
      risk_level: z.string(),
    }),
  ),
  status: z.number(),
  vip_status_history: z.array(
    z.object({
      assigned_at: z.string(),
      assigned_by: z.string(),
      status: z.string(),
    }),
  ),

  // New fields after October 6, 2023
  nuclei_name: z.string(),
  nuclei_description: z.string(),
  nuclei_template_exists: z.boolean(),
  nuclei_template_url: z.string(),
  nuclei_last_updated_by_nucleus: z.string(),
  nuclei_severity: z.string(),

  metasploit_module_exists: z.boolean(),
  metasploit_last_updated_by_nucleus: z.string(),

  project_zero_vendor: z.string(),
  project_zero_product: z.string(),
  project_zero_type: z.string(),
  project_zero_description: z.string(),
  project_zero_date_discovered: z.string(),
  project_zero_date_patched: z.string(),
  project_zero_analysis_url: z.string(),
  project_zero_rca_url: z.string(),
  project_zero_vuln: z.boolean(),
  project_zero_advisory: z.string(),
  project_zero_last_updated_by_nucleus: z.string(),

  // New fields after October 27, 2023
  shodan_hosts: z.number(),
  shodan_last_updated_by_nucleus: z.string(),
  cisa_ransomware_campaigns: z.boolean(),
  exploitdb_last_updated_by_nucleus: z.string(),
  exploitdb_exists: z.boolean(),
  exploitdb_exploits: z.array(z.any()).optional(),
  shadowserver_vuln_score: z.number(),
  shadowserver_product: z.string(),
  shadowserver_vendor: z.string(),
  shadowserver_7d_avg: z.number(),
  shadowserver_30d_avg: z.number(),
  shadowserver_1d: z.number(),
  shadowserver_severity: z.enum(["Critical", "High", "Medium", "Low", "None"]),
  shadowserver_connections: z.number(),
  shadowserver_affects_iot: z.boolean(),
  shadowserver_type: z.string(),
  shadowserver_class: z.string(),
  shadowserver_unique_ips: z.number(),
  shadowserver_last_updated_by_nucleus: z.string(),
  shadowserver_geo: z.string(),
  shadowserver_vuln_id: z.string(),
  zdi_cans: z.string(),

  // New fields after November 23, 2023
  nvd_vendor: z.string(),
  nvd_cpes: z.array(z.string()),

  // New fields after March 4, 2024
  securin_cpes: z.string(),
  securin_fixes: z.string(),
  securin_affected_industries: z.string(),
  securin_plugins: z.string(),
  securin_description: z.string(),
  securin_posts: z.string(),
  securin_prioritized_by: z.string(),
  securin_references: z.string(),
  securin_sources: z.array(SecurinSourceSchema),
  securin_weaknesses: z.string(),
  securin_definitive_severity: z.enum(["Critical", "High", "Medium", "Low", "None"]),
  securin_threat_actors: z.string(),
  securin_threats: z.string(),
  securin_vendor_comments: z.array(SecurinVendorCommentSchema),
  securin_last_updated_by_nucleus: z.string(),
  securin_definitive_vrs: z.number(),
  securin_exploited_in_wild: z.boolean(),
  securin_is_trending: z.boolean(),
  securin_title: z.string(),

  securin_is_discussed: z.boolean(),
  securin_has_poc: z.boolean(),
  securin_has_code_snippet: z.boolean(),
  securin_has_attack_signature: z.boolean(),
  securin_has_pentest_framework: z.boolean(),
  securin_is_prioritized: z.boolean(),
  securin_cvssv2_vector: z.string(),
  securin_cvssv2_score: z.number(),
  securin_cvssv3_vector: z.string(),
  securin_cvssv3_score: z.number(),
  securin_requester_email: z.string(),
  securin_assigner_email: z.string(),
  securin_publish_date: z.string(),

  cisa_kev_exists: z.boolean(),

  mandiant_is_predicted: z.boolean(),

  vulncheck_kev_vendor: z.string(),
  vulncheck_kev_product: z.string(),
  vulncheck_kev_description: z.string(),
  vulncheck_kev_last_updated_by_nucleus: z.string(),
  vulncheck_kev_reported_exploitation: z.array(VulncheckKevReportExploitationSchema),
  vulncheck_kev_xdb_entries: z.array(VulncheckKevXDBEntrySchema),
  vulncheck_kev_due_date: z.string(),
  vulncheck_kev_date_added: z.string(),
  vulncheck_kev_exists: z.boolean(),
  vulncheck_kev_ransomware_campaigns: z.string(),
  vulncheck_kev_name: z.string(),
  vulncheck_kev_action: z.string(),
  vulncheck_nvd_cvssv2: VulncheckKevCVSSv2Schema,
  vulncheck_nvd_cvssv3: VulncheckKevCVSSv3Schema,
  vulncheck_nvd_cvssv4: VulncheckKevCVSSv4Schema,
  vulncheck_nvd_references: z.array(VulncheckNvcReferenceSchema),
  vulncheck_nvd_description: z.string(),
  vulncheck_nvd_last_modified: z.string(),
  vulncheck_nvd_status: z.string(),
  vulncheck_nvd_published: z.string(),
  vulncheck_nvd_source_identifier: z.string(),
  vulncheck_nvd_cpes: z.array(z.string()),
  vulncheck_nvd_weaknesses: z.string(),
  vulncheck_nvd_last_updated_by_nucleus: z.string(),

  // computed fields (if feature flag is enabled)
  nucleus_exploited_by_malware: z.boolean(),
  nucleus_exploited: z.boolean(),
  nucleus_likely_to_be_exploited: z.boolean(),
  nucleus_impacts_ot: z.boolean(),
  nucleus_public_exploit_available: z.boolean(),

  // new nvd v4 fields
  nvd_cvssv4_base_score: z.number(),
  nvd_cvssv4_base_severity: z.enum(["CRITICAL", "HIGH", "MEDIUM", "LOW", "NONE"]),
  nvd_cvssv4_vulnerability_response_effort: z.string(),
  nvd_cvssv4_value_density: z.string(),
  nvd_cvssv4_recovery: z.string(),
  nvd_cvssv4_automatable: z.string(),
  nvd_cvssv4_safety: z.string(),
  nvd_cvssv4_modified_subsequent_system_availability: z.string(),
  nvd_cvssv4_modified_subsequent_system_integrity: z.string(),
  nvd_cvssv4_modified_subsequent_system_confidentiality: z.string(),
  nvd_cvssv4_modified_vulnerable_system_availability: z.string(),
  nvd_cvssv4_modified_vulnerable_system_integrity: z.string(),
  nvd_cvssv4_modified_vulnerable_system_confidentiality: z.string(),
  nvd_cvssv4_modified_user_interaction: z.string(),
  nvd_cvssv4_modified_privileges_required: z.string(),
  nvd_cvssv4_attack_requirements: z.string(),
  nvd_cvssv4_attack_complexity: z.string(),
  nvd_cvssv4_attack_vector: z.string(),
  nvd_cvssv4_vector_string: z.string(),
  nvd_cvssv4_provider_urgency: z.string(),
  nvd_cvssv4_version: z.string(),
  nvd_cvssv4_privileges_required: z.string(),
  nvd_cvssv4_user_interaction: z.string(),
  nvd_cvssv4_vulnerable_system_confidentiality: z.string(),
  nvd_cvssv4_vulnerable_system_integrity: z.string(),
  nvd_cvssv4_vulnerable_system_availability: z.string(),
  nvd_cvssv4_subsequent_system_confidentiality: z.string(),
  nvd_cvssv4_subsequent_system_integrity: z.string(),
  nvd_cvssv4_subsequent_system_availability: z.string(),

  // history field (used in djangoql)
  history: z.object({}).passthrough(),

  nucleus_risk_rating: z.enum(["Low", "Medium", "High", "Critical", "Existential"]),
});

// Infer the SerializedThreat type from the schema.
export type SerializedThreat = z.infer<typeof threatSchema>;
export type ThreatKey = keyof SerializedThreat;

// Export valid keys for use elsewhere.
export const validThreatKeys = Object.keys(threatSchema.shape) as (keyof SerializedThreat)[];

// Special type for frontend listing.
export interface FrontendSerializedThreat extends SerializedThreat {}

/**
 * Checks if the provided key is a valid key on SerializedThreat.
 *
 * @param key - The column key to validate.
 * @returns true if key is a valid ThreatKey, false otherwise.
 */
export function isValidThreatKey(key: any): key is ThreatKey {
  return typeof key === "string" && validThreatKeys.includes(key as ThreatKey);
}

export default SerializedThreat;
