import type { color } from "@vip-tailwind/types/components/button";

const buttonGroupDividerColor: Record<color, Record<string, string>> = {
  primary: {
    divideColor: "divide-gray-100",
  },
  secondary: {
    divideColor: "divide-gray-100",
  },
  success: {
    divideColor: "divide-green-50",
  },
  danger: {
    divideColor: "divide-red-50",
  },
  warning: {
    divideColor: "divide-yellow-50",
  },
  "secondary-success": {
    divideColor: "divide-green-50",
  },
  "secondary-danger": {
    divideColor: "divide-red-50",
  },
};

export default buttonGroupDividerColor;
