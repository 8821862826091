import type { Placement } from "@floating-ui/react";
import { Button } from "@vip-tailwind/components/Button";
import { Menu, MenuHandler, MenuItem, MenuList } from "@vip-tailwind/components/Menu";
import { Typography } from "@vip-tailwind/components/Typography";
import classNames from "classnames";
import {
  type Dispatch,
  type FunctionComponent,
  type HTMLAttributes,
  type MouseEventHandler,
  type SetStateAction,
  forwardRef,
  useState,
} from "react";
import { NavLink, useNavigate } from "react-router";

interface HeaderMenuItemProps {
  label?: string;
  iconClass?: string;
  url?: string;
  color?: string;
  size?: "sm" | "lg";
  BadgeWrapper?: React.ComponentType<{ children: React.ReactNode }>;
  isActive?: boolean;
}

interface MenuTypographyItemProps extends Omit<HeaderMenuItemProps, "url"> {
  url: string;
  external?: boolean;
  action?: (e: React.MouseEvent<HTMLLinkElement>) => void;
}

interface HeaderMenuProps extends HeaderMenuItemProps {
  menuItems?: MenuTypographyItemProps[];
  showChevron?: boolean;
  placement?: Placement;
  handleMenuClick?: (nextOpen: boolean) => void;
  BadgeWrapper?: React.ComponentType<{ children: React.ReactNode }>;
}

const HeaderButtonLink: FunctionComponent<{ iconClass: string }> = ({ iconClass }) => (
  <div className="w-4 h-4 leading-4 text-sm font-normal tracking-wider">
    <i className={`${iconClass}`} />
  </div>
);

const MenuTypographyItem = forwardRef<
  HTMLButtonElement,
  { item: MenuTypographyItemProps; setIsOpen: Dispatch<SetStateAction<boolean>> }
>(({ item, setIsOpen }, ref) => {
  const { url, iconClass, label, BadgeWrapper, external } = item;

  const menuItem = (
    <MenuItem className="flex items-center gap-2  tracking-wider" ref={ref}>
      {iconClass && <HeaderButtonLink iconClass={iconClass} />}
      <Typography as="span" variant="small" className="font-normal">
        {label}
      </Typography>
    </MenuItem>
  );

  const badgedMenuItem = BadgeWrapper ? <BadgeWrapper>{menuItem}</BadgeWrapper> : menuItem;

  const handleOnClick: MouseEventHandler = (e: React.MouseEvent<HTMLLinkElement>) => {
    setIsOpen(false);
    if (item.action) {
      item.action(e);
    }

    return;
  };

  return external ? (
    <Typography
      as={"a"}
      href={url}
      target={external ? "_blank" : undefined}
      variant="small"
      className="block w-full border-0 outline-none"
    >
      {badgedMenuItem}
    </Typography>
  ) : (
    <NavLink to={url} className="block w-full border-0 outline-none" onClick={handleOnClick}>
      <Typography variant="small">{badgedMenuItem}</Typography>
    </NavLink>
  );
});

const HeaderMainMenuItem = forwardRef<
  HTMLButtonElement,
  HeaderMenuProps & { isOpen?: boolean } & HTMLAttributes<HTMLButtonElement>
>(
  (
    {
      iconClass,
      color,
      label,
      BadgeWrapper,
      isActive,
      menuItems,
      isOpen,
      showChevron = false,
      url,
      handleMenuClick,
      ...props
    },
    ref,
  ) => {
    const navigate = useNavigate();
    
    const hasChevron = showChevron && menuItems && menuItems.length > 0;
    const hasBadge = !!BadgeWrapper;
    // we don't want to wrap the button in an anchor tag if it has a chevron, since its a menu navigation item
    const wrapIntoUrl = url && !hasChevron;

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      props.onClick?.(e);
      handleMenuClick?.(!isOpen);
      if (wrapIntoUrl) {
        navigate(url);
      }
    };

    const button = (
      <Button
        variant="text"
        className={classNames("rounded flex items-center px-2", {
          // when header menu item with an icon, we have bigger padding to fit 32x32 sizing
          "py-1.5": !iconClass,
          "py-2": iconClass,
          "bg-blue-300 text-blue-500": isActive,
        })}
        ripple={false}
        {...props}
        ref={ref}
        tabIndex={wrapIntoUrl ? -1 : undefined}
        onClick={handleOnClick}
      >
        {iconClass && <HeaderButtonLink iconClass={iconClass} />}
        {label && (
          <span className={classNames("sm:pl-1 normal-case text-sm font-normal", { "pr-1": !hasChevron })}>
            {label}
          </span>
        )}
        {hasChevron && (
          <div className="flex items-center ml-2 mr-1">
            <i className={`fa fa-chevron-down text-sm transition-transform ${isOpen ? "rotate-180" : ""}`} />
          </div>
        )}
      </Button>
    );

    if (hasBadge) {
      return <BadgeWrapper>{button}</BadgeWrapper>;
    }

    return wrapIntoUrl ? <a href={url}>{button}</a> : button;
  },
);

const HeaderMenu: FunctionComponent<HeaderMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = (nextOpen: boolean) => {
    setIsOpen(nextOpen);
    props.handleMenuClick?.(nextOpen);
  };

  if (props.menuItems && props.menuItems.length > 1) {
    return (
      <div className="relative">
        <Menu
          offset={{ mainAxis: 0, crossAxis: 0 }}
          open={isOpen}
          handler={handleMenuClick}
          placement={props.placement}
        >
          <MenuHandler>
            <HeaderMainMenuItem {...props} isOpen={isOpen} />
          </MenuHandler>
          <MenuList className="p-0">
            {props.menuItems.map((subItem) => (
              <MenuTypographyItem key={subItem.label} item={subItem} setIsOpen={setIsOpen} />
            ))}
          </MenuList>
        </Menu>
      </div>
    );
  }

  return <HeaderMainMenuItem {...props} handleMenuClick={handleMenuClick} />;
};

export { HeaderMenu, MenuTypographyItem };
export type { HeaderMenuItemProps, MenuTypographyItemProps };
