import { RecordsType } from "@features/listing";
import { z } from "zod";

import type { SerializedMandiantMalwareKey } from "./SerializedMandiantMalware";
import type SerializedOrganizationConfiguration from "./SerializedOrganizationConfiguration";
import type SerializedSavedSearch from "./SerializedSavedSearch";
import type { ThreatKey } from "./SerializedThreat";


// ----------------------
// SerializedProfile Schema
// ----------------------
export const SerializedProfileSchema = z.object({
  id: z.number(),
  username: z.string(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  email: z.string(),
  is_active: z.boolean(),
  is_staff: z.boolean(),
  is_superuser: z.boolean(),
  date_joined: z.string().optional(),
  last_login: z.string().optional(),

  // TODO: Uncomment when we have the schemas
  // client_settings: ClientSettingsSchema.optional(),
  // user_data: UserDataSchema.partial().optional(),
  // organization_configuration: SerializedOrganizationConfigurationSchema,
  // organization: OrganizationSchema,
  // saved_searches: z.array(SerializedSavedSearchSchema).optional(),
  client_settings: z.object({}).passthrough().optional(),
  user_data: z.object({}).passthrough().partial().optional(),
  organization_configuration: z.object({}).passthrough(),
  organization: z.object({}).passthrough(),
  saved_searches: z.array(z.object({}).passthrough()).optional(),
  // TODO: Uncomment when we have the schemas

  is_weekly_rollup_email_enabled: z.boolean(),
});

export default interface SerializedProfile {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  email: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  date_joined?: string;
  last_login?: string;
  client_settings?: ClientSettings;
  user_data?: Partial<UserData>;
  organization_configuration: SerializedOrganizationConfiguration;
  organization: {
    id: number;
    name: string;
    role: string;
    tier: string;
    watched_search_result_limit: boolean;
    expiration_date: string;
  };
  saved_searches?: SerializedSavedSearch[];
  is_weekly_rollup_email_enabled: boolean;
}

export interface ClientSettings {
  _v: number;
  visible_columns: {
    [RecordsType.CVEs]: ThreatKey[];
    [RecordsType.Malware]: SerializedMandiantMalwareKey[];
  };
  pinned_searches?: {
    // key is actually a slot ID: 0-5, value is a search ID
    [key: string]: number | string; // number in case thats real search, string in case its global search from site settings
  };
}

export interface UserData {
  quick_start_items_completed: string[];
  quick_start_complete_datetime?: string;
}
