import PropTypes from "prop-types";
import type { ElementType, ReactNode } from "react";

// generic types
import type { colors } from "../generic";
import { propTypesColors } from "../generic";

/**
 * This file contains the types and prop-types for Typography component.
 */

// typescript types
export type variant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "lead" | "paragraph" | "small";
export type color = "inherit" | "current" | "black" | "white" | colors;
export type className = string;
export type asType = React.ElementType;
export type children = React.ReactNode;

export const propTypesVariant: variant[] = ["h1", "h2", "h3", "h4", "h5", "h6", "lead", "paragraph", "small"];
export const propTypesColor: color[] = ["inherit", "current", "black", "white", ...propTypesColors];
export const propTypesAs = PropTypes.elementType;
export const propTypesClassName = PropTypes.string;
export const propTypesChildren = PropTypes.node.isRequired as PropTypes.Validator<React.ReactNode>;
