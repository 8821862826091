import PropTypes from "prop-types";
import type { ReactNode } from "react";

// generic types
import type { semantic_colors } from "../generic";

/**
 * This file contains the types and prop-types for Input and Textarea components.
 */

// typescript types
export type variant = "standard" | "outlined" | "static";
export type size = "md" | "lg";
export type color = semantic_colors | "primary";
export type label = string;
export type error = boolean;
export type success = boolean;
export type icon = ReactNode;
export type resize = boolean;
export type labelProps = {
  [key: string]: any;
};
export type containerProps = {
  [key: string]: any;
};
export type shrink = boolean;
export type className = string;

// javascript prop-types
export const propTypesVariant: variant[] = ["standard", "outlined", "static"];
export const propTypesSize: size[] = ["md", "lg"];
export const propTypesColor: color[] = ["success", "danger", "warning", "primary"];
export const propTypesLabel = PropTypes.string;
export const propTypesError = PropTypes.bool;
export const propTypesSuccess = PropTypes.bool;
export const propTypesIcon = PropTypes.node;
export const propTypesResize = PropTypes.bool;
export const propTypesLabelProps = PropTypes.instanceOf(Object);
export const propTypesContainerProps = PropTypes.instanceOf(Object);
export const propTypesShrink = PropTypes.bool;
export const propTypesClassName = PropTypes.string;
