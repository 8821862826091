import fetchWithSession from "@utils/fetchWithSession";
import Button from "@vip-tailwind/components/Button";

async function doLogout() {
  await fetchWithSession("/logout/", {
    method: "GET",
    noParse: true,
  });
  window.location.href = "/login/";
}

function VersionMismatchBanner() {
  const handleLogout = () => {
    doLogout();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <div className="flex flex-col items-center rounded-lg p-6 m-4 max-w-lg shadow-2xl font-sans text-base bg-red-50 text-warning border border-warning">
        <div className="w-full pb-4 text-center">
          <h2 className="text-xl font-bold mb-2">
            <i className="fas fa-exclamation-triangle mr-2" /> Version Mismatch Detected
          </h2>
          <p className="text-sm text-gray-800">
            A new version of the application is available. Please refresh or log out to ensure proper functionality.
          </p>
        </div>
        <div className="w-full flex justify-center gap-4">
          <Button type="button" onClick={handleRefresh} variant="outlined" color="secondary">
            Refresh Application
          </Button>
          <Button type="button" onClick={handleLogout} color="primary">
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
}

export { VersionMismatchBanner };
