import TablePagination from "@components/TablePagination";
import { useClientSorting, useClosestListingTableState } from "@features/listing";
import {
  type ColumnSizingState,
  type OnChangeFn,
  type PaginationState,
  type SortingState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { useCallback, useState } from "react";
import TableLayout from "./TableLayout";

const TableInstance = () => {
  const { listingTableSnap, listingTableState } = useClosestListingTableState();

  const {
    results,
    columnsConfiguration,
    isFetching,
    isEnabled,
    recordsType,
    displayFeedsRow: showExtraRow,
    tableMaxHeight,
    totalPages,
  } = listingTableSnap;

  const tableState = listingTableState.getState();

  const [canHandleSorting, appliedSorting] = useClientSorting(tableState.sorting, recordsType);

  // sync the table state with the filters
  const handlePaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updater) => {
      const next =
        typeof updater === "function"
          ? updater({
              pageIndex: listingTableSnap.page,
              pageSize: listingTableSnap.perPage,
            })
          : updater;

      listingTableState.updatePagination(next.pageIndex, next.pageSize);
      return next;
    },
    [listingTableSnap.page, listingTableSnap.perPage, listingTableState],
  );

  const handleColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback(
    (updater) => {
      const next = typeof updater === "function" ? updater(tableState.columnSizing) : updater;

      listingTableState.updateColumnSizing(next);
    },
    [tableState.columnSizing, listingTableState],
  );

  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    if (!canHandleSorting) {
      return;
    }

    const next = typeof updater === "function" ? updater([...tableState.sorting]) : updater;

    listingTableState.updateSorting(next);
  };

  const tableInstance = useReactTable({
    data: isEnabled && results ? results : [],
    columns: columnsConfiguration,
    getCoreRowModel: getCoreRowModel(),

    // Resizing
    enableColumnResizing: true,
    columnResizeMode: "onEnd",
    onColumnSizingChange: handleColumnSizingChange,

    // Sorting
    onSortingChange: handleSortingChange,
    enableSorting: canHandleSorting,

    // Pagination control
    manualPagination: true,
    pageCount: totalPages,
    onPaginationChange: handlePaginationChange,
    state: {
      sorting: appliedSorting as SortingState,
      columnSizing: tableState.columnSizing,
      pagination: {
        pageIndex: listingTableSnap.page,
        pageSize: listingTableSnap.perPage,
      },
    },
  });

  return (
    <div className="flex flex-col flex-1 h-full">
      <TablePagination table={tableInstance} />
      <TableLayout
        table={tableInstance}
        isFetching={isFetching}
        showExtraRow={showExtraRow}
        tableMaxHeight={tableMaxHeight}
      />
      <TablePagination table={tableInstance} showSizeSelector={false} />
    </div>
  );
};

export default TableInstance;
