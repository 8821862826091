import type SerializedOrganization from "./SerializedOrganization";
import type SerializedOrganizationConfiguration from "./SerializedOrganizationConfiguration";
import type SerializedOrganizationMember from "./SerializedOrganizationMember";

const DEFAULT_MAX_USERS_ALLOWED = 5;

class OrganizationUIModel implements SerializedOrganization {
  id!: number;
  members!: SerializedOrganizationMember[];
  name!: string;
  data!: object;
  created_at!: string;
  modified_at!: string;
  expiration_date?: string;
  trial_expiration_date?: string;
  configuration?: SerializedOrganizationConfiguration;
  tier!: "FREE" | "TRIAL" | "PAID";
  has_risk_levels?: boolean;
  sso_settings?: {
    domain: string;
    provider: "Okta" | "None";
    metadata_url: string;
  };

  //
  currentUserID?: number;

  constructor(organization: SerializedOrganization, currentUserID: number) {
    Object.assign(this, organization);

    this.currentUserID = currentUserID;
  }

  isOrganizationAdmin() {
    return this.members.find((member) => member.profile.id === this.currentUserID)?.role === "org_admin";
  }

  canUseCustomRiskLevels() {
    return this.configuration?.are_custom_risk_levels_enabled ?? false;
  }

  get maxUsers() {
    return this.configuration?.max_users_allowed ?? DEFAULT_MAX_USERS_ALLOWED;
  }

  get activeUsers() {
    return this.members.filter((u) => u.profile.is_active).length;
  }

  get canInviteNewUser() {
    return this.activeUsers < this.maxUsers;
  }

  get availableFreeSlots() {
    return this.maxUsers - this.activeUsers;
  }

  get isSSOEnabled() {
    return !!this.sso_settings?.provider;
  }

  get ssoSettings() {
    return this.sso_settings;
  }
}

export { OrganizationUIModel };