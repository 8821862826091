import type { color } from "@vip-tailwind/types/components/select";

const selectStaticColors: Record<color, Record<string, Record<string, string>>> = {
  gray: {
    close: {
      borderColor: "border-blue-200",
    },
    open: {
      borderColor: "border-blue-700",
    },
    withValue: {
      borderColor: "border-blue-200",
    },
  },
};

export default selectStaticColors;
