import "./DjangoQL/DjangoQL.css";

import {
  type FieldReferencesFieldsState,
  getFieldReferencesFieldsSnapshot,
} from "@features/field_references/fieldReferences.state";
import type { ThreatKey } from "@interfaces/SerializedThreat";
import { CONTAINS_CVE_ID_REGEX, FULL_CVE_ID_REGEX, TOKEN_TYPE } from "@utils/Lexer";
import { type DebouncedFunc, debounce } from "lodash-es";
import { MenuSuggestion } from "./DjangoQL/MenuSuggestion";
import QLContext, { CONTEXT_SCOPE } from "./DjangoQL/QLContext";
import { Suggestion } from "./DjangoQL/Suggestion";
import { autofixCveId, stringToCVEsLikeList } from "./DjangoQL/autoFixSearchQuery";
import createMenuSuggestionWithCategories from "./DjangoQL/createMenuSuggestionWithCategories";
import FieldModel, { resolveFieldName } from "./DjangoQL/fields/FieldModel";
import FunctionFieldModel from "./DjangoQL/fields/FunctionFieldModel";
import NotesFieldModel from "./DjangoQL/fields/NotesFieldModel";
import RiskLevelFieldModel from "./DjangoQL/fields/RiskLevelFieldModel";
import TagsFieldModel from "./DjangoQL/fields/TagsFieldModel";
import VipStatusFieldModel from "./DjangoQL/fields/VipStatusFieldModel";
import { FIELD_TYPE } from "./DjangoQL/utils";
import type { NucleusQLState } from "./NucleusQL/NucleusQLState";

interface DjangoQLOptions {
  uid: string;
  syntaxHelp?: string;
  onSubmit?: (query: string) => void;
  inputRef: HTMLTextAreaElement;
  isAdmin?: boolean;

  // valtio state taken from nearest context, in order to perform mutations
  stateUpdater: (state: Partial<NucleusQLState>) => void;

  currentValue: string;
  cursorPos: number | null;
  excludedFields?: string[];
  onCompletionOpen: (isOpen: boolean) => void;
}

class DjangoQL {
  uid: string;
  isAdmin = false;
  options: DjangoQLOptions;

  fieldReferences!: Readonly<FieldReferencesFieldsState>;
  // ToDo: wuld be great to validate search query based on these fields too
  excludedFields: string[] = [];

  boundStateUpdater: (state: Partial<NucleusQLState>, isValidate?: boolean, isSubmit?: boolean) => void;
  currentValue: string;
  currentCursorPos = 0;

  // suggestions contains instances of classes which extend Suggestion
  suggestions: Suggestion[] = [];
  textarea: HTMLTextAreaElement;
  debouncedRenderCompletion: DebouncedFunc<typeof this.updateCompletion> = debounce(
    this.updateCompletion.bind(this),
    75,
  );

  _id_increment = 0; // used to generate unique ids for suggestions
  boundOnKeyDown!: (
    e: KeyboardEvent & { keyCode: any; preventDefault: () => void; currentTarget: { form: { submit: () => void } } },
  ) => true | undefined;
  boundHideCompletion!: () => void;
  boundUpdateCompletionClick!: (e: MouseEvent) => void;

  onCompletionOpen: (isOpen: boolean) => void;

  constructor(options: DjangoQLOptions) {
    this.uid = options.uid;

    this.options = options;

    this.isAdmin = options.isAdmin ?? false;

    this.suggestions = [];

    this.textarea = options.inputRef;

    this.boundStateUpdater = options.stateUpdater.bind(this);
    this.currentValue = options.currentValue;
    this.currentCursorPos = options.cursorPos ?? 0;

    this.onCompletionOpen = options.onCompletionOpen;

    if (!this.textarea) {
      this.logError("QL Element not found.");
      return;
    }

    if (options.excludedFields) {
      this.excludedFields = options.excludedFields;
    }

    if (this.textarea.tagName !== "TEXTAREA") {
      this.logError(
        `selector must be pointing to <textarea> element, but ${this.textarea.tagName} was found`,
        this.textarea,
      );
      return;
    }

    this.fieldReferences = getFieldReferencesFieldsSnapshot() as FieldReferencesFieldsState;
    if (!this.fieldReferences) {
      throw new Error("Field references state is not initialized");
    }

    this.textarea.setAttribute("autocomplete", "off");
    this.textarea.style.resize = "none";
    this.initEventListeners();
  }

  public updateCurrentValues(updatedValue: string, updatedCursorPos: number | null) {
    if (this.currentValue !== updatedValue || (updatedCursorPos && this.currentCursorPos !== updatedCursorPos)) {
      this.currentValue = updatedValue;

      if (updatedCursorPos !== null) {
        this.currentCursorPos = updatedCursorPos;
      }

      this.debouncedRenderCompletion();
    }
  }

  initEventListeners() {
    this.boundOnKeyDown = this.onKeydown.bind(this);
    this.boundHideCompletion = this.hideCompletion.bind(this);
    this.boundUpdateCompletionClick = this.updateCompletionClick.bind(this);

    this.textarea.addEventListener("keydown", this.boundOnKeyDown as EventListener);
    this.textarea.addEventListener("blur", this.boundHideCompletion);
    this.textarea.addEventListener("click", this.boundUpdateCompletionClick);
  }

  clearEventListeners() {
    if (!this.textarea) {
      console.error("DjangoQL: textarea is not defined");
      return;
    }

    this.textarea.removeEventListener("keydown", this.boundOnKeyDown as EventListener);
    this.textarea.removeEventListener("blur", this.boundHideCompletion);
    this.textarea.removeEventListener("click", this.boundUpdateCompletionClick);
  }

  updateCompletionClick(e: MouseEvent) {
    e.preventDefault();

    // update cursor position on click and rerender completion
    this.currentCursorPos = this.textarea.selectionStart;

    this.debouncedRenderCompletion();
  }

  destroy() {
    if (!this.textarea) {
      console.error("DjangoQL: textarea is not defined");
      return;
    }

    this.clearEventListeners();
    this.onCompletionOpen(false);
  }

  getContext() {
    return new QLContext(this.fieldReferences, this.boundStateUpdater, this.currentValue, this.currentCursorPos);
  }

  getCompletionElement(): HTMLElement {
    try {
      this.onCompletionOpen(true);

      return document.getElementById(`nucleusql-completion-content-${this.uid}`)!;
    } catch (e) {
      this.logError(`NucleusQL: Completion element not found for uid ${this.uid}`);
      return null as unknown as HTMLElement; // we know that the element will be there or error will be thrown, so we cast this to HTMLElement
    }
  }

  logError(message: string, extra?: any) {
    console.error(`DjangoQL: ${message}`, extra);
  }

  get hasOnlyOneSuggestions() {
    return this.suggestions.length === 1;
  }

  onKeydown(
    e: KeyboardEvent & {
      keyCode: any;
      preventDefault: () => void;
      currentTarget: { form: { submit: () => void } };
    },
  ) {
    if (this.hasOnlyOneSuggestions && this.suggestions[0]?.type === "menu") {
      // menu handles its own keydown events
      // ? make this part more abstract?
      return true;
    }

    switch (e.keyCode) {
      case 13: // Enter
        // Technically this is a textarea, due to automatic multi-line feature,
        // but other than that it should look and behave like a normal input.
        // So expected behavior when pressing Enter is to submit the form,
        // not to add a new line.
        this.boundStateUpdater(
          {
            value: this.textarea.value,
          },
          true,
          true,
        );

        e.preventDefault();
        break;

      case 27: // Esc
        this.hideCompletion();
        break;

      case 16: // Shift
      case 17: // Ctrl
      case 18: // Alt
      case 91: // Windows Key or Left Cmd on Mac
      case 93: // Windows Menu or Right Cmd on Mac
        // Control keys shouldn't trigger completion popup
        break;

      default:
        break;
    }
  }

  private updateCompletion() {
    this.destroyCompletion();

    // only executing when the textarea is focused
    if (this.textarea === document.activeElement) {
      this.generateSuggestions();
      this.renderCompletion();
    }
  }

  hideCompletion() {
    this.onCompletionOpen(false);

    // add classes for active/inactive states
    this.textarea.classList.add("rounded-b");
  }

  destroyCompletion() {
    // Removing all options in order to re-render them. This might be overkill, need to check performance && update only changed options
    for (const element of this.suggestions) {
      const suggestion = element;
      suggestion.destroy();
    }

    this.onCompletionOpen(false);
  }

  renderCompletion() {
    if (!this.suggestions.length) {
      this.hideCompletion();
      return;
    }

    const mountingPoint = this.getCompletionElement();
    const context = this.getContext();

    const suggestionsLen = this.suggestions.length;
    for (let i = 0; i < suggestionsLen; i++) {
      this._id_increment += 1;
      const suggestionInstance = this.suggestions[i];
      const renderedSugesstion = suggestionInstance?.render(this._id_increment, context);

      if (!renderedSugesstion) {
        // some suggestions might not be rendered, e.g. they have own rendering logic rather than adding list items
        continue;
      }

      mountingPoint.appendChild(renderedSugesstion);
    }

    if (this.textarea) {
      this.textarea.classList.remove("rounded-b");
    }
  }

  showGenericTooltipIfPossible() {
    const ctx = this.getContext();

    if (ctx.currentFullToken && ctx.currentFullToken.name === "CVE_ID") {
      const isFullMatch = FULL_CVE_ID_REGEX.test(ctx.prefix);
      this.suggestions = [
        new MenuSuggestion({
          children: [
            new Suggestion({
              text: `Search for VIP ID${!isFullMatch ? "s that contain" : ":"} ${ctx.prefix}`,
              value: autofixCveId(ctx.prefix),
              snippetBefore: "",
              snippetAfter: " ",
              className: "text-gray-500",
              type: "text",
              textarea: this.textarea,
              isReplacement: true,
            }),
          ],
          type: "menu",
          context: ctx,
          textarea: this.textarea,
        }),
      ];
    } else if (ctx.field === null && CONTAINS_CVE_ID_REGEX.test(ctx.currentValue) && /[ ,]/.test(ctx.currentValue)) {
      // Lets check for list of CVEs
      const cveList = stringToCVEsLikeList(ctx.currentValue);

      if (cveList.length > 1) {
        // Lets make suggestion with multiple CVEs
        this.suggestions = [
          new MenuSuggestion({
            children: [
              new Suggestion({
                text: `Search for VIP IDs (${cveList.slice(0, 3).join(", ")}${
                  cveList.length > 3 ? ` and ${cveList.length - 3} more` : ""
                })`,
                value: autofixCveId(ctx.currentValue),
                snippetBefore: "",
                snippetAfter: " ",
                className: "text-gray-500",
                type: "text",
                textarea: this.textarea,
                isReplacement: true,
              }),
            ],
            type: "menu",
            context: ctx,
            textarea: this.textarea,
          }),
        ];
      }
    }
  }

  generateSuggestions() {
    if (!this.textarea || this.textarea.selectionStart !== this.textarea.selectionEnd) {
      // Introspections are not loaded yet OR
      // We shouldn't show suggestions when something is selected
      this.suggestions = [];
      return;
    }

    const context = this.getContext();
    // thats default filter applied to all suggestions. We can override it for specific cases
    let searchFilter = (item: Suggestion) => {
      // Menu is never filtered
      if (item.type === "menu" || item.isLogical) {
        return true;
      }

      return item.text.toLowerCase().indexOf(context.prefix.toLowerCase()) >= 0;
    };

    const allFieldsKeys = Object.keys(this.fieldReferences)
      .filter((key) => this.fieldReferences[key as ThreatKey]?.searchable !== false)
      .filter((key) => !this.excludedFields.includes(key));

    this.suggestions = [];
    if (context.scope === CONTEXT_SCOPE.FIELD) {
      // if current full token is functional, we give it full control over context since they are self-dependant
      const { currentFullToken } = context;
      if (currentFullToken?.type === TOKEN_TYPE.FUNCTION) {
        const fieldModel = new FunctionFieldModel(
          currentFullToken.name,
          currentFullToken.value,
          this.textarea,
          this.fieldReferences,
        );
        // no searchFilter, since its already applied in the field model
        searchFilter = () => true;
        this.suggestions = fieldModel.getSuggestions(context);
      } else if (context.prefix !== "") {
        // include all suggestions as text ones
        this.suggestions = allFieldsKeys.map(
          (text) =>
            new Suggestion({
              text,
              snippetAfter: text === "history" ? "" : " ", // might be moved to field method if more conditions will apply
              textarea: this.textarea,
            }),
        );
      } else {
        this.suggestions.push(createMenuSuggestionWithCategories(allFieldsKeys, context, this.textarea));
      }
    } else if (context.scope === CONTEXT_SCOPE.LOGICAL) {
      this.suggestions = [
        new Suggestion({
          text: "and",
          snippetAfter: " ",
          textarea: this.textarea,
        }),
        new Suggestion({
          text: "or",
          snippetAfter: " ",
          textarea: this.textarea,
        }),
      ];

      if (context.nestingLevel === 0) {
        this.suggestions.push(
          new Suggestion({
            text: "order by",
            snippetAfter: " ",
            textarea: this.textarea,
          }),
        );
      }
    } else if (context.scope === CONTEXT_SCOPE.SORTDIRECTION) {
      this.suggestions.push(
        new Suggestion({
          text: "asc",
          snippetAfter: " ",
          textarea: this.textarea,
        }),
        new Suggestion({
          text: "desc",
          snippetAfter: " ",
          textarea: this.textarea,
        }),
      );
    } else if (context.field) {
      const fieldValue = resolveFieldName(context.field);
      if (!fieldValue) {
        return;
      }

      const fieldReference = this.fieldReferences[fieldValue];
      if (!fieldReference) {
        return;
      }

      let fieldModel: FieldModel;
      switch (fieldReference.type) {
        case FIELD_TYPE.TAGS:
          fieldModel = new TagsFieldModel(fieldValue, fieldReference, this.textarea);
          break;
        case FIELD_TYPE.NOTES:
          fieldModel = new NotesFieldModel(fieldReference, this.isAdmin, this.textarea);
          break;
        case FIELD_TYPE.RISK_LEVEL:
          fieldModel = new RiskLevelFieldModel(fieldReference, this.textarea);
          break;
        case FIELD_TYPE.CVE_STATUS:
          fieldModel = new VipStatusFieldModel(fieldReference, this.textarea);
          break;
        default:
          fieldModel = new FieldModel(fieldValue, fieldReference, this.textarea);
          break;
      }

      // no searchFilter, since its already applied in the field model
      searchFilter = () => true;
      this.suggestions = fieldModel.getSuggestions(context);
    }

    const filteredSuggestions = this.suggestions.filter(searchFilter);
    if (filteredSuggestions.length > 0 && filteredSuggestions.every((s) => s.type === "text")) {
      // if all suggestions are just Suggestion type, we transform them into MenuSuggestion
      this.suggestions = [
        new MenuSuggestion({
          children: filteredSuggestions,
          type: "menu",
          context: context,
          textarea: this.textarea,
        }),
      ];

      return;
    }

    this.suggestions = filteredSuggestions;

    if (this.suggestions.length === 0) {
      this.showGenericTooltipIfPossible();
    }
  }
}

export { DjangoQL };
