import type { color } from "@vip-tailwind/types/components/input";

const inputStaticLabelColors: Record<color, object> = {
  primary: {
    color: "text-black",
    borderColor: "border-black",
    borderColorFocused: "focus:border-black",
  },
  danger: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-orange-500",
  },
  success: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-green-500",
  },
  warning: {
    borderColor: "border-gray-200",
    borderColorFocused: "focus:border-yellow-500",
  },
};

export default inputStaticLabelColors;
